<template>
  <p class="product-price">
    <span v-if="isPurchasable">
      <span
        v-if="discountPrice"
        class="product-price__text product-price__text--discount"
      >{{ discountPrice }}</span>
      <span
        v-if="showPriceLabels && discountPrice"
        class="product-price__text product-price__text--discount product-price__text--label"
      >{{ labelPrice }}</span>
      <span
        class="product-price__text"
        :class="{'product-price__text--base': discountPrice}"
      >{{ basePrice }}</span>
      <span class="product-price__text product-price__text--label" v-if="showPriceLabels && !discountPrice">{{ labelPrice }}</span>
    </span>
    <span v-else>
      Product No Longer Available
    </span>
  </p>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import FormatMixin from '@/mixins/FormatMixin'

export default {
  name: 'ProductPrice',
  mixins: [FormatMixin],
  props: {
    productData: {
      type: Object,
      required: true
    },
    adjustedPrice: {
      type: Number,
      required: false
    },
    showPriceLabels: {
      type: Boolean,
      default: false
    },
    isPurchasable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isAffiliate']),
    uniquePrices () {
      return new Set([
        this.productData.listPrice,
        this.productData.employeePrice,
        this.productData.clearancePrice,
        this.isAffiliate ? this.productData.affiliatePrice : null,
        this.adjustedPrice,
        this.productData.discountPrice
      ].filter(el => el))
    },
    discountPrice () {
      if (this.productData.employeePrice) {
        return this.formatPrice(this.productData.employeePrice)
      }
      return this.uniquePrices.size > 1 ? this.formatPrice(Math.min(...this.uniquePrices)) : null
    },
    basePrice () {
      return this.uniquePrices.size > 0
        ? this.formatPrice(Math.max(...this.uniquePrices))
        : 'Price is not available'
    },
    labelPrice () {
      return this.productData.discountPrice
        ? 'Promotion Price'
        : this.productData.affiliatePrice && this.isAffiliate
          ? 'Affiliate Price'
          : this.productData.employeePrice
            ? 'Employee Price'
            : this.productData.clearancePrice
              ? 'Clearance Price'
              : null
    }
  }
}
</script>
