<template>
  <div class="logo__container">
    <a
      @click.prevent="onClick"
      :href="link"
      :title="title"
      class="logo__link"
    >
      <img
        :src="src || defaultSrc"
        :title="title"
        :alt="alt || title"
        class="logo__img"
        loading="lazy"
        width="126"
        height="57"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    src: {
      required: true,
      default: '/Hubbell_Logo.png'
    },
    title: {
      default: 'Hubbell'
    },
    alt: {
      default: 'Hubbell'
    },
    link: {
      type: String,
      default: '/'
    }
  },
  methods: {
    onClick () {
      this.$emit('clicked')
      this.$router.push(this.link)
    }
  },
  data () {
    return {
      defaultSrc: '/Hubbell_Logo.png'
    }
  }
}
</script>
