import ContentfulService from '@/services/contentful'
import markdownToHTML from 'marked'
import { getSortingKeys } from '@/helpers'

const transformLogoEntry = (entry) => {
  const imageEntry = entry.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleImage')
  return {
    id: entry.fields.key || null,
    imageUrl: ContentfulService.extractImageUrl(imageEntry?.fields?.image) || null,
    imageAlt: imageEntry?.fields?.image?.fields?.title || null,
    imageDescription: imageEntry?.fields?.image?.fields?.description || null,
    title: entry.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleImage').fields?.image?.fields?.title || null,
    link: entry.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields?.url || null
  }
}

const transformCatalogContentEntry = (entry) => ({
  image: ContentfulService.extractImageUrl(entry.fields.image),
  imageMobile: entry.fields.imageMobile ? ContentfulService.extractImageUrl(entry.fields.imageMobile) : null,
  imageAlt: entry.fields.image?.fields?.title || null,
  imageDescription: entry.fields.image?.fields?.description || null,
  logo: ContentfulService.extractImageUrl(entry.fields.logo),
  title: entry.fields.title ? entry.fields.title : null,
  showAs: entry.fields.showAs ? entry.fields.showAs : null,
  showMegamenuCarousel: entry.fields.showMegamenuCarousel || false,
  description: entry.fields.description ? markdownToHTML(entry.fields.description) : null,
  heroBanner: entry.fields.heroBanner ? transformEntryByType(entry.fields.heroBanner) : null,
  bannerA: entry.fields.bannerA ? transformEntryByType(entry.fields.bannerA) : null,
  bannerB: entry.fields.bannerB ? transformEntryByType(entry.fields.bannerB) : null,
  bannerC: entry.fields.bannerC ? transformEntryByType(entry.fields.bannerC) : null,
  bannerAd: entry.fields.bannerAd ? transformEntryByType(entry.fields.bannerAd) : null,
  showBannerAd: entry.fields.showBannerAd || false,
  meta: entry.fields.meta ? transformEntryByType(entry.fields.meta) : null
})

const transformGenericContentSlot = (entry) => {
  return {
    key: entry.fields.key,
    container: entry.fields.container,
    alignment: entry.fields.alignment,
    verticalPadding: !!entry.fields.verticalPadding,
    slot: transformEntryByType(entry.fields.slot)
  }
}

const transformGenericContentPageEntry = (entry) => {
  const sortingKeys = entry.fields.slots ? getSortingKeys(entry.fields.slots) : null
  return {
    key: entry.fields.key,
    pageUrl: entry.fields.pageUrl,
    title: entry.fields.title,
    meta: entry.fields.meta ? transformEntryByType(entry.fields.meta) : null,
    heroBanner: entry.fields.heroBanner || null,
    slots: entry.fields?.slots?.length
      ? entry.fields.slots.map(transformGenericContentSlot).sort((a, b) => sortingKeys[a.key] - sortingKeys[b.key])
      : null
  }
}

// @TODO Fill with used entry types handlers
// to centralize transformers by type in one place
const transformEntryByType = (entry) => {
  const type = ContentfulService.extractContentType(entry)
  const handlers = {
    blockBannerContent: (entry) => ({
      type,
      key: entry.fields.key,
      image: entry.fields.image ? ContentfulService.extractImageUrl(entry.fields.image) : null,
      imageMobile: entry.fields.imageMobile ? ContentfulService.extractImageUrl(entry.fields.imageMobile) : null,
      imageAlt: entry.fields.image?.fields?.title || null,
      imageDescription: entry.fields.image?.fields?.description || null,
      title: entry.fields.title ? entry.fields.title : null,
      paragraph: entry.fields.paragraph ? markdownToHTML(entry.fields.paragraph) : null,
      alignText: entry.fields.alignText ? entry.fields.alignText.toLowerCase() : 'left',
      textColor: entry.fields.textColor ? entry.fields.textColor.toLowerCase() : 'black',
      isPromotionComponent: entry.fields.promotionComponent,
      link: entry.fields.link
        ? {
          title: entry.fields.link.fields.title,
          url: entry.fields.link.fields.url
        }
        : null
    }),
    blockBannerImage: (entry) => ({
      type,
      key: entry.fields.key,
      image: ContentfulService.extractImageUrl(entry.fields.image),
      imageMobile: entry.fields.imageMobile ? ContentfulService.extractImageUrl(entry.fields.imageMobile) : null,
      imageAlt: entry.fields.image?.fields?.title || null,
      imageDescription: entry.fields.image?.fields?.description || null,
      isPromotionComponent: entry.fields.promotionComponent,
      link: entry.fields.link
        ? {
          title: entry.fields.link.fields.title,
          url: entry.fields.link.fields.url
        }
        : null
    }),
    blockTwoColumnContent: (entry) => ({
      type,
      key: entry.fields.key,
      image: entry.fields.image ? ContentfulService.extractImageUrl(entry.fields.image) : null,
      imageMobile: entry.fields.imageMobile ? ContentfulService.extractImageUrl(entry.fields.imageMobile) : null,
      imageAlt: entry.fields.image?.fields?.title || null,
      imageDescription: entry.fields.image?.fields?.description || null,
      title: entry.fields.title ? entry.fields.title : null,
      paragraph: entry.fields.paragraph ? markdownToHTML(entry.fields.paragraph) : null,
      alignText: entry.fields.alignText ? entry.fields.alignText.toLowerCase() : 'left',
      link: entry.fields.link ? transformEntryByType(entry.fields.link) : null
    }),
    particleText: (entry) => ({
      type,
      key: entry.fields.key,
      header: entry.fields.header ? markdownToHTML(entry.fields.header) : null,
      paragraph: entry.fields.paragraph ? markdownToHTML(entry.fields.paragraph) : null
    }),
    particleLink: (entry) => ({
      type,
      key: entry.fields.key,
      title: entry.fields.title || null,
      url: entry.fields.url || null
    }),
    blockHintBar: (entry) => ({
      type,
      key: entry.fields.key,
      icon: entry.fields?.icon?.fields?.iconClass || null,
      message: entry.fields?.paragraph ? markdownToHTML(entry.fields.paragraph) : null,
      backgroundColor: entry.fields.hintBarColor ? entry.fields.hintBarColor.split('/')[0] : '',
      textColor: entry.fields.hintBarColor ? entry.fields.hintBarColor.split('/')[1] : ''
    }),
    pageMeta: (entry) => ({
      type,
      key: entry?.fields?.key,
      title: entry?.fields?.title,
      description: entry?.fields?.description || ''
    }),
    blockNewsletterModal: (entry) => ({
      type,
      key: entry.fields.key,
      title: entry?.fields?.title,
      titleColor: entry?.fields?.titleColor,
      description: entry?.fields?.description || '',
      descriptionColor: entry?.fields?.descriptionColor,
      additionalParagraph: entry?.fields?.additionalParagraph || '',
      successMessage: entry?.fields?.successMessage || '',
      image: entry.fields.image ? ContentfulService.extractImageUrl(entry.fields.image) : null
    }),
    blockCarousel: (entry) => ({
      type,
      key: entry?.fields?.key,
      title: entry?.fields?.title,
      link: entry?.fields?.link ? transformEntryByType(entry.fields.link) : null,
      carouselType: entry?.fields?.carouselType?.toLowerCase(),
      numberOfItems: +entry?.fields?.numberOfItems || 10,
      autoplayTimeout: +entry?.fields?.autoplayTimeout * 1000,
      items: entry?.fields?.items,
      cardDisplay: entry?.fields?.cardDisplay
    }),
    blockScript: (entry) => ({
      type,
      key: entry?.fields?.key,
      title: entry?.fields?.title,
      script: entry?.fields?.script
    }),
    blockCard: (entry) => ({
      type,
      key: entry?.fields?.key,
      title: entry?.fields?.title,
      description: entry?.fields?.description ? markdownToHTML(entry.fields.description) : null,
      image: entry.fields.image ? ContentfulService.extractImageUrl(entry.fields.image) : null,
      link: entry.fields.link ? transformEntryByType(entry.fields.link) : null
    }),
    blockCardGrid: (entry) => ({
      type,
      key: entry?.fields?.key,
      title: entry?.fields?.title,
      cardDisplay: entry?.fields?.cardDisplay,
      columns: entry?.fields?.columns,
      items: entry?.fields?.items ? entry.fields.items.map(item => transformEntryByType(item)) : null
    }),
    pageCatalog: (entry) => ({
      type,
      key: entry?.fields?.key,
      title: entry?.fields?.title,
      description: entry?.fields?.description ? markdownToHTML(entry.fields.description) : null,
      image: entry.fields.image ? ContentfulService.extractImageUrl(entry.fields.image) : null,
      link: {
        url: '/' + entry.fields?.catalogType.toLowerCase() + '/' + entry.fields.key + (entry.fields.showAs === 'PLP' ? '/products' : '')
      }
    })
  }

  return type && handlers[type] ? handlers[type](entry) : null
}

export {
  transformLogoEntry,
  transformCatalogContentEntry,
  transformGenericContentPageEntry,
  transformEntryByType
}
