import eventBusService from '@/services/event-bus'
import zApiService from '@/services/z-api'
import settings from '@/settings'
import { saveCurrentURLState } from '../../services/auth'

const _getAuthActionUrl = (queryParams, customPath, baseUrl) => {
  saveCurrentURLState(customPath || window.location.pathname, queryParams)
  localStorage.setItem('oAuthRedirect', state)

  return settings.services.auth.oAuthLoginURL
}

const state = {
  redirectUrlsData: null
}

const mutations = {
  SET_REDIRECT_URLS_DATA (state, data) {
    state.redirectUrlsData = data
  }
}

const actions = {
  initEventListeners ({ commit, dispatch }) {
    eventBusService.$on('auth.notAuthorized', errors => {
      dispatch('reLogin')
    })

    eventBusService.$on('api.serverError', errors => {
      const errorTemplate = `
        <h1 class="text-h1 text-center">Sorry! We'll be back soon.</h1>
        <h3 class="text-h3 text-center">We are currently having technical difficulties. We'll be up and running as soon as possible.</h3>
      `
      commit('app-error/SHOW_APP_ERROR', errorTemplate, { root: true })
    })
  },
  async setAppData ({ dispatch }, { resolve: resolveBoot, reject: rejectBoot, bootData }) {
    const immediateQueue = []
    const dependencyQueue = []
    const delayedQueue = []
    const { sessionType } = bootData

    dispatch('initEventListeners')

    switch (sessionType) {
      case 'anonymous':
        immediateQueue.push(() =>
          dispatch('cart/setCartDataOnBoot', 'anonymous', { root: true })
        )
        immediateQueue.push(() =>
          dispatch('content/setGlobalConfiguration', null, { root: true })
        )
        immediateQueue.push(() =>
          dispatch('catalogs/setCatalogsData', null, { root: true })
        )
        immediateQueue.push(() =>
          dispatch('genericContent/setGenericContentData', null, { root: true })
        )

        // Uncomment when redirect microservice is ready
        // immediateQueue.push(() => dispatch('setRedirectUrlsData'))
        break
      case 'force-login':
        immediateQueue.push(() => dispatch('doLogin'))
        break
      case 'user':
        immediateQueue.push(() =>
          dispatch('user/setUserData', 'current', { root: true })
        )
        immediateQueue.push(() =>
          dispatch('content/setGlobalConfiguration', null, { root: true })
        )
        immediateQueue.push(() =>
          dispatch('genericContent/setGenericContentData', null, { root: true })
        )

        // Uncomment when redirect microservice is ready
        // immediateQueue.push(() => dispatch('setRedirectUrlsData'))
        dependencyQueue.push(() =>
          dispatch('cart/setCartDataOnBoot', 'current', { root: true })
        )
        dependencyQueue.push(() =>
          dispatch('catalogs/setCatalogsData', null, { root: true })
        )
        // @TODO remove from boot queue
        delayedQueue.push(() =>
          dispatch('user/setShippingAddressData', null, { root: true })
        )
        delayedQueue.push(() =>
          dispatch('fullstory/identifySession', null, { root: true })
        )
        break
    }

    Promise.all(immediateQueue.map(cb => cb()))
      .then(() => Promise.all(dependencyQueue.map(cb => cb())))
      .then(() => {
        resolveBoot()
        Promise.all(delayedQueue.map(cb => cb()))
      })
      .catch(error => {
        rejectBoot(error)
      })
  },

  async setRedirectUrlsData ({ commit }) {
    const data = await zApiService.sites.getUrlRedirectsList()
    const urlsData = data
      ? data.reduce((sum, cur) => {
        const sourceURL = cur.sourceURL.split('/').slice(3).join('/')
        const targetURL = cur.targetURL.split('/').slice(3).join('/')
        sum[`/${sourceURL}`] = `/${targetURL}`
        return sum
      }, {})
      : {}
    urlsData['/category/featured-products'] = '/category/featured-categories/featured-products/products'
    urlsData['/category/featured-products/products'] = '/category/featured-categories/featured-products/products'
    commit('SET_REDIRECT_URLS_DATA', urlsData)
  },

  async doLogout ({ dispatch }) {
    await dispatch('user/resetUserData', null, { root: true })
    window.location.href = '/'
  },

  doLogin (context, { queryParams = {}, customPath = '', baseUrl = settings.services.api.azureLoginUrl } = {}) {
    window.location.href = _getAuthActionUrl(queryParams, customPath, baseUrl)
    return new Promise(() => {})
  },

  doLoginOkta (context, { queryParams = {}, customPath = '', baseUrl = settings.services.api.azureLoginOktaUrl } = {}) {
    window.location.href = _getAuthActionUrl(queryParams, customPath, baseUrl)
    return new Promise(() => {})
  },

  doSignup (context, { queryParams = {}, customPath = '', baseUrl = settings.services.api.azureSignupUrl } = {}) {
    window.location.href = _getAuthActionUrl(queryParams, customPath, baseUrl)
    return new Promise(() => {})
  },

  doResetPassword (context, { queryParams = {}, customPath = '' } = {}) {
    queryParams[settings.app.queryTokens.resetPasswordInitiatedToken] = true
    window.location.href = _getAuthActionUrl(queryParams, customPath, settings.services.api.azureResetPasswordUrl)
    return new Promise(() => {})
  },

  async reLogin ({ dispatch }) {
    await dispatch('user/resetUserData', null, { root: true })
    dispatch('doLogin')
  }
}

const getters = {
  isUSSite: () => settings.app.country === 'US'
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
