import settings from '@/settings'
import FullstoryService from '@/services/fullstory'

const state = {
  scriptLoaded: false
}

const mutations = {
  SET_SCRIPT_LOADED (state, data) {
    state.scriptLoaded = data
  }
}

const actions = {
  identifySession ({ rootState, state }) {
    if (settings.services.fullStory.enabled && settings.services.fullStory.orgId) {
      const data = {
        uid: rootState.user.userData?.customerId,
        displayName: rootState.user.userData?.uid,
        email: rootState.user.userData?.fullName
      }
      if (!state.scriptLoaded) {
        const timer = setInterval(() => {
          if (state.scriptLoaded) {
            clearInterval(timer)
            FullstoryService.identify(data)
          }
        }, 500)
      } else {
        FullstoryService.identify(data)
      }
    }
  },
  orderComplete ({ rootState }) {
    if (settings.services.fullStory.enabled && settings.services.fullStory.orgId && rootState.cart.placedOrderData) {
      const data = {
        orderId: rootState.cart.placedOrderData.id,
        products: rootState.cart.placedOrderData.entries?.map(el => ({
          productId_str: el.product.sku,
          price_real: el.adjustedPrice,
          quantity_real: el.quantity
        }))
      }
      FullstoryService.orderComplete(data)
    }
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
