export const extractProductPrimaryImage = (entry) => {
  const transformed = {
    type: 'image',
    title: entry.title || entry.name,
    src: entry['Image URL'] || entry.images?.find(el => el.imageType === 'PRIMARY' && el.format === 'zoom')?.url
      .replace('/hubbellcommercewebservices/v2', ''),
    previewSrc: entry['Image URL'] || entry.images?.find(el => el.imageType === 'PRIMARY' && el.format === 'thumbnail')?.url
      .replace('/hubbellcommercewebservices/v2', '')
  }
  if (window.isWebpSupported) {
    transformed.src = transformed.src?.replace('.jpg', '.webp')
    transformed.previewSrc = transformed.previewSrc?.replace('.jpg', '.webp')
  }
  return transformed
}
export const extractProductGalleryImages = (entry) => {
  if (entry['Image URL']) {
    return [extractProductPrimaryImage(entry)]
  } else {
    const galleryImages = entry.images.filter(el => el.imageType === 'GALLERY')
    const zoomGalleryImages = galleryImages.filter(el => el.format === 'zoom')
    const thumbnailGalleryImages = galleryImages.filter(el => el.format === 'thumbnail')
    let asset3d = entry.dataSheets.find(el => el.description === '3D Product View')
    if (asset3d) {
      asset3d = {
        type: 'asset3d',
        id: 'asset3d',
        src: asset3d.url?.replace('/hubbellcommercewebservices/v2', ''),
        previewSrc: 'https://hubbellcdn.com/ohwassets/Hubbell/images/ar-360-icon.png'
      }
    }
    const videos = entry.videos.map(el => {
      return {
        type: 'video',
        id: el.videoID,
        src: el.videoPlayerType === 'YouTube'
          ? `https://www.youtube.com/embed/${el.videoID}`
          : el.videoLocation?.includes('https')
            ? el.videoLocation
            : el.videoLocation?.replace('http', 'https'),
        previewSrc: el.videoPlayerType === 'YouTube'
          ? `https://img.youtube.com/vi/${el.videoID}/default.jpg`
          : null
      }
    }).filter(el => el.src)

    let transformedGallery = zoomGalleryImages.map(zoomImage => {
      const transformed = {
        type: 'image',
        title: entry.title || entry.name,
        src: zoomImage.url?.replace('/hubbellcommercewebservices/v2', ''),
        previewSrc: thumbnailGalleryImages
          .find(thumbnailImage => thumbnailImage.galleryIndex === zoomImage.galleryIndex)?.url?.replace('/hubbellcommercewebservices/v2', '')
      }
      if (window.isWebpSupported) {
        transformed.src = transformed.src?.replace('.jpg', '.webp')
        transformed.previewSrc = transformed.previewSrc?.replace('.jpg', '.webp')
      }
      return transformed
    })

    transformedGallery.unshift(...videos)

    const primaryImage = extractProductPrimaryImage(entry)
    transformedGallery = transformedGallery.filter(el => el.src && el.src !== primaryImage.src)
    if (asset3d) transformedGallery.unshift(asset3d)
    transformedGallery.unshift(primaryImage)

    return transformedGallery
  }
}
export const extractProductResources = (list) => {
  const resourcesGroups = {}
  const resourcesBlacklistByType = ['ENERGY_GUIDE', 'PROP_65', 'PRODUCT_LOGO']
  const resourcesBlacklistByDescription = ['3D Product View']
  const filteredResources = list
    .filter(el => !resourcesBlacklistByType.includes(el.type) && !resourcesBlacklistByDescription.includes(el.description))
  filteredResources.forEach(el => {
    if (!resourcesGroups[el.type]) {
      resourcesGroups[el.type] = {
        title: el.description,
        items: []
      }
    }
    resourcesGroups[el.type].items.push({
      title: el.altText,
      link: el.url?.replace('/hubbellcommercewebservices/v2', ''),
      extension: el.url?.split('.').pop()
    })
    resourcesGroups[el.type].items = resourcesGroups[el.type].items.filter(el => el.link)
  })
  return Object.keys(resourcesGroups).length !== 0 ? resourcesGroups : null
}
export const extractProductVariants = (entry) => {
  const finishColorEntries = entry.productReferences?.filter(el => el.target?.id && el.referenceType === 'FINISHORCOLOR')
  if (!finishColorEntries?.length) return null
  const variantProductsList = finishColorEntries.map(el => ({
    image: extractProductPrimaryImage(el.target),
    catalogId: el.target?.catalogNumber,
    sku: el.target?.sku
  }))
  const currentProduct = {
    image: extractProductPrimaryImage(entry),
    catalogId: entry.catalogNumber,
    sku: entry.sku
  }
  return [currentProduct, ...variantProductsList]
}
export const extractProductStore = (entry, storeName) => {
  const valueObject = entry.classifications
    ?.find(el => el.code === '14280')
    ?.features.find(el => el.code === 'hubbellClassification/1.0/14280.' + storeName)
    ?.featureValues?.[0]
  return !(typeof valueObject?.value === 'string' && valueObject?.value === 'No')
}

export const extractPdpNotices = (entry) => {
  const valueObject = entry.classifications
    ?.find(el => el.code === '14280')
    ?.features.find(el => el.code === 'hubbellClassification/1.0/14280.storepdpnotice')
    ?.featureValues?.[0]
  const cleaned = valueObject?.value?.replace(/<ul><li>|<\/li><\/ul>/g, '') || ''
  return cleaned !== '' ? cleaned.split('</li><li>') : ''
}

export const extractProductCategory = (groups) => {
  if (!groups?.length) return ''
  const groupedCategories = []
  groups.forEach(group => {
    group.forEach((subGroup, idx) => {
      if (!groupedCategories[idx]) groupedCategories[idx] = []
      if (!groupedCategories[idx].includes(subGroup.title)) groupedCategories[idx].push(subGroup.title)
    })
  })
  return groupedCategories.map(el => el.join(';')).join(' / ')
}

export const extractCategoriesData = (groupedCategoriesString) => {
  if (!groupedCategoriesString) return {}
  const fieldNameStaticPart = 'item_category'
  const categories = {}
  const parsedCategories = groupedCategoriesString.split(' / ')
  parsedCategories.forEach((categoryLvlLine, idx) => {
    const fieldName = idx ? `${fieldNameStaticPart}${idx + 1}` : fieldNameStaticPart
    categories[fieldName] = categoryLvlLine
  })
  return categories
}

export const extractPromoBanner = (potentialPromotions) => {
  const potentialPromotionEntry = potentialPromotions?.find(el => el.couldFireMessages?.[0])
  return potentialPromotionEntry
    ? {
      code: potentialPromotionEntry.code,
      message: potentialPromotionEntry.couldFireMessages[0]
    }
    : null
}

export const extractDiscountAmountFromProduct = ({ discountPrice, regularPrice }) => {
  const discount = !discountPrice || !regularPrice ? 0 : regularPrice - discountPrice
  return discount
}

export const extractDiscountAmountFromCartEntry = (cartEntry) => {
  return (cartEntry.totalPriceWithoutDiscount - cartEntry.totalPrice) / cartEntry.quantity
}

export const extractDiscountedProductPrice = ({ productData, isAffiliate }) => {
  let price = 0
  const uniquePrices = new Set([
    isAffiliate ? productData.affiliatePrice : null,
    productData.listPrice,
    productData.employeePrice,
    productData.clearancePrice
  ].filter(el => el))
  if (uniquePrices.size > 0) price = Math.min(...Array.from(uniquePrices))
  return +price
}
