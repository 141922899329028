import settings from '@/settings'
import algoliasearch from 'algoliasearch/lite'

const _client = algoliasearch(
  settings.services.algolia.appId,
  settings.services.algolia.accessToken
)

const _searchAppClient = algoliasearch(
  settings.services.algolia.searchApp.appId,
  settings.services.algolia.searchApp.accessToken
)

const _getProductIndex = (sortBy) => {
  switch (sortBy) {
    case 'popularity':
      return settings.services.algolia.productIndexPopularity
    case 'price':
      return settings.services.algolia.productIndexPrice
    case 'price_desc':
      return settings.services.algolia.productIndexPriceDesc
    case 'relevance':
    default:
      return settings.services.algolia.productIndexRelevance
  }
}

export default {
  getProductIndex (sortBy) {
    const index = _getProductIndex(sortBy)
    return _client.initIndex(index)
  },
  getSearchAppProductIndex () {
    return _searchAppClient.initIndex(settings.services.algolia.searchApp.productIndexRelevance)
  },
  getClient () {
    return _client
  },
  getProductIndexName (sortBy) {
    return _getProductIndex(sortBy)
  },
  getQuerySuggestions ({ query, ...rest }) {
    return _client.initIndex(settings.services.algolia.productIndexSuggestions).search(query, rest)
  },
  finalizeQueryParams ({ filters, ...rest }, { isPublic, isEmployee, isDiscountedPrice, isAffiliate }) {
    filters = filters || ''
    if (isPublic) {
      if (filters) filters = filters + ' AND '
      filters = filters + "'Public Store':'Yes'"
    }
    if (isAffiliate) {
      if (filters) filters = filters + ' AND '
      filters = filters + "'Affiliate Store':'Yes'"
    }
    if (isEmployee) {
      if (filters) filters = filters + ' AND '
      filters = filters + "'Employee Store':'Yes'"
    }
    if (isEmployee && isDiscountedPrice) {
      if (filters) filters = filters + ' AND '
      filters = filters + 'isDiscountedPrice:true'
    }
    return { filters, ...rest }
  },
  finalizeFilters ({ filters, isPublic, isEmployee, isDiscountedPrice, isAffiliate }) {
    filters = filters || []
    if (isPublic && !filters.some(el => el[0] === "'Public Store':'Yes'")) {
      filters.push(["'Public Store':'Yes'"])
    }
    if (isAffiliate && !filters.some(el => el[0] === "'Affiliate Store':'Yes'")) {
      filters.push(["'Affiliate Store':'Yes'"])
    }
    if (isEmployee && !filters.some(el => el[0] === "'Employee Store':'Yes'")) {
      filters.push(["'Employee Store':'Yes'"])
    }
    if (isEmployee && isDiscountedPrice && !filters.some(el => el[0] === 'isDiscountedPrice:true')) {
      filters.push(['isDiscountedPrice:true'])
    }

    return filters
  }
}
