<template>
  <footer v-if="footerData" class="footer">
    <div class="footer__container container">
      <ul class="footer__nav" v-if="footerData.navLinks">
        <li
          v-for="(navItem, idx) in footerData.navLinks"
          :key="navItem.id"
          class="footer__nav-item"
          :class="{
            'is-active': activeNavItem == navItem.title + navItem.id + idx
          }"
        >
          <div
            v-if="!isMobile"
            v-html="navItem.title"
            class="footer__nav-title"
          ></div>
          <div
            v-else
            @click="toggleNestedMenu(navItem.title + navItem.id + idx)"
            class="footer__nav-title-wrapper"
          >
            <div class="footer__nav-title" v-html="navItem.title"></div>
            <i class="footer__nav-icon icon-down-arrow"></i>
          </div>
          <ul
            v-if="navItem.links && navItem.links.length"
            class="footer__nav-links"
          >
            <li v-for="item in navItem.links" :key="item.id">
              <a
                v-if="item.url"
                @click.prevent="navigateTo(item.url)"
                v-html="item.title"
                :href="item.url"
                :title="item.title"
                target="_blank"
                rel="noopener"
                class="footer__nav-link footer__link"
              ></a>
              <p v-else-if="item.header" v-html="item.header" class="footer__text"></p>
            </li>
          </ul>
        </li>
      </ul>
      <div class="footer__right">
        <SocialLinks
          v-if="footerData.socialLinks"
          :links="footerData.socialLinks"
        />
        <CreditCardLogo
          v-if="footerData.creditCardLogo"
          :link="footerData.creditCardLogo.link"
          :src="footerData.creditCardLogo.imageUrl"
          :title="footerData.creditCardLogo.imageDescription || footerData.creditCardLogo.title"
          :alt="footerData.creditCardLogo.imageAlt || footerData.creditCardLogo.title"
        />
      </div>
      <div class="footer__info">
        <Logo
          v-if="footerData.logo"
          @clicked="scrollPageToTop"
          :link="footerData.logo.link"
          :src="footerData.logo.imageUrl"
          :title="footerData.logo.imageDescription || footerData.logo.title"
          :alt="footerData.logo.imageAlt || footerData.logo.title"
        />
        <ul class="footer__copyright" v-if="footerData.termsSection">
          <li v-for="item in footerData.termsSection" :key="item.id">
            <a
              v-if="item.url"
              @click.prevent="navigateTo(item.url)"
              v-html="item.title"
              :href="item.url"
              :title="item.title"
              target="_blank"
              class="footer__link"
              rel="noopener"
            ></a>
            <p v-else-if="item.title" v-html="item.title"></p>
          </li>
          <li v-if="showCookieButton">
            <button
              ref="cookieButton"
              id="ot-sdk-btn"
              class="button--underlined footer__link ot-sdk-show-settings"
            ></button>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AnalyticsService from '@/services/analytics'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import Logo from '@/components/Logo'
import SocialLinks from '@/components/SocialLinks'
import CreditCardLogo from '@/components/CreditCardLogo'

export default {
  name: 'Footer',
  components: {
    Logo,
    SocialLinks,
    CreditCardLogo
  },
  mixins: [NavigationalMixin, ScreenWidthMixin],
  computed: {
    ...mapState('content', ['footerData']),
    showCookieButton () {
      return AnalyticsService.oneTrustEnabled
    }
  },
  methods: {
    ...mapActions('content', ['setFooterData']),
    toggleNestedMenu (id) {
      this.activeNavItem !== id
        ? (this.activeNavItem = id)
        : (this.activeNavItem = null)
    }
  },
  created () {
    this.setFooterData()
  },
  mounted () {
    if (this.showCookieButton) {
      let i = 0
      const interval = setInterval(() => {
        if (this.$refs.cookieButton) this.$refs.cookieButton.innerText = this.cookieButtonText
        ++i
        if (i > 9) clearInterval(interval)
      }, 500)
    }
  },
  data () {
    return {
      activeNavItem: null,
      cookieButtonText: 'Cookie Settings'
    }
  }
}
</script>
