// @TODO remove dep
import _ from 'lodash'

export default {
  computed: {
    isModelValid () {
      return !this.$v.$invalid
    }
  },
  methods: {
    pushDataToForm (data) {
      Object.values(this.form).forEach((field) => {
        if (Object.keys(data).includes(field.title)) {
          field.value = data[field.title]
        }
      })
    },
    pushFormToData (data) {
      Object.values(this.form).forEach((field) => {
        if (field.title) {
          data[field.title] = field.value
        }
      })
    },
    mapFormErrors (errors) {
      if (errors && errors.length) {
        this.clearFormServerErrors()
        errors.forEach(error => {
          const field = _.find(this.form, ['title', error.subject])
          if (field) {
            field.errors = { ...field.errors || {}, server: error.message }
          } else {
            this.form._errors.push(error)
          }
        })
      }
    },
    mapFormValidationErrors (errors) {
      if (errors && errors.length) {
        const filteredErrors = errors.filter(error => ['ValidationError', 'invalid_grant', 'unauthorized', 'VoucherOperationError'].includes(error.type))
        this.mapFormErrors(filteredErrors)
      }
    },
    onFieldChange (field) {
      this.$v.$touch()
      if (field && field.errors) {
        this.clearFieldServerError(field)
      }
    },
    clearFieldServerError (field, vField) {
      if (field?.errors?.server) {
        delete field.errors.server
        // @TODO investigate the reason behind faulty $touch, hack to trigger the update
        const _value = field.value
        field.value = ''
        field.value = _value
      }
    },
    isEqualObjects (firstObject, secondObject) {
      return _.isEqual(firstObject, secondObject)
    },
    clearFormServerErrors () {
      for (const key in this.form) {
        this.clearFieldServerError(this.form[key])
      }
      this.form._errors = []
    },
    withPresendRoutine (cb) {
      this.clearFormServerErrors()
      if (this.isModelValid) {
        return cb()
      } else {
        this.isValidationRun = true
      }
    },
    updatePhoneMask (country, phone) {
      if (country.name === 'United States' || country.name === 'Canada') {
        phone.mask = '(###) ### - ####'
      } else {
        phone.mask = '+(##) ##-#### ####'
      }
    },
    sendForm (action, data, asyncCallback) {
      this.isLoading = true
      this.isValidationRun = true
      return new Promise((resolve, reject) => {
        action(data)
          .then(async res => {
            if (asyncCallback) await asyncCallback()
            this.isValidationRun = false
            resolve(res)
          })
          .catch((reason) => {
            if (reason?.response?.data?.length) {
              this.mapFormValidationErrors(reason.response.data)
            }
            reject(reason)
          })
          .finally(() => {
            this.isLoading = false
          })
      })
    },
    server (value, model) {
      return !model.errors.server
    }
  }
}
