import { transformLogoEntry, transformEntryByType } from '@/transformers'
import ContentfulService from '@/services/contentful'
import { getSortingKeys } from '@/helpers'

const state = {
  footerData: null,
  headerData: null,
  defaultBannerAd: null,
  alertHintBar: null,
  showAlertHintBar: false,
  isAlertHintBarViewed: false,
  defaultPlpMeta: null,
  newsletterModalData: null,
  showNewsletterModal: false
}

const mutations = {

  SET_FOOTER_DATA (state, data) {
    state.footerData = data
  },
  SET_HEADER_DATA (state, data) {
    state.headerData = data
  },
  SET_DEFAULT_BANNER_AD (state, data) {
    state.defaultBannerAd = data
  },
  SET_ALERT_HINT_BAR_DATA (state, { newTransformedAlertHintBar, showAlertHintBar }) {
    state.alertHintBar = newTransformedAlertHintBar
    state.showAlertHintBar = showAlertHintBar
  },
  TOGGLE_ALERT_HINT_BAR_VIEWED_STATE (state, isViewed) {
    state.isAlertHintBarViewed = isViewed
  },
  SET_DEFAULT_PLP_META_DATA (state, data) {
    state.defaultPlpMeta = data
  },
  SET_NEWSLETTER_MODAL_DATA (state, data) {
    state.newsletterModalData = data
  },
  SET_SHOW_NEWSLETTER_MODAL (state, data) {
    state.showNewsletterModal = data
  }
}

const actions = {

  async setFooterData ({ commit }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'blockFooter',
      'default-block-footer'
    )

    const navLinks = !entry?.fields?.topicLinks
      ? null
      : entry.fields.topicLinks.map(item => {
        return {
          id: item.fields.key || null,
          title: item.fields.title || null,
          links: item.fields.items.map(linkItem => linkItem.fields) || null
        }
      })

    const socialLinks = !entry?.fields?.socialLinks
      ? null
      : entry.fields.socialLinks.fields.items.map(item => {
        return {
          id: item.fields.key || null,
          // @TODO add service helper to get entry by type from includes field
          iconClass: item.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleIcon')?.fields?.iconClass || null,
          title: item.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields?.title || null,
          url: item.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields?.url || null
        }
      })

    const termsSection = !entry?.fields?.termsLinks
      ? null
      : entry.fields.termsLinks.map(item => {
        return {
          id: item.fields.key || null,
          title: item.fields.title || item.fields.header || null,
          url: item.fields.url || null
        }
      })

    const logo = entry?.fields?.logo
      ? transformLogoEntry(entry.fields.logo)
      : null

    const creditCardLogo = entry?.fields?.creditCardLogo
      ? transformLogoEntry(entry.fields.creditCardLogo)
      : null

    const footerData = {
      navLinks,
      socialLinks,
      termsSection,
      logo,
      creditCardLogo
    }

    commit('SET_FOOTER_DATA', footerData)
  },

  async setHeaderData ({ commit, rootState }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'blockHeader',
      'default-block-header'
    )

    const logo = entry?.fields?.logo
      ? transformLogoEntry(entry.fields.logo)
      : null

    const headerLinksA = entry?.fields?.headerLinksA
      ? entry.fields.headerLinksA.map(transformEntryByType).map(el => ({
        title: el.title,
        link: el.url,
        isOpenMenu: false
      }))
      : null

    const headerLinksB = entry?.fields?.headerLinksB
      ? entry.fields.headerLinksB.map(transformEntryByType).map(el => ({
        title: el.title,
        link: el.url,
        isOpenMenu: false
      }))
      : null

    const sortingKeys = entry?.fields?.menuCategoriesOrder ? getSortingKeys(entry.fields.menuCategoriesOrder) : null
    const updateSubValues = value => {
      if (value.subValues) {
        value.subValues.sort((a, b) => a.title.localeCompare(b.title))
        value.subValues.forEach(updateSubValues)
      }
    }

    let megaMenuData
    if (rootState.catalogs.catalogsData?.categories) {
      megaMenuData = rootState.catalogs.catalogsData.categories
      rootState.catalogs.catalogsData.categories.forEach(category => {
        if (category.subValues) updateSubValues(category)
      })
    }
    const headerLinks = [
      {
        title: 'Products',
        link: '',
        isOpenMenu: false,
        megaMenu: sortingKeys
          ? megaMenuData?.sort((a, b) => sortingKeys[b.key] - sortingKeys[a.key]).reverse()
          : megaMenuData,
        containsCarousel: megaMenuData?.some(el => el.showMegamenuCarousel)
      }
    ]

    if (headerLinksA) headerLinks.unshift(...headerLinksA)
    if (headerLinksB) headerLinks.push(...headerLinksB)

    const headerData = {
      logo,
      headerLinks
    }

    commit('SET_HEADER_DATA', headerData)
  },
  async setGlobalConfiguration ({ commit, state }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'globalConfiguration',
      'default-global-configurations'
    )

    if (entry?.fields?.defaultSearchBannerAd) {
      commit('SET_DEFAULT_BANNER_AD', transformEntryByType(entry.fields.defaultSearchBannerAd))
    }

    const alertHintBarFromLocalStorage = state.alertHintBar
    const newTransformedAlertHintBar = entry?.fields?.alertHintBar
      ? transformEntryByType(entry.fields.alertHintBar)
      : null
    const showAlertHintBar = entry?.fields?.showAlertHintBar
    if (alertHintBarFromLocalStorage?.key !== newTransformedAlertHintBar?.key) {
      commit('TOGGLE_ALERT_HINT_BAR_VIEWED_STATE', false)
    }
    commit('SET_ALERT_HINT_BAR_DATA', { newTransformedAlertHintBar, showAlertHintBar })

    if (entry?.fields?.defaultPlpMeta) {
      commit('SET_DEFAULT_PLP_META_DATA', transformEntryByType(entry.fields.defaultPlpMeta))
    }
  },
  async decorateMegamenuWithCarousels ({ commit, state, dispatch }) {
    const categoriesWithCarouselEnabled = state.headerData.headerLinks
      .find(el => el.containsCarousel)?.megaMenu?.filter(el => el.showMegamenuCarousel)
    const carouselProductsByCategory = categoriesWithCarouselEnabled.map(el => ({
      category: el,
      productsList: []
    }))

    if (!carouselProductsByCategory?.length) return

    const transformedProductsGroups = await Promise.all(carouselProductsByCategory.map(el => dispatch(
      'search/getTransformedSearchProductsByQueryParams',
      { filters: `(${el.category.indexTitle})`, sortBy: 'popularity' },
      { root: true }
    )))

    transformedProductsGroups.forEach((produtsGroup, idx) => {
      carouselProductsByCategory[idx].productsList = produtsGroup
    })

    if (state.headerData.headerLinks.find(el => el.containsCarousel).megaMenu) {
      const headerData = state.headerData
      const megaMenu = headerData.headerLinks.find(el => el.containsCarousel).megaMenu
      headerData.headerLinks.find(el => el.containsCarousel).megaMenu = megaMenu.map(megaMenuItem => {
        let productsCarouselList = null
        const productsToAdd = carouselProductsByCategory.find(list => list.category.indexTitle === megaMenuItem.indexTitle)?.productsList
        if (productsToAdd) productsCarouselList = productsToAdd
        megaMenuItem.productsCarouselList = productsCarouselList
        return megaMenuItem
      })
      commit('SET_HEADER_DATA', headerData)
    }
  },
  async setNewsletterModalData ({ commit }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'blockNewsletterModal',
      'default-newsletter-modal'
    )
    if (entry?.fields) commit('SET_NEWSLETTER_MODAL_DATA', transformEntryByType(entry))
  },
  decorateCarouselEntryWithItems ({ rootState, dispatch }, transformedCarouselEntry) {
    const handlersByCarouselType = {
      products: async () => {
        const catalog = rootState.catalogs.catalogsData.allCatalogs
          .find(catalog => transformedCarouselEntry.items[0].fields.key === catalog.key)
        if (!catalog) return null
        const transformedProducts = await dispatch(
          'search/getTransformedSearchProductsByQueryParams',
          { filters: `(${catalog.indexTitle})`, hitsPerPage: transformedCarouselEntry.numberOfItems, sortBy: 'popularity' },
          { root: true }
        )
        transformedCarouselEntry.items = transformedProducts
        return transformedCarouselEntry
      },
      categories: () => {
        const sortingKeys = getSortingKeys(transformedCarouselEntry.items)

        transformedCarouselEntry.items = transformedCarouselEntry.items.map(item => {
          const categoryCatalog = rootState.catalogs.catalogsData.allCatalogs.find(catalog => item.fields.key === catalog.key)
          return categoryCatalog
        }).filter(el => el)
          .sort((a, b) => sortingKeys[b.key] - sortingKeys[a.key]).reverse()
        return transformedCarouselEntry
      },
      cards: () => {
        transformedCarouselEntry.items = transformedCarouselEntry.items.map(item => transformEntryByType(item))
        return transformedCarouselEntry
      }
    }
    return handlersByCarouselType[transformedCarouselEntry.carouselType]
      ? handlersByCarouselType[transformedCarouselEntry.carouselType](transformedCarouselEntry)
      : null
  },
  decorateBannerCarousel ({ rootGetters }, carouselEntry) {
    const carouselData = carouselEntry ? transformEntryByType(carouselEntry) : null
    const carouselItemType = carouselData ? ContentfulService.extractContentType(carouselData.items[0]) : null
    const carouselBanners = carouselData?.carouselType === 'banners' && carouselItemType === 'compositeUserGroupCollection'
      ? carouselData.items[0].fields[rootGetters['user/userGroupCollectionName']].map(banner => transformEntryByType(banner))
      : null
    if (carouselBanners) carouselData.items = carouselBanners
    return carouselBanners
      ? {
        parentKey: carouselEntry.fields?.items?.[0]?.fields?.key,
        carousel: carouselData
      }
      : null
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
