import ApiBase from '@/services/z-api/v1/api-base'

class Products extends ApiBase {
  /**
   * Get product by SKU.
   */
  getProduct (code) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/products/${encodeURIComponent(code)}?fields=STOREPDPDEFAULT`
      )
    })
  }

  getProductsList (skuList) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/v1/products/productList', {
        productCodes: skuList
      })
    })
  }

  getPricesForProducts (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/v1/products/productPrices', data)
    })
  }

  getDownloadAllResourcesByTypeLink ({ productCode, resourceType }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/products/${productCode}/download?resourceTypes=${resourceType}`
      )
    })
  }

  getRelatedProducts ({ productCode, quantity }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/products/${productCode}/references?referenceType=SIMILAR${
          quantity ? `&quantity=${quantity}` : ''
        }`
      )
    })
  }

  getAllRelatedProducts ({ productCode, quantity }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/products/${productCode}/references?referenceType=ALL${
          quantity ? `&quantity=${quantity}` : ''
        }`
      )
    })
  }
}

export default Products
