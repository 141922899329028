<template>
  <div
    v-click-outside="() => toggleDropDown(false)"
    class="country-switcher dropdown-select"
  >
    <div @click="toggleDropDown()" class="dropdown-select__btn">
      <i class="icon-globe dropdown-select__icon"></i>
      <span class="dropdown-select__text"
        >Shipping to: {{ currentCountry.title }}</span
      >
      <i class="icon-down-arrow dropdown-select__icon"></i>
    </div>
    <ul v-if="isDropDownOpen" class="dropdown-select__list">
      <li
        v-for="country in countries"
        :key="country.code"
        class="dropdown-select__item"
        :class="{
          'dropdown-select__item--disabled':
            currentCountry.code === country.code,
        }"
      >
        <a :href="getCountryLink(country.code)" class="dropdown-select__link">{{
          country.title
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import settings from '@/settings'

export default {
  name: 'country-switcher',
  computed: {
    ...mapGetters('user', ['isGuest']),
    currentCountry () {
      return this.countries.find((el) => el.code === settings.app.country)
    }
  },
  methods: {
    ...mapActions('app', ['doLogin']),
    toggleDropDown (val) {
      this.isDropDownOpen =
        typeof val === 'boolean' ? val : !this.isDropDownOpen
    },
    getCountryLink (countryCode) {
      const useForceLogin = !this.isGuest ? `?${settings.app.queryTokens.forceLogin}=true` : ''
      return `${settings.environmentsBaseUrl[settings.app.env][countryCode]}${
        window.location.pathname
      }${
        useForceLogin
      }`
    }
  },
  data () {
    return {
      isDropDownOpen: false,
      countries: [
        {
          code: 'US',
          title: 'USA'
        },
        {
          code: 'CA',
          title: 'Canada'
        }
      ]
    }
  }
}
</script>
