import zApiService from '@/services/z-api'
import { transformOrderEntry } from '@/transformers'

const state = {
  ordersData: null,
  ordersResultsMetaData: null,
  orderDetailsData: null,
  trackOrderData: null
}

const mutations = {
  SET_ORDERS_DATA (state, data) {
    state.ordersData = data
  },
  UNSET_ORDERS_DATA (state) {
    state.ordersData = null
  },
  SET_ORDERS_RESULTS_META_DATA (state, data) {
    state.ordersResultsMetaData = Object.freeze(data)
  },
  UNSET_ORDERS_RESULTS_META_DATA (state) {
    state.ordersResultsMetaData = null
  },
  SET_ORDER_DETAILS_DATA (state, data) {
    state.orderDetailsData = data
  },
  UNSET_ORDER_DETAILS_DATA (state) {
    state.orderDetailsData = null
  },
  SET_TRACK_ORDER_DATA (state, data) {
    state.trackOrderData = data
  },
  UNSET_TRACK_ORDER_DATA (state) {
    state.trackOrderData = null
  }
}

const actions = {
  async setOrdersData ({ commit }, params) {
    const { orders, pagination } = await zApiService.orders.getOrders(params)
    const { currentPage, pageSize, sort, totalPages } = pagination

    commit('SET_ORDERS_DATA', orders.map(transformOrderEntry))
    commit('SET_ORDERS_RESULTS_META_DATA', { currentPage, pageSize, sort, totalPages })
  },
  async setOrderDetailsData ({ commit }, orderId) {
    return zApiService.orders.getOrderById(orderId)
      .then(res => {
        commit('SET_ORDER_DETAILS_DATA', transformOrderEntry(res))
      })
  },
  async trackGuestOrder ({ commit }, requestParams) {
    const orderData = await zApiService.orders.trackGuestOrder(requestParams)
    commit('SET_TRACK_ORDER_DATA', transformOrderEntry(orderData))
  },
  async sendReorder ({ dispatch }, orderId) {
    await zApiService.orders.sendReorder({ orderId })
    await dispatch('cart/setCartData', { showProductsPriceChange: true }, { root: true })
  }
}

const getters = {
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
