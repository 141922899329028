<template>
  <div
    :disabled="disabled || model.disabled"
    class="form-group"
    :class="{
      'form-group--inline': model.inline,
      'form-group--disabled': disabled || model.disabled,
      'form-group--shadow': model.variant === 'shadow',
      'form-group--error': isValidationRun && field.value && field.value.$invalid
    }"
  >
    <span class="form-group__label"
      v-if="model.placeholder"
      v-html="model.placeholder"
    ></span>

    <the-mask
      v-if="model.inputType === 'the-mask'"
      v-model="model.value"
      @input="$emit('input', model.value)"
      :mask="model.mask"
      :tokens="model.tokens"
      :masked="false"
      :type="model.type"
      :maxlength="model.maxLength"
      :readonly="model.readonly"
      :disabled="disabled || model.disabled"
      class="form-group__input form-input"
      :class="{
        'form-input--shadow': model.variant === 'shadow',
        'form-input--disabled': model.disabled,
        'form-input--error': isValidationRun && field.value && field.value.$invalid
      }"
    />

    <input
      v-else-if="model.type !== 'textarea'"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :type="model.type"
      :disabled="disabled || model.disabled"
      :maxlength="model.maxLength"
      :readonly="model.readonly"
      :placeholder="model.placeholderText"
      class="form-group__input form-input"
      :class="{
        'form-input--shadow': model.variant === 'shadow',
        'form-input--disabled': model.disabled,
        'form-input--error': isValidationRun && field.value && field.value.$invalid
      }"
    />

    <textarea
      v-else
      @change="$emit('input', $event.target.value)"
      :maxlength="model.maxLength"
      :readonly="model.readonly"
      :value="value"
      :disabled="disabled || model.disabled"
      class="form-group__input form-textarea"
      :class="{
        'form-textarea--shadow': model.variant === 'shadow',
        'form-textarea--disabled': model.disabled,
        'form-textarea--error': isValidationRun && field.value && field.value.$invalid
      }"
    />

    <template v-if="isValidationRun && errors">
      <span
        v-for="(error, idx) in errors"
        :key="idx"
        v-html="error"
        class="form-group__hint"
      ></span>
    </template>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'

import FormControlMixin from '@/mixins/FormControlMixin'

export default {
  components: {
    TheMask
  },
  mixins: [FormControlMixin]
}
</script>
