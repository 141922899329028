import VTooltip from 'v-tooltip'
import Vue from 'vue'
import ClickOutside from 'vue-click-outside'
import Vuelidate from 'vuelidate'
import { sync } from 'vuex-router-sync'

import Loader from '@/components/Loader'
import VueScriptComponent from '@/components/vendor/vue-script-component/VueScriptComponent'
import { setWindowWebpBrowserSupportFlag } from '@/helpers'
import AnalyticsService from '@/services/analytics'
import FullstoryService from '@/services/fullstory'
import settings from '@/settings'
import '@/styles/app.scss'
import App from './App.vue'
import router from './router'
import store from './store'

sync(store, router)

Vue.use(Vuelidate)
Vue.directive('click-outside', ClickOutside)
Vue.use(VTooltip, { defaultClass: 'tooltip', defaultTrigger: 'hover focus click' })
Vue.config.productionTip = false
Vue.component('loader', Loader)
Vue.component('VueScriptComponent', VueScriptComponent)

setWindowWebpBrowserSupportFlag()

// TODO: Uncomment when the redirect microservice is ready
// zApiService.sites.checkUrlRedirect({
//   sourceUrl: `${window.location.origin}${window.location.pathname}`
// }).then(data => {
//   if (window.location.pathname === '/category/featured-products' || window.location.pathname === '/category/featured-products/products') {
//     window.location.href = '/category/featured-categories/featured-products/products'
//   }
//   const redirectUrl = data?.targetURL ? encodeURI(data.targetURL) : null
//   if (redirectUrl) window.location.href = redirectUrl
// })

FullstoryService.init()

function preconnectResources () {
  const algoliaResource = document.createElement('link')
  algoliaResource.href = `https://${settings.services.algolia.appId}-dsn.algolia.net`
  algoliaResource.rel = 'preconnect'
  document.head.appendChild(algoliaResource)

  const contentfulResource = document.createElement('link')
  contentfulResource.href = 'https://cdn.contentful.com'
  contentfulResource.rel = 'preconnect'
  document.head.appendChild(contentfulResource)
}
preconnectResources()

AnalyticsService.init()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

console.log(`🛒 zCommerce Hubbell v${settings.app.version}`)
