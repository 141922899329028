<template>
  <div
    class="compare-checkbox"
    :class="{
      'compare-checkbox--active': activeState,
      'compare-checkbox--disabled': disabledState,
      'compare-checkbox--size-small': size === 'small',
    }"
  >
    <FormCheckbox
      @input="$emit('input', $event)"
      :field="checkboxField"
      :label="label"
      :value="value"
      :disabled="disabledState"
    />
  </div>
</template>

<script>
import FormCheckbox from '@/components/FormCheckbox'

export default {
  components: {
    FormCheckbox
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: 'Compare'
    },
    checkboxField: {
      type: Object,
      required: true
    },
    activeState: {
      type: Boolean,
      required: true
    },
    disabledState: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      default: 'normal'
    }
  }
}
</script>
