import settings from '@/settings'
import { detectIE, detectSafari } from '@/helpers'
import { createClient as createContentfulClient } from 'contentful'

const urlParams = new URLSearchParams(window.location.search)

const _client = createContentfulClient({
  space: settings.services.contentful.space.id,
  environment: settings.services.contentful.space.environment,
  accessToken: urlParams.has('preview') ? settings.services.contentful.previewAccessToken : settings.services.contentful.accessToken,
  host: urlParams.has('preview') ? settings.services.contentful.previewHost : settings.services.contentful.host
})

export default {
  getEntriesByQuery (query) {
    return _client.getEntries(query).then(response => response.items || null)
  },
  getEntryByTypeAndKey (type, key) {
    return _client.getEntries({
      skip: 0,
      limit: 1,
      content_type: type,
      include: 5,
      'fields.key': key
    }).then(response => response.items ? response.items[0] : null)
  },
  extractContentType (entry) {
    return entry?.sys?.contentType?.sys?.id
  },
  extractEntryFromIncludesByType (field, type) {
    const linkEntry = field?.fields?.includes
      ? field.fields.includes.find(el => this.extractContentType(el) === type)
      : null
    return linkEntry
      ? {
        title: linkEntry.fields.title,
        url: linkEntry.fields.url
      }
      : null
  },
  extractImageUrl (imageField, quality = 80, width = '', height = '') {
    if (!imageField || !Object.keys(imageField).length || !('fields' in imageField) || !('file' in imageField.fields)) {
      return null
    }
    const format = detectIE() || detectSafari() ? 'fl=progressive' : 'fm=webp'
    return `${imageField.fields.file.url}?${format}&q=${quality}&w=${width}&h=${height}`
  }
}
