import ApiBase from '@/services/z-api/v1/api-base'

// @TODO cleanup once api cart is ready
class Checkout extends ApiBase {
  createNewCart (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts`, {})
    })
  }

  /**
   * Return cart.
   */
  getCart (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/carts/current`
      )
    })
  }

  /** Unused */
  createCart (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts`, data)
    })
  }

  /**
   * Set cart contact address.
   * @param data
   */
  /** Unused */
  setCartContactAddress ({ addressData }, userId) {
    return this._withDecoratedPromise(() => {
      // @TODO get comments on diff
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/addresses/contact`, addressData)
    })
  }

  /**
   * Set cart shipping address data.
   * @param addressData
   */
  setCartShippingAddressData (addressData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/addresses/delivery`, addressData)
    })
  }

  /**
   * Set cart shipping address data.
   * @param addressId
   */
  setCartShippingAddressId (addressId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/addresses/delivery`, addressId)
    })
  }

  deleteShippingAddressFromCart (addressId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/v1/users/${this._getUserId(userId)}/carts/current/addresses/delivery`)
    })
  }

  /**
   * Set cart billing address data.
   * @param addressData
   */
  /** Unused */
  setCartBillingAddressData ({ addressData }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/addresses/billing`, addressData)
    })
  }

  /**
   * Set cart billing address Id.
   * @param data addressId
   */
  /** Unused */
  setCartBillingAddressId ({ addressId }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/addresses/billing`, addressId)
    })
  }

  /**
   * Set payment method.
   * @param data
   */
  /** Unused */
  setPaymentMethod ({ data }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/paymentmethod`, data)
    })
  }

  /**
   * Update notification email list.
   * @param emails
   */
  /** Unused */
  updateNotificationEmailList ({ emails, type }, userId) {
    return this._withDecoratedPromise(() => {
      const data = { emails: emails }
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/${encodeURIComponent(type)}/notifications`, data)
    })
  }

  /**
   * Add product to the cart.
   * @returns {Promise<any>}
   */
  addProduct (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries`, data)
    })
  }

  /**
   * Update product quantity in the cart.
   */
  updateProductQty (entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}`, data)
    })
  }

  /**
   * Update product from the product page.
   */
  /** Unused */
  updateProduct (entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/v2/entries/${entryId}`, data)
    })
  }

  /**
   * Delete product from the cart.
   */
  deleteProduct (entryId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}`)
    })
  }

  /**
   * Duplicate product to the cart.
   * @returns {Promise<any>}
   */
  /** Unused */
  duplicateProduct (entryId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}/duplicate`)
    })
  }

  /**
   * Get users's unit related customers.
   * Does not appear to be used.
   */
  /** Unused */
  getDeliveryContacts (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${this._getUserId(userId)}/unitcustomers`)
    })
  }

  /**
   * Apply delivery contact to cart.
   * Does not appear to be used.
   */
  /** Unused */
  setDeliveryContact (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/delivery/contact`, data)
    })
  }

  /**
   * Apply delivery address for cart entry.
   */
  /** Unused */
  setProductShippingAddress (entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}/addresses/delivery`, data)
    })
  }

  /**
   * Remove delivery address from cart entry.
   */
  /** Unused */
  deleteProductShippingAddress (entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}/addresses/delivery`, data)
    })
  }

  /**
   * Apply delivery contact for cart entry.
   */
  /** Unused */
  setProductDeliveryContact (entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}/contact/delivery`, data)
    })
  }

  /**
   * Place quote.
   */
  /** Unused */
  placeQuote (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/quote`, data)
    })
  }

  /**
   * Delete cart for the logged in user.
   */
  deleteCart (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/v1/users/${this._getUserId(userId)}/carts/current`)
    })
  }

  setGuestEmail (email, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/anonymous/carts/current/email?email=${email}`)
    })
  }

  getDeliveryModes (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${this._getUserId(userId)}/carts/current/deliverymodes`)
    })
  }

  getDeliveryModesCost (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${this._getUserId(userId)}/carts/current/deliverymodescost`)
    })
  }

  setDeliveryMode (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/deliverymode`, data)
    })
  }

  getPaymentParams (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${this._getUserId(userId)}/carts/current/paymentiframeoptions`)
    })
  }

  setVoucher (voucher, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/vouchers`, voucher)
    })
  }

  deleteVoucher (voucher, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/v1/users/${this._getUserId(userId)}/carts/current/vouchers/${encodeURIComponent(voucher)}`)
    })
  }

  placeOrder (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/placeOrder`)
    })
  }

  mergeCarts (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts`, data)
    })
  }

  recalculatePoductsPrice (cartId = 'current', userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/${cartId}/viewed`)
    })
  }

  saveCartItemEntryAdditionalData ({ entryNumber, data }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryNumber}/properties`, data, {
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
  }

  getSaveForLater (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${this._getUserId(userId)}/carts/current/saveForLater`)
    })
  }

  addProductToSaveForLater (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/v1/users/${this._getUserId(userId)}/carts/current/saveForLater`, data)
    })
  }

  updateProductInSaveForLater (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(`/rest/v1/users/${this._getUserId(userId)}/carts/current/saveForLater`, data)
    })
  }

  deleteProductFromSaveForLater (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/v1/users/${this._getUserId(userId)}/carts/current/saveForLater`, data)
    })
  }
}

export default Checkout
