import { removeAccessToken } from '@/services/auth'
import zApiService from '@/services/z-api'
import { transformAddressEntry, transformProductsListItemEntry, transformUserEntry } from '@/transformers'

const state = {
  userData: null,
  shippingAddressData: null,
  defaultShippingAddresses: null,
  favoriteProductsCodes: null,
  emailSubscriptionsData: null,
  productsLists: null
}

const mutations = {
  SET_USER_DATA (state, data) {
    state.userData = data
  },
  SET_SHIPPING_ADDRESS_DATA (state, data) {
    state.shippingAddressData = data && data.length
      ? data.map(el => transformAddressEntry(el))
      : null
  },
  SET_DEFAULT_SHIPPING_ADDRESSES (state, data) {
    state.defaultShippingAddresses = data
  },
  SET_FAVORITE_PRODUCTS_CODES (state, data) {
    state.favoriteProductsCodes = data
  },
  SET_PRODUCTS_LISTS (state, data) {
    state.productsLists = data
  },
  SET_EMAIL_SUBSCRIPTIONS_DATA (state, data) {
    state.emailSubscriptionsData = data
  }
}

const actions = {
  async setUserData ({ commit }, userId) {
    return zApiService.user.accountDetails(userId)
      .then(userData => commit('SET_USER_DATA', transformUserEntry(userData.user)))
  },

  async updateUserData ({ dispatch }, data) {
    return zApiService.user.updateSomeUserInfoFields(data, 'current')
      .then(() => dispatch('setUserData'))
  },

  async setShippingAddressData ({ commit }) {
    return zApiService.address.getShippingAddresses()
      .then(userData => commit('SET_SHIPPING_ADDRESS_DATA', userData))
  },

  async resetUserData ({ commit }) {
    commit('SET_USER_DATA', null)
    removeAccessToken()
    localStorage.clear()
  },

  async addShippingAddress ({ dispatch }, data) {
    return zApiService.address.addShippingAddress(data)
      .then(() => dispatch('setShippingAddressData'))
  },

  async updateShippingAddress ({ dispatch }, data) {
    return zApiService.address.updateShippingAddress(data)
      .then(() => dispatch('setShippingAddressData'))
  },

  async deleteShippingAddress ({ dispatch }, addressId) {
    return zApiService.address.deleteShippingAddress(addressId)
      .then(() => dispatch('setShippingAddressData'))
  },

  async validateShippingAddress (context, addressId) {
    return zApiService.address.validateShippingAddress(addressId)
  },

  async getDefaultShippingAddessesByDeliveryModeCode ({ commit, state }, { code }) {
    const addressesData = await zApiService.address.getDefaultShippingAddessesByDeliveryModeCode({ code })
    commit('SET_DEFAULT_SHIPPING_ADDRESSES', addressesData.map(el => {
      return {
        ...transformAddressEntry(el),
        firstName: state.userData?.firstName,
        lastName: state.userData?.lastName
      }
    }))
    return Promise.resolve()
  },

  async setFavoriteProductsCodes ({ commit }) {
    const rawLists = await zApiService.favorites.getFavoriteProductsCodes()
    const favoritesList = rawLists?.find(list => list.defaultList)
    if (favoritesList) commit('SET_FAVORITE_PRODUCTS_CODES', favoritesList.productCodes)
  },

  async setProductsLists ({ commit }) {
    const rawLists = await zApiService.favorites.getProductsLists()
    commit('SET_PRODUCTS_LISTS', rawLists.map(rawList => {
      return {
        id: rawList.id,
        name: rawList.name,
        products: rawList.entries.map(transformProductsListItemEntry),
        isFavorites: rawList.defaultList
      }
    }).sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })).sort((a, b) => b.isFavorites - a.isFavorites))
  },

  async createProductsList ({ dispatch }, { listName }) {
    const rawCreatedList = await zApiService.favorites.createProductsList({ listName })
    await dispatch('setProductsLists')
    return {
      id: rawCreatedList.id,
      name: rawCreatedList.name,
      products: rawCreatedList.entries.map(transformProductsListItemEntry),
      isFavorites: rawCreatedList.defaultList
    }
  },

  async renameProductsList ({ dispatch }, { listId, listName }) {
    await zApiService.favorites.updateProductList({ listId, listName })
    await dispatch('setProductsLists')
  },

  async deleteProductsList ({ dispatch }, { listId }) {
    await zApiService.favorites.deleteProductsList({ listId })
    await dispatch('setProductsLists')
  },

  async addProductToList ({ state, dispatch }, { listId, productCode }) {
    await zApiService.favorites.addProductToList({ listId, productCode })

    const favoritesListId = state.productsLists?.find(list => list.isFavorites)?.id
    if (favoritesListId === listId) await dispatch('setFavoriteProductsCodes')

    await dispatch('setProductsLists')
  },

  async updateProductInList ({ dispatch }, { listId, productCode, quantity }) {
    await zApiService.favorites.updateProductInList({ listId, productCode, quantity })
    await dispatch('setProductsLists')
  },

  async deleteProductFromList ({ dispatch }, { listId, productCode }) {
    await zApiService.favorites.deleteProductFromList({ listId, productCode })
    await dispatch('setProductsLists')
  },

  async deleteAllProductsFromList ({ dispatch }, { listId }) {
    await zApiService.favorites.deleteAllProductsFromList({ listId })
    await dispatch('setProductsLists')
  },

  async addProductToFavorites ({ dispatch, state }, { productCode }) {
    if (!state.productsLists) await dispatch('setProductsLists')
    const favoritesListId = state.productsLists.find(list => list.isFavorites)?.id
    await zApiService.favorites.addProductToList({
      listId: favoritesListId,
      productCode
    })
    await dispatch('setFavoriteProductsCodes')
  },

  async deleteProductFromFavorites ({ state, dispatch }, { productCode }) {
    if (!state.productsLists) await dispatch('setProductsLists')
    const favoritesListId = state.productsLists.find(list => list.isFavorites)?.id
    await zApiService.favorites.deleteProductFromList({
      listId: favoritesListId,
      productCode
    })
    await dispatch('setFavoriteProductsCodes')
  },

  async setEmailSubscriptionsData ({ commit, getters }) {
    const {
      newProductAnnouncements,
      salesAndDiscounts,
      unsubscribe
    } = await zApiService.analytics.getEmailSubscriptions(getters.isGuest ? 'anonymous' : 'current')
    commit('SET_EMAIL_SUBSCRIPTIONS_DATA', { newProductAnnouncements, salesAndDiscounts, unsubscribe })
    return Promise.resolve()
  },

  async updateEmailSubscriptionsData ({ getters }, {
    email,
    newProductAnnouncements,
    salesAndDiscounts,
    pageName,
    pageUri,
    unsubscribe = false
  }) {
    await zApiService.analytics.updateEmailSubscriptions(
      { email, newProductAnnouncements, salesAndDiscounts, pageName, pageUri, unsubscribe },
      getters.isGuest ? 'anonymous' : 'current'
    )
    return Promise.resolve()
  },

  async sendInvitationsEmail (context, { email }) {
    await zApiService.analytics.sendInvitationsEmail({ email })
    return Promise.resolve()
  },

  async applyInvitationsCode ({ dispatch }, code) {
    const isValid = await zApiService.user.applyInvitationsCode(code)
    if (isValid) await dispatch('setUserData')
    return Promise.resolve(isValid)
  }
}

const getters = {
  profileCompletionPercentage: state => {
    if (!state.userData) return null
    const requiredFields = ['firstName', 'lastName', 'productInterests', 'customerRole']
    const completedFields = requiredFields.filter(field => !!state.userData[field])
    const completionPercentage = completedFields.length * 100 / requiredFields.length
    return completionPercentage
  },
  isGuest: state => !state.userData,
  isPublic: state => !state.userData || state.userData?.groups.includes('public'),
  isEmployee: state => state.userData?.groups.includes('employee'),
  isAffiliate: state => state.userData?.groups.includes('affiliate'),
  analyticsAffiliationGroup: state => {
    const isEmployee = state.userData?.groups.includes('employee')
    const isAffiliate = state.userData?.groups.includes('affiliate')
    return isEmployee ? 'Employee' : isAffiliate ? 'Affiliate' : 'Public'
  },
  userGroupCollectionName: state => {
    const isPublic = !state.userData || state.userData?.groups.includes('public')
    const isEmployee = state.userData?.groups.includes('employee')
    const isAffiliate = state.userData?.groups.includes('affiliate')
    if (isAffiliate) return 'affiliateUserItems'
    if (isEmployee) return 'employeeUserItems'
    if (isPublic) return 'publicUserItems'
  },
  userId: state => state.userData?.uid || 'anonymous',
  isUserHasEmailSubscription: state => state.emailSubscriptionsData
    ? !!(state.emailSubscriptionsData.newProductAnnouncements || state.emailSubscriptionsData.salesAndDiscounts)
    : false
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
