import AlgoliaService from '@/services/algolia'
import zApiService from '@/services/z-api'
import { transformProductEntry, transformProductSearchEntry, transformEntryByType } from '@/transformers'
import ContentfulService from '@/services/contentful'
import { constructExternalPDPLink } from '@/helpers'
import settings from '@/settings'

const state = {
  searchPageData: null,
  productsData: null,
  initFacetsData: null,
  queryFacetsData: null,
  resultsMetaData: null,
  defaultHitsPerPage: 15,
  suggestedProductsData: null,
  querySuggestionsData: null,
  facetBlackList: ['Public Store', 'Affiliate Store', 'Employee Store', 'isDiscountedPrice'],
  scrollPosition: 0,
  previousSearchPagePath: null
}

const mutations = {
  SET_SEARCH_PAGE_DATA (state, data) {
    state.searchPageData = Object.freeze(data)
  },
  SET_PRODUCTS_DATA (state, data) {
    state.productsData = Object.freeze(data)
  },
  SET_INIT_FACETS_DATA (state, data) {
    state.initFacetsData = Object.freeze(data)
  },
  SET_QUERY_FACETS_DATA (state, data) {
    state.queryFacetsData = Object.freeze(data)
  },
  SET_RESULTS_META_DATA (state, data) {
    state.resultsMetaData = Object.freeze(data)
  },
  SET_SUGGESTED_PRODUCTS_DATA (state, data) {
    state.suggestedProductsData = Object.freeze(data)
  },
  SET_QUERY_SUGGESTIONS_DATA (state, data) {
    state.querySuggestionsData = Object.freeze(data)
  },
  UNSET_SUGGESTED_PRODUCTS_DATA (state) {
    state.suggestedProductsData = null
  },
  SET_SCROLL_POSITION (state, data) {
    state.scrollPosition = data
  },
  SET_PREVIOUS_SEARCH_PAGE_PATH (state, data) {
    state.previousSearchPagePath = data
  }
}

const actions = {
  async setSearchPageData ({ commit }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'pageSearch',
      'default-page-search'
    )

    const data = {
      key: entry?.fields?.key,
      showBannerAd: typeof entry?.fields?.showBannerAd === 'boolean' ? entry.fields.showBannerAd : true,
      bannerAd: entry?.fields?.bannerAd ? transformEntryByType(entry.fields.bannerAd) : null,
      meta: entry?.fields?.meta ? transformEntryByType(entry?.fields?.meta) : null
    }
    commit('SET_SEARCH_PAGE_DATA', data)
  },
  async setSearchResultsData (
    { commit, state, rootState, rootGetters, dispatch },
    { filters, query, page, sortBy, hitsPerPage, isCatalog, isDiscountedPrice, options, ...rest }
  ) {
    const isPublic = rootGetters['user/isPublic']
    const isEmployee = rootGetters['user/isEmployee']
    const isAffiliate = rootGetters['user/isAffiliate']
    const isGuest = rootGetters['user/isGuest']
    filters = AlgoliaService.finalizeFilters({ filters, isPublic, isEmployee, isDiscountedPrice, isAffiliate })

    const getFilterString = facets => {
      return facets.map(el => `(${el.join(' OR ')})`).join(' AND ')
    }
    const extractFacetName = facetValue => facetValue.split("'").join('').split(':')[0].split('.lvl')[0]

    const groupedFacetsByName = {}
    filters.forEach(facetValuesArray => {
      const facetName = extractFacetName(facetValuesArray[0])
      if (groupedFacetsByName[facetName]) {
        groupedFacetsByName[facetName].push(...facetValuesArray)
      } else {
        groupedFacetsByName[facetName] = [...facetValuesArray]
      }
    })
    const groupedFacetValues = Object.values(groupedFacetsByName)
    const requests = [
      {
        indexName: AlgoliaService.getProductIndexName(sortBy),
        params: {
          query,
          page,
          facets: ['*'],
          hitsPerPage,
          filters: getFilterString(groupedFacetValues),
          clickAnalytics: true
        }
      }
    ]
    for (const facetName in groupedFacetsByName) {
      if (state.facetBlackList.includes(facetName)) continue
      const filtersForRequest = groupedFacetValues.filter(facetGroup => extractFacetName(facetGroup[0]) !== facetName)
      const facetsForRequest = new Set()

      groupedFacetsByName[facetName].forEach(facetValue => {
        const facetNameWithLvl = facetValue.split("'").join('').split(':')[0]
        facetsForRequest.add(facetNameWithLvl)
        if (facetNameWithLvl.includes('.lvl')) {
          let facetLvl = 0
          while (facetLvl <= 3) {
            const parentFacetNameWithLvl = `${facetName}.lvl${facetLvl}`
            facetsForRequest.add(parentFacetNameWithLvl)
            facetLvl++
          }
        }
      })
      requests.push({
        indexName: AlgoliaService.getProductIndexName(sortBy),
        params: {
          query,
          page,
          facets: Array.from(facetsForRequest),
          hitsPerPage: 1,
          filters: getFilterString(filtersForRequest)
        }
      })
    }

    const { results } = await AlgoliaService.getClient().multipleQueries(requests)
    const { hits, nbHits, nbPages, queryID, page: currentPage } = results[0]

    let facets = {}
    results.forEach(result => {
      facets = Object.assign(facets, result.facets)
    })

    const decoratedProductsWithoutPrices = hits.map(el => transformProductSearchEntry({
      entry: el,
      categories: rootState.catalogs.catalogsData.allCatalogs,
      hasFinalizedPrices: !isEmployee
    }))
    const decoratedProducts = options.getPrices
      ? await dispatch('getDecoratedProducts', {
        requestData: { products: hits.map(el => ({ code: el?.objectID })) },
        products: hits.map(el => transformProductSearchEntry({
          entry: el,
          categories: rootState.catalogs.catalogsData.allCatalogs,
          hasFinalizedPrices: !isEmployee
        }))
      })
      : decoratedProductsWithoutPrices

    if (options.isImpressionsAnalyticsEnabled) {
      let newProductsPosition = page ? state.productsData.length : 0
      const productsImpressionsData = decoratedProducts.map(product => {
        ++newProductsPosition
        return {
          product,
          position: newProductsPosition,
          list: isCatalog ? 'Category' : 'Search Results'
        }
      })
      dispatch('analytics/populateImpressionsData', productsImpressionsData, { root: true })
    }

    commit('SET_RESULTS_META_DATA', {
      totalQty: nbHits,
      totalPages: hitsPerPage > state.defaultHitsPerPage
        ? Math.ceil(nbHits / state.defaultHitsPerPage) : nbPages,
      page: hitsPerPage > state.defaultHitsPerPage
        ? (hitsPerPage / state.defaultHitsPerPage) - 1 : currentPage,
      indexName: AlgoliaService.getProductIndexName(sortBy),
      queryID
    })
    commit('SET_QUERY_FACETS_DATA', facets)
    commit('SET_PRODUCTS_DATA', page
      ? [...state.productsData, ...decoratedProducts]
      : decoratedProducts
    )

    if (!hits?.length && !isCatalog) dispatch('setSuggestedResultsOnNoResultsFound', query)
    if (!isGuest && !rootState.user.favoriteProductsCodes) dispatch('user/setFavoriteProductsCodes', null, { root: true })
  },
  async setQuerySuggestionsData ({ commit }, { query }) {
    if (!query || !settings.services.algolia.productIndexSuggestions) {
      commit('SET_QUERY_SUGGESTIONS_DATA', null)
      return
    }
    const { hits } = await AlgoliaService.getQuerySuggestions({ query, hitsPerPage: 8 })
    const querySuggestions = hits.map(hit => ({
      query: hit.query,
      facet: ''
    }))
    const facetSuggestions = []
    hits.forEach(hit => {
      const list = hit.Products?.facets?.exact_matches?.['Categories.lvl1'] || hit.Products?.facets?.exact_matches?.['Categories.lvl0']
      if (list) {
        list.forEach(listItem => {
          facetSuggestions.push({
            query: hit.query,
            facet: listItem.value
          })
        })
      }
    })
    const querySuggestionsData = {
      querySuggestions,
      facetSuggestions: facetSuggestions.slice(0, 8)
    }
    commit('SET_QUERY_SUGGESTIONS_DATA', querySuggestionsData)
  },
  async setSuggestedResultsOnNoResultsFound ({ commit }, query) {
    const { hits } = await AlgoliaService.getSearchAppProductIndex().search(query, { hitsPerPage: 12 })
    const suggestedProductsData = hits?.length
      ? hits.map(transformProductEntry).map(product => ({
        title: product.title,
        descriptionShort: product.descriptionShort,
        image: product.image,
        link: constructExternalPDPLink(product.sku)
      }))
      : null
    commit('SET_SUGGESTED_PRODUCTS_DATA', suggestedProductsData)
  },
  async getSearchDataByQueryParams ({ rootGetters }, {
    query = '',
    filters = '',
    facets = ['*'],
    sortBy = 'relevance',
    hitsPerPage = 20
  } = {}) {
    const isPublic = rootGetters['user/isPublic']
    const isEmployee = rootGetters['user/isEmployee']
    const isAffiliate = rootGetters['user/isAffiliate']
    const queryParams = AlgoliaService.finalizeQueryParams({
      filters,
      facets,
      hitsPerPage
    }, { isPublic, isEmployee, isAffiliate })

    const res = await AlgoliaService.getProductIndex(sortBy).search(query, queryParams)
    return res
  },
  async setInitFacetsData ({ commit, dispatch }) {
    const { facets } = await dispatch('getSearchDataByQueryParams')
    commit('SET_INIT_FACETS_DATA', facets)
  },
  async getPricesForProducts (context, { requestData }) {
    const { products: rawProducts } = await zApiService.products.getPricesForProducts(requestData)
    const rawProductsWithPrices = rawProducts?.filter(el => el.customPrices) || []
    const productsWithPrices = rawProductsWithPrices.map(el => transformProductEntry(el))
    return productsWithPrices
  },
  // @TODO get rid of this once proper employee price fetching is done
  async getDecoratedProducts ({ dispatch }, { requestData, products, productsWithPrices }) {
    const shouldLoadPrices = !productsWithPrices
    if (shouldLoadPrices) productsWithPrices = await dispatch('getPricesForProducts', { requestData })

    const decoratedProducts = [...products.map(product => {
      const {
        employeePrice = null,
        listPrice = null,
        clearancePrice = null,
        affiliatePrice = null,
        discountPrice = null,
        minPrice = null,
        hasPrice
      } = productsWithPrices.find(productWithPrice => product.sku === productWithPrice.sku) || {}
      return {
        ...product,
        employeePrice: employeePrice || product.employeePrice,
        listPrice: listPrice || product.listPrice,
        clearancePrice: clearancePrice || product.clearancePrice,
        affiliatePrice: affiliatePrice || product.affiliatePrice,
        discountPrice: discountPrice || product.discountPrice,
        minPrice: minPrice || product.minPrice,
        hasPrice: hasPrice,
        hasFinalizedPrices: true
      }
    })]

    return decoratedProducts
  },
  async getSearchDataBySkuList ({ dispatch }, skuList) {
    const filters = skuList.map(sku => `objectID:${sku}`).join(' OR ')
    return dispatch('getSearchDataByQueryParams', { filters: `(${filters})` })
  },
  async getTransformedSearchProductsByQueryParams ({ dispatch, rootState }, {
    query = '',
    filters = '',
    facets = ['*'],
    sortBy = 'relevance',
    hitsPerPage = 20
  } = {}) {
    const { hits } = await dispatch('getSearchDataByQueryParams', { query, filters, facets, sortBy, hitsPerPage })

    const transformedProducts = await dispatch(
      'getDecoratedProducts',
      {
        requestData: { products: hits.map(el => ({ code: el?.objectID })) },
        products: hits.map(el => transformProductSearchEntry({ entry: el, categories: rootState.catalogs.catalogsData.allCatalogs }))
      }
    )
    return transformedProducts
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
