import ApiBase from '@/services/z-api/v1/api-base'

class Orders extends ApiBase {
  /**
   * Get a list of recent orders.
   * @returns {Promise<any>}
   */
  getOrders (params, userId) {
    return this._withDecoratedPromise(() => {
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/orderHistory?${params}`
      )
    })
  }

  /**
   * Get an order by ID.
   * @param orderId
   * @param userId
   * @returns {Promise<any>}
   */
  getOrderById (orderId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/orders/${encodeURIComponent(
          orderId
        )}`
      )
    })
  }

  /**
   * Track guest order.
   * @param params - orderNumber, orderEmail
   * @returns {Promise<any>}
   */
  trackGuestOrder (params) {
    return this._withDecoratedPromise(() => {
      params = new URLSearchParams(params).toString()
      return this.transport.get(`/rest/v1/trackOrder?${params}`)
    })
  }

  /**
   * Send Reorder
   * @param userId
   * @param orderId
   * @param data
   */
  sendReorder ({ orderId }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/v1/users/${this._getUserId(
          userId
        )}/orderHistory/carts/current/orders/${encodeURIComponent(orderId)}`
      )
    })
  }

  /**
   * Download an archive of order docs.
   * @returns {Promise<any>}
   */
  /** Unused */
  downloadDocs (docsList) {
    return this._withDecoratedPromise(() => {
      const requestOptions = {
        responseType: 'arraybuffer'
      }
      return this.transport.post(
        '/rest/v1/media/zip',
        docsList,
        null,
        requestOptions
      )
    })
  }
}

export default Orders
