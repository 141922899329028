<template>
  <div class="is-centered">
    <slot name="body" class="is-centered"></slot>
  </div>
</template>

<script>
export default {
  name: 'ErrorOverlay'
}
</script>
