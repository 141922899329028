<template>
  <div class="product-carousel" ref="carousel">
    <carousel
      :class="carouselClass"
      :perPageCustom="itemsPerPage"
      :scrollPerPage="scrollPerPage"
      :mouse-drag="false"
      :navigation-enabled="navigationEnabled"
      :pagination-enabled="paginationEnabled"
      :loop="loop"
      :autoplay="autoplay"
      :autoplayTimeout="autoplayTimeout"
      @pagination-click="handlePaginationClick"
      @navigation-click="handleNavigationClick"
    >
      <slide
        v-for="(product, idx) in productsList"
        :key="product.title + idx"
        class="product-carousel__item"
      >
        <carousel-product
          @productClicked="$emit('productClicked')"
          @addProductToCart="$emit('addProductToCart', { productData: product, addToCartQty: $event})"
          :productData="product"
          :isPriceVisible="isPriceVisible"
          :isAddToCartVisible="isAddToCartVisible"
          :analyticsData="analyticsData"
          :isComparable="isComparable"
          :compareMainProduct="compareMainProduct"
        ></carousel-product>
      </slide>
    </carousel>
  </div>
</template>

<script>
import _ from 'lodash'
import { Carousel, Slide } from 'vue-carousel'
import { mapActions } from 'vuex'

import { checkIsElementInView } from '@/helpers'
import CarouselProduct from '@/components/CarouselProduct'

export default {
  name: 'ProductCarousel',
  components: {
    Carousel,
    Slide,
    CarouselProduct
  },
  props: {
    productsList: {
      type: Array,
      required: true
    },
    carouselClass: {
      type: String,
      default: 'carousel-arrow-navigation'
    },
    scrollPerPage: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Array,
      default: () => [[0, 1], [768, 3], [1024, 5], [1700, 5]]
    },
    navigationEnabled: {
      type: Boolean,
      default: true
    },
    paginationEnabled: {
      type: Boolean,
      default: false
    },
    isPriceVisible: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayTimeout: {
      type: Number
    },
    analyticsData: {
      default: null
    },
    isAddToCartVisible: {
      type: Boolean,
      default: false
    },
    isComparable: {
      type: Boolean,
      default: false
    },
    compareMainProduct: {
      type: Object,
      default: null
    }
  },
  watch: {
    isVisible (val) {
      this.handleInitialProductsLoad(val)
    }
  },
  computed: {
    isImpressionsAnalyticsEnabled () {
      return this.analyticsData?.enableImpressions
    }
  },
  methods: {
    ...mapActions('analytics', ['populateImpressionsData']),
    handleInitialProductsLoad (isVisible) {
      if (isVisible && !this.viewedItems.length) {
        const visibleOnLoadProducts = this.productsList.slice(0, this.currentItemsPerPage)
        this.viewedItems.push(...visibleOnLoadProducts.map(this.transformImpressionsAnalyticsProduct))
        this.lastActiveCarouselItem = this.viewedItems.length
        this.populateImpressionsData(this.viewedItems)
      }
    },
    handlePaginationClick (page) {
      if (this.isImpressionsAnalyticsEnabled && page !== 0) {
        const firstItemOfPageIndex = page * this.currentItemsPerPage
        let positionOfNextProductToAdd = firstItemOfPageIndex
        const productsToAdd = this.productsList
          .slice(firstItemOfPageIndex, firstItemOfPageIndex + this.currentItemsPerPage)
          .map(el => {
            ++positionOfNextProductToAdd
            return this.transformImpressionsAnalyticsProduct(el, positionOfNextProductToAdd - 1)
          })
        if (!productsToAdd.every(el => this.viewedItems.map(el => el.position).includes(el.position))) {
          this.viewedItems.push(...productsToAdd)
          this.populateImpressionsData(productsToAdd)
        }
      }
    },
    handleNavigationClick (e) {
      if (this.isImpressionsAnalyticsEnabled) {
        this.lastActiveCarouselItem = e === 'forward' ? ++this.lastActiveCarouselItem : --this.lastActiveCarouselItem
        const productToAdd = this.productsList[this.lastActiveCarouselItem - 1]
        if (e === 'forward' && productToAdd && !this.viewedItems.some(el => el.position === this.lastActiveCarouselItem)) {
          const transformedProductToAdd = this.transformImpressionsAnalyticsProduct(productToAdd, this.lastActiveCarouselItem - 1)
          this.viewedItems.push(transformedProductToAdd)
          this.populateImpressionsData([transformedProductToAdd])
        }
      }
    },
    scrollListener: _.throttle(function () {
      this.isVisible = checkIsElementInView(this.$refs.carousel, 25)
    }, 300),
    transformImpressionsAnalyticsProduct (product, idx) {
      return {
        product,
        position: idx + 1,
        list: this.analyticsData?.referencePage
      }
    },
    calculateCurrentItemsPerPage () {
      const screenWidth = window.innerWidth
      const itemsLessThenScreenWidth = this.itemsPerPage.filter(([screenBreakPoint]) => screenBreakPoint <= screenWidth)
      const currentScreenBreakPointNumber = Math.max(...itemsLessThenScreenWidth.map(([screenBreakPoint]) => screenBreakPoint))
      const currentItemsPerPage = itemsLessThenScreenWidth.find(([screenBreakPoint]) => screenBreakPoint === currentScreenBreakPointNumber)[1]
      this.currentItemsPerPage = currentItemsPerPage
    }
  },
  created () {
    this.calculateCurrentItemsPerPage()
  },
  mounted () {
    if (this.isImpressionsAnalyticsEnabled) {
      this.$nextTick(function () {
        this.isVisible = checkIsElementInView(this.$refs.carousel, 25)
        this.handleInitialProductsLoad(this.isVisible)
        window.addEventListener('scroll', this.scrollListener)
      })
    }
  },
  beforeDestroy () {
    if (this.isImpressionsAnalyticsEnabled) window.removeEventListener('scroll', this.scrollListener)
  },
  data () {
    return {
      currentItemsPerPage: 0,
      isVisible: false,
      viewedItems: [],
      lastActiveCarouselItem: 0
    }
  }
}
</script>
