import ApiBase from '@/services/z-api/v1/api-base'

class Address extends ApiBase {
  getShippingAddresses (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/addresses`
      )
    })
  }

  addShippingAddress (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/v1/users/${this._getUserId(userId)}/addresses`,
        data
      )
    })
  }

  deleteShippingAddress (addressId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/v1/users/${this._getUserId(userId)}/addresses/${addressId}`
      )
    })
  }

  updateShippingAddress (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/v1/users/${this._getUserId(userId)}/addresses/${data.id}`,
        data
      )
    })
  }

  validateShippingAddress (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/v1/users/${this._getUserId(userId)}/addresses/validate`,
        data
      )
    })
  }

  getDefaultShippingAddessesByDeliveryModeCode ({ code }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(
          userId
        )}/addresses/defaultAddresses/${code}`
      )
    })
  }
}

export default Address
