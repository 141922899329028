<template>
  <transition :name="animation">
    <div v-if="showModal" class="modal modal__mask" ref="overlay" @click="handleOverlayClick">
      <div class="modal__container">
        <div class="modal__container-inner">
          <div class="modal__header">
            <slot name="header"></slot>
          </div>
          <div class="modal__body">
            <slot name="body"></slot>
          </div>
          <div class="modal__footer">
            <slot name="footer"></slot>
          </div>
          <button
            @click="$emit('close')"
            class="modal__close button"
            type="button"
          >
            <i class="icon-times"></i>
          </button>
        </div>
        <loader v-if="isLoading"/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showModal: Boolean,
    isLoading: Boolean,
    animation: {
      type: String,
      required: false,
      default: 'fade'
    },
    isOverlayClickable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleOverlayClick (e) {
      if (this.isOverlayClickable && e.target === this.$refs.overlay) this.$emit('close')
    }
  }
}
</script>
