import { transformCatalogEntry } from '@/transformers'
import ContentfulService from '@/services/contentful'

const state = {
  catalogsData: null
}

const mutations = {
  SET_CATALOGS_DATA (state, data) {
    state.catalogsData = data
  }
}

const actions = {
  async setCatalogsData ({ commit, dispatch, rootState }) {
    const [contentfulCatalogs, { facets: algoliaCatalogs }] = await Promise.all([
      ContentfulService.getEntriesByQuery({
        skip: 0,
        content_type: 'pageCatalog',
        include: 5,
        limit: 1000
      }),
      dispatch(
        'search/getSearchDataByQueryParams',
        { facets: ['Categories*', 'Brands'], hitsPerPage: 1 },
        { root: true }
      )
    ])

    const grouped = Object.keys(algoliaCatalogs).map(catalogGroup => {
      return {
        groupTitle: catalogGroup,
        catalogs: Object.keys(algoliaCatalogs[catalogGroup]).map(value => transformCatalogEntry({
          value,
          catalogGroup,
          contentfulCatalogs,
          defaultPlpMeta: rootState.content.defaultPlpMeta
        }))
      }
    })

    // @TODO refactor the tree constructor
    grouped.forEach((catalogGroup) => {
      const [nestTitle, nestIdxStr] = catalogGroup.groupTitle.split('.lvl')
      const nestIdxInt = parseInt(nestIdxStr)
      if (nestIdxInt) {
        const parentFacet = grouped.find(el => el.groupTitle === nestTitle + '.lvl' + (nestIdxInt - 1))
        parentFacet.catalogs.forEach(parentValue => {
          parentValue.subValues = catalogGroup.catalogs
            .filter(el =>
              el.originalTitle.split(' > ').slice(0, -1).join(' > ') === parentValue.originalTitle
            )
          if (!parentValue.subValues.length) delete parentValue.subValues
        })
      }
    })

    const categories = grouped.find(el => el.groupTitle === 'Categories.lvl0')?.catalogs
    const brands = grouped.find(el => el.groupTitle === 'Brands')?.catalogs
    const allCatalogs = grouped.map(el => el.catalogs).flat()

    commit('SET_CATALOGS_DATA', { categories, brands, allCatalogs })
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
