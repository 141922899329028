<template>
  <div
    @click="handleCarouselProductClick"
    class="carousel-product"
  >
    <router-link :to=productData.link>
      <div class="carousel-product__img-wrap overlay">
        <img
          :src="productData.image.src"
          :alt="productData.image.title"
          :width="isMobile ? 310 : 224"
          :height="isMobile ? 310 : 257"
          loading="lazy"
          class="carousel-product__img overlay--img"
        />
      </div>
      <p
        v-if="showProductTitle"
        v-html="productData.title"
        class="carousel-product__title text-h3"
      ></p>
      <p class="carousel-product__sku" v-if="productData.catalogId && showProductCatalogId">
        Catalog ID: {{ productData.catalogId }}
      </p>
      <ProductPrice v-if="isPriceVisible && productData.hasPrice" class="carousel-product__price" :productData="productData" />
    </router-link>
    <div class="carousel-product__actions" v-if="productData.hasPrice && isAddToCartVisible">
      <div class="carousel-product__desc carousel-product__desc--incremental" v-if="productData.minOrderQty > 1">
        Incremental Order Quantity: {{ productData.minOrderQty }}
        <i
          class="icon-question-circle carousel-product__moq-tooltip"
          v-tooltip="`This item has an incremental order quantity of ${productData.minOrderQty};
            <br/> typing in a quantity value may result in rounding`"
        />
      </div>
      <div class="carousel-product__actions-wrap">
        <ProductQuantityInput
          :minOrderQty="productData.minOrderQty"
          v-model="addToCartQty"
        />
        <button
          type="button"
          @click="$emit('addProductToCart', addToCartQty)"
          class="search-item__button--cart button button--primary button--cart"
        >
          <i class="icon-shopping-cart"></i>
          Add to Cart
        </button>
      </div>
    </div>
    <FavoritesIcon v-if="isFavoriteVisible" :productCode="productData.sku" class="carousel-product__favorites" />
    <CompareCheckbox
      v-if="isComparable"
      :value="isCompareSelected"
      :checkboxField="{ label: productData.sku }"
      :activeState="isCompareSelected"
      :disabledState="!isCompareSelected && productsToCompare.length === maxProductsToCompare"
      @input="toggleCompareMainProductWithSelected({ mainProduct: compareMainProduct, secondaryProduct: productData })"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import ProductsCompareOverlayMixin from '@/mixins/ProductsCompareOverlayMixin'
import ProductPrice from '@/components/ProductPrice'
import FavoritesIcon from '@/components/FavoritesIcon'
import ProductQuantityInput from '@/components/ProductQuantityInput'
import CompareCheckbox from '@/components/CompareCheckbox'

export default {
  name: 'CarouselProduct',
  components: {
    ProductPrice,
    FavoritesIcon,
    ProductQuantityInput,
    CompareCheckbox
  },
  mixins: [ScreenWidthMixin, ProductsCompareOverlayMixin],
  props: {
    productData: {
      type: Object,
      required: true
    },
    isPriceVisible: {
      type: Boolean,
      default: false
    },
    isFavoriteVisible: {
      type: Boolean,
      default: false
    },
    analyticsData: {
      default: null
    },
    isAddToCartVisible: {
      type: Boolean,
      default: false
    },
    isComparable: {
      type: Boolean,
      default: false
    },
    showProductTitle: {
      type: Boolean,
      default: true
    },
    showProductCatalogId: {
      type: Boolean,
      default: true
    },
    compareMainProduct: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    handleCarouselProductClick () {
      if (this.analyticsData) this.setProductAnalyticsData({ referencePage: this.analyticsData.referencePage })
      this.$emit('productClicked')
    }
  },
  created () {
    this.addToCartQty = this.productData.minOrderQty
  },
  data () {
    return {
      addToCartQty: 1
    }
  }
}
</script>
