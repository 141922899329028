<template>
  <ul class="crumbs" v-if="crumbsData">
    <li
      v-for="crumb in crumbsData"
      :key="crumb.id"
      v-click-outside="() => toggleDropDown(crumb, false)"
      class="crumbs__item"
    >
      <router-link
        v-if="crumb.hasAction"
        :to="crumb.url"
        v-html="crumb.title"
        class="crumbs__link"
      ></router-link>
      <span v-else class="crumbs__text" v-html="crumb.title"></span>

      <i
        v-if="crumb.dropdownData && crumb.dropdownData.length"
        @click.prevent="toggleDropDown(crumb)"
        class="icon-down-arrow crumbs__dropdown-icon"
        :class="{ 'crumbs__dropdown-icon--active': crumb.isDropDownOpen }"
      ></i>
      <ul
        v-if="crumb.dropdownData && crumb.dropdownData.length && crumb.isDropDownOpen"
        class="crumbs__dropdown"
        :class="{ 'crumbs__dropdown--active': crumb.dropdownData.some(el => el.isDropDownOpen) }"
      >
        <CrumbsDropdownItem
          v-for="dropdownItem in crumb.dropdownData"
          :key="dropdownItem.key"
          :dropdownItem="dropdownItem"
          :handleCrumbsDropdownItemToggle="handleCrumbsDropdownItemToggle"
          @toggleDropDown="handleCrumbsDropdownItemToggle($event, crumb.dropdownData)"
        />
      </ul>
    </li>
  </ul>
</template>

<script>
import CrumbsDropdownItem from '@/components/CrumbsDropdownItem'

export default {
  name: 'Crumbs',
  props: ['crumbsData'],
  components: {
    CrumbsDropdownItem
  },
  methods: {
    toggleDropDown (crumb, val) {
      crumb.isDropDownOpen = typeof val === 'boolean' ? val : !crumb.isDropDownOpen
      this.handleCrumbsDropdownItemToggle(crumb, crumb.dropdownData)
    },
    handleCrumbsDropdownItemToggle (dropdownItem, dropdownData) {
      const closeDropdowns = (item) => {
        if (item.isDropDownOpen) item.isDropDownOpen = false
        if (item.dropdownData?.length) item.dropdownData.forEach(closeDropdowns)
      }
      if (dropdownData?.length) dropdownData.filter(el => el.key !== dropdownItem.key).forEach(closeDropdowns)
    }
  }
}
</script>
