import ApiBase from '@/services/z-api/v1/api-base'

class Favorites extends ApiBase {
  getFavoriteProductsCodes (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/favorites`
      )
    })
  }

  getProductsLists (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/favorites/products`
      )
    })
  }

  createProductsList ({ listName }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/v1/users/${this._getUserId(userId)}/favorites`,
        { name: listName }
      )
    })
  }

  deleteProductsList ({ listId }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/v1/users/${this._getUserId(userId)}/favorites`,
        { wishlistId: listId }
      )
    })
  }

  addProductToList ({ listId, productCode }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/v1/users/${this._getUserId(userId)}/favorites/products`,
        { wishlistId: listId, productCode }
      )
    })
  }

  updateProductInList ({ listId, productCode, quantity }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/v1/users/${this._getUserId(userId)}/favorites/products`,
        { wishlistId: listId, productCode, quantity }
      )
    })
  }

  deleteProductFromList ({ listId, productCode }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/v1/users/${this._getUserId(userId)}/favorites/products`,
        { wishlistId: listId, productCode }
      )
    })
  }

  updateProductList ({ listId, listName }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/v1/users/${this._getUserId(userId)}/favorites`,
        { wishlistId: listId, name: listName }
      )
    })
  }

  deleteAllProductsFromList ({ listId }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/v1/users/${this._getUserId(userId)}/favorites/allItems`,
        { wishlistId: listId }
      )
    })
  }
}

export default Favorites
