const _defaultCrumbsData = [
  {
    id: 'home',
    title: 'Home',
    url: '/',
    hasAction: true,
    dropdownData: null,
    isDropDownOpen: false
  }
]

const state = {
  crumbsData: _defaultCrumbsData
}

const mutations = {
  SET_CRUMBS_DATA (state, data) {
    state.crumbsData = [..._defaultCrumbsData, ...data]
  }
}

export default {
  state,
  mutations,
  namespaced: true
}
