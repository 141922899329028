import ApiBase from '@/services/z-api/v1/api-base'

class Analytics extends ApiBase {
  getEmailSubscriptions (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${userId}/analytics`)
    })
  }

  updateEmailSubscriptions (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/v1/users/${userId}/analytics`, data)
    })
  }

  sendInvitationsEmail (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/v1/users/${this._getUserId(
          userId
        )}/analytics/sendInvitationsEmail`,
        data
      )
    })
  }

  getCheckoutStatuses (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/analytics/checkoutStatuses`
      )
    })
  }

  getOrderStatuses (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(userId)}/analytics/orderStatuses`
      )
    })
  }

  sendCartStepsHubspotAnalytics (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/v1/users/${this._getUserId(userId)}/analytics/updateOrderStage`,
        data
      )
    })
  }
}

export default Analytics
