import { transformCartEntry, transformDeliveryModeEntry, transformOrderEntry } from '@/transformers'
import zApiService from '@/services/z-api'
import settings from '@/settings'

const state = {
  cartData: null,
  deliveryModesData: null,
  paymentProviderData: null,
  placedOrderData: null,
  deliveryModesCostData: null,
  isDeliveryModesCalculating: false,
  isProductsPriceRecalculationRequired: false,
  isCartDataUpdateOnPageLoadRequired: false,
  checkoutStatuses: {},
  orderStatuses: {}
}

const mutations = {
  SET_CART_DATA (state, data) {
    state.cartData = data
  },
  SET_DELIVERY_MODES_DATA (state, data) {
    state.deliveryModesData = data
  },
  SET_PAYMENT_PROVIDER_DATA (state, data) {
    state.paymentProviderData = data
  },
  SET_PLACED_ORDER_DATA (state, data) {
    state.placedOrderData = data
  },
  SET_DELIVERY_MODES_COST_DATA (state, data) {
    state.deliveryModesCostData = data
  },
  SET_IS_DELIVERY_MODES_CALCULATING (state, data) {
    state.isDeliveryModesCalculating = data
  },
  SET_IS_PRODUCTS_RECALCULATION_REQUIRED (state, data) {
    state.isProductsPriceRecalculationRequired = data
  },
  SET_IS_CART_DATA_UPDATE_ON_PAGE_LOAD_REQUIRED (state, data) {
    state.isCartDataUpdateOnPageLoadRequired = data
  },
  SET_CHECKOUT_STATUSES (state, data) {
    state.checkoutStatuses = data
  },
  SET_ORDER_STATUSES (state, data) {
    state.orderStatuses = data
  }
}

const actions = {
  async setCartDataOnBoot ({ commit, state, rootGetters }, userId) {
    let data = await zApiService.checkout.getCart(userId)

    if (!rootGetters['user/isGuest'] && state.cartData?.isGuestCart) {
      data = await zApiService.checkout.mergeCarts({ oldCartId: state.cartData.guid, toMergeCartGuid: data?.guid }, userId)
    }

    const cartData = transformCartEntry(data)

    commit('SET_CART_DATA', cartData)
    commit('SET_IS_PRODUCTS_RECALCULATION_REQUIRED', cartData.isSomeProductPriceChanged)
    return Promise.resolve()
  },
  async setCartData ({ commit, dispatch, rootGetters }, options = { showProductsPriceChange: true }) {
    const data = await zApiService.checkout.getCart(options.userId)
    const cartData = transformCartEntry(data, { showProductsPriceChange: options.showProductsPriceChange, entriesDeep: true })

    commit('SET_CART_DATA', cartData)
    commit('SET_IS_PRODUCTS_RECALCULATION_REQUIRED', cartData.isSomeProductPriceChanged)
    commit('SET_IS_CART_DATA_UPDATE_ON_PAGE_LOAD_REQUIRED', false)
    if (!rootGetters['user/isGuest']) dispatch('saveForLater/setSaveForLaterData', null, { root: true })
    return Promise.resolve()
  },
  async checkInventory ({ state }, requestData) {
    const inventoryData = await zApiService.inventory.checkInventory({ ...requestData, baseStoreId: state.cartData?.baseStoreId })
    const inventoryQty = inventoryData.total
    const hasShortage = inventoryQty < requestData.quantity

    return Promise.resolve({
      hasShortage,
      inventoryQty,
      inventoryData
    })
  },
  async addToCart ({ dispatch, state }, initialRequestData) {
    const requestParams = {
      quantity: initialRequestData.quantity,
      code: initialRequestData.code
    }

    let inventoryQty, hasShortage

    try {
      const inventoryData = await dispatch('checkInventory', requestParams)
      inventoryQty = inventoryData.inventoryQty
      hasShortage = inventoryData.hasShortage

      if (hasShortage && initialRequestData.isClearance) {
        if (inventoryQty === 0) {
          return Promise.resolve({
            hasShortage,
            inventoryQty
          })
        } else {
          requestParams.quantity = inventoryQty
        }
      }
    } catch (err) {
      console.error(err)
    }

    await zApiService.checkout.addProduct(requestParams)
    await dispatch('setCartData')

    return Promise.resolve({
      hasShortage,
      inventoryQty,
      cartItemEntryNumber: state.cartData?.entries.find(el => el.product?.sku === initialRequestData.code)?.number
    })
  },
  async updateCartEntryQty ({ dispatch }, { entryNumber, data }) {
    await zApiService.checkout.updateProductQty(entryNumber, data)
    return dispatch('setCartData')
  },
  async deleteCartEntry ({ dispatch }, { entryNumber }) {
    await zApiService.checkout.deleteProduct(entryNumber)
    return dispatch('setCartData')
  },
  async setGuestEmail ({ dispatch }, data) {
    await zApiService.checkout.setGuestEmail(data)
    return dispatch('setCartData')
  },
  async setDeliveryModes ({ commit, rootGetters }) {
    const modesBlacklist = [
      !rootGetters['user/isEmployee'] && settings.app.country === 'CA' ? 'CPU' : null
    ]
    const modes = await zApiService.checkout.getDeliveryModes()
    commit('SET_DELIVERY_MODES_DATA', modes.filter(el => !modesBlacklist.includes(el.code)).map(el => {
      const entry = transformDeliveryModeEntry(el)
      delete entry.price // Use prices from deliveryModesCostData
      return entry
    }).sort((a, b) => a.sortOrder - b.sortOrder))
    return Promise.resolve()
  },
  async setDeliveryModesCost ({ commit, dispatch, state }) {
    const prevDeliveryModeCode = state.cartData?.deliveryMode?.code
    const isPrevDeliveryModeCodeWOB = prevDeliveryModeCode === settings.groundDeliveryModeCodeByCountry[settings.app.country]
    if (!isPrevDeliveryModeCodeWOB) await dispatch('setCartDeliveryMode', { code: settings.groundDeliveryModeCodeByCountry[settings.app.country] })
    const WOBModeEntry = { ...state.cartData?.deliveryMode }
    const anotherModesEntries = await zApiService.checkout.getDeliveryModesCost()
    const allModesEntries = [WOBModeEntry, ...anotherModesEntries.map(transformDeliveryModeEntry)]
    const allModesCost = {}
    allModesEntries.forEach(el => {
      allModesCost[el.code] = el.price
    })
    commit('SET_DELIVERY_MODES_COST_DATA', allModesCost)
    if (!isPrevDeliveryModeCodeWOB &&
      prevDeliveryModeCode &&
      (allModesCost[prevDeliveryModeCode] || prevDeliveryModeCode === settings.willCallDeliveryModeCodeByCountry[settings.app.country])
    ) {
      await dispatch('setCartDeliveryMode', { code: prevDeliveryModeCode })
    }
    await dispatch('setDeliveryModes')
    return Promise.resolve()
  },
  async setCartDeliveryMode ({ dispatch }, { code }) {
    // tax is calculated on deliverymode set
    // with faulty action cart won't have delivery mode, hence request it anyway to get respective empty field
    return zApiService.checkout.setDeliveryMode({ deliveryModeId: code }).finally(() => {
      return dispatch('setCartData')
    })
  },
  async setPaymentProviderData ({ commit }, data) {
    const params = await zApiService.checkout.getPaymentParams(data)
    commit('SET_PAYMENT_PROVIDER_DATA', params)
    return Promise.resolve()
  },

  async setCartShippingAddressId ({ dispatch }, { addressId }) {
    await zApiService.checkout.setCartShippingAddressId({ addressId: addressId })
    return dispatch('setCartData')
  },

  async setCartShippingAddressData ({ dispatch, rootGetters }, data) {
    await zApiService.checkout.setCartShippingAddressData(data)
    if (!rootGetters['user/isGuest']) dispatch('user/setShippingAddressData', null, { root: true })
    return dispatch('setCartData')
  },

  async deleteShippingAddressFromCart ({ dispatch }) {
    await zApiService.checkout.deleteShippingAddressFromCart()
    return dispatch('setCartData')
  },

  async setVoucher ({ dispatch }, voucher) {
    return zApiService.checkout.setVoucher(voucher)
      .then(() => dispatch('setCartData', { showProductsPriceChange: false }))
  },

  async deleteVoucher ({ dispatch }, voucher) {
    return zApiService.checkout.deleteVoucher(voucher)
      .then(() => dispatch('setCartData', { showProductsPriceChange: false }))
  },

  async placeOrder ({ commit }) {
    const placedOrder = await zApiService.checkout.placeOrder()
    commit('SET_PLACED_ORDER_DATA', transformOrderEntry(placedOrder))
    return Promise.resolve()
  },

  createNewCart () {
    return zApiService.checkout.createNewCart()
  },

  async deleteCart ({ dispatch }) {
    await zApiService.checkout.deleteCart()
    await dispatch('createNewCart')
    await dispatch('resetCart')
    return Promise.resolve()
  },

  resetCart ({ commit, dispatch }) {
    commit('SET_DELIVERY_MODES_DATA', null)
    commit('SET_PAYMENT_PROVIDER_DATA', null)
    commit('SET_PLACED_ORDER_DATA', null)
    commit('SET_DELIVERY_MODES_COST_DATA', null)
    return dispatch('setCartData')
  },

  async saveCartItemEntryAdditionalData ({ commit }, {
    entryNumber,
    list,
    position,
    indexName,
    queryID,
    catalogOriginalTitle,
    brand,
    category
  }) {
    await zApiService.checkout.saveCartItemEntryAdditionalData({
      entryNumber,
      data: Object.entries({
        list,
        position: position.toString(),
        indexName,
        queryID,
        catalogOriginalTitle,
        brand,
        category
      }).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')
    })
    commit('SET_IS_CART_DATA_UPDATE_ON_PAGE_LOAD_REQUIRED', true)
  },

  async recalculatePoductsPrice ({ commit }) {
    await zApiService.checkout.recalculatePoductsPrice()
    commit('SET_IS_PRODUCTS_RECALCULATION_REQUIRED', false)
    return Promise.resolve()
  },

  async getCheckoutAndOrderStatuses ({ commit }) {
    return Promise.all([
      zApiService.analytics.getCheckoutStatuses(),
      zApiService.analytics.getOrderStatuses()
    ]).then(([checkoutStatuses, orderStatuses]) => {
      commit('SET_CHECKOUT_STATUSES', {
        billing: checkoutStatuses.BILLING,
        orderConfirmation: checkoutStatuses.ORDER_CONFIRMATION,
        reviewCart: checkoutStatuses.REVIEW_CART,
        reviewOrder: checkoutStatuses.REVIEW_ORDER,
        shipping: checkoutStatuses.SHIPPING
      })
      commit('SET_ORDER_STATUSES', {
        checkoutCompleted: orderStatuses.CHECKOUT_COMPLETED,
        checkoutPending: orderStatuses.CHECKOUT_PENDING
      })
    })
  },

  sendCartStepsHubspotAnalytics ({ state }, { orderStage, checkoutStage }) {
    if (!orderStage || !checkoutStage) return
    return zApiService.analytics.sendCartStepsHubspotAnalytics({
      guid: state.cartData.guid,
      cartId: state.cartData.id,
      orderStage,
      checkoutStage
    })
  }
}

const getters = {
  cartSize: state => state.cartData?.totalItems || null,
  isCartContainsUnavailableProduct: state => state.cartData?.entries.some(entry => !entry.product.isPurchasable)
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
