import ApiBase from '@/services/z-api/v1/api-base'

class Sites extends ApiBase {
  checkUrlRedirect (data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/v1/urlRedirect', data)
    })
  }

  getUrlRedirectsList () {
    return this._withDecoratedPromise(() => {
      return this.transport.get('/rest/v1/urlRedirect')
    })
  }
}

export default Sites
