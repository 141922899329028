// @TODO move to the view component, as we use url as a state tool
import router from '@/router'

const state = {
}

const mutations = {
}

const actions = {
  setStep ({ commit, getters, state, dispatch }, stepValue) {
    const step = getters.steps.find(el => el.value === stepValue)
    step
      ? router.push('/cart/' + getters.currentStep.next.step.value)
      : dispatch('resetCartFlow')
  },
  incrementStep ({ commit, state, getters }) {
    router.push('/cart/' + getters.currentStep.next.step.value)
  },
  decrementStep ({ commit, state, getters }) {
    router.push('/cart/' + getters.currentStep.previous.step.value)
  },
  resetCartFlow ({ commit, state, getters }) {
    const step = getters.steps[0]
    router.replace('/cart/' + step.value)
  }
}

const getters = {
  steps: (state, getters, rootState, rootGetters) => {
    const _steps = [
      {
        value: 'review-cart',
        navigationTitle: 'Review Cart',
        stepTitle: 'Shopping Cart',
        pageTitle: 'Cart',
        next: {
          get step () {
            return rootGetters['user/isGuest']
              ? _steps.find(el => el.value === 'guest-login')
              : _steps.find(el => el.value === 'shipping')
          },
          actionText: 'Continue to Shipping'
        },
        previous: {
          get step () {
            return null
          },
          actionText: ''
        },
        hasBreadcrumb: true,
        get crumbData () {
          return { title: 'Items for Purchase: ' + rootGetters['cart/cartSize'] }
        },
        get isAccessible () {
          return true
        }
      },
      {
        value: 'guest-login',
        stepTitle: 'Shopping Cart',
        pageTitle: 'Cart',
        next: {
          get step () {
            return _steps.find(el => el.value === 'shipping')
          },
          actionText: 'Continue to Shipping'
        },
        previous: {
          get step () {
            return _steps.find(el => el.value === 'review-cart')
          },
          actionText: 'Back'
        },
        hasBreadcrumb: false,
        belongsToCrumbStep: 'review-cart',
        get crumbData () {
          return null
        },
        get isAccessible () {
          return rootGetters['user/isGuest'] &&
          !rootGetters['cart/isCartContainsUnavailableProduct'] &&
          !rootState.cart.cartData?.isInvalidPromotionsApplied
        }
      },
      {
        value: 'shipping',
        navigationTitle: 'Shipping',
        stepTitle: 'Shipping',
        pageTitle: 'Checkout',
        next: {
          get step () {
            const stepId = rootState.cart.cartData?.paymentDetails ? 'review-order' : 'billing'
            return _steps.find(el => el.value === stepId)
          },
          actionText: rootState.cart.cartData?.paymentDetails ? 'Review Order' : 'Continue to Billing'
        },
        previous: {
          get step () {
            return _steps.find(el => el.value === 'review-cart')
          },
          actionText: 'Back'
        },
        hasBreadcrumb: true,
        get crumbData () {
          return rootState.cart.cartData?.shippingAddress
        },
        get isAccessible () {
          return !!rootState.cart.cartData?.customerUid &&
          !rootGetters['cart/isCartContainsUnavailableProduct'] &&
          !rootState.cart.cartData?.isInvalidPromotionsApplied
        }
      },
      {
        value: 'billing',
        navigationTitle: 'Billing',
        stepTitle: 'Billing',
        pageTitle: 'Checkout',
        next: {
          get step () {
            return _steps.find(el => el.value === 'review-order')
          },
          actionText: 'Review Order'
        },
        previous: {
          get step () {
            return _steps.find(el => el.value === 'shipping')
          },
          actionText: 'Back'
        },
        hasBreadcrumb: true,
        get crumbData () {
          return rootState.cart.cartData?.paymentDetails
        },
        get isAccessible () {
          return !!rootState.cart.cartData?.customerUid &&
            !!rootState.cart.cartData?.deliveryMode &&
            !!rootState.cart.cartData?.shippingAddress
        }
      },
      {
        value: 'review-order',
        navigationTitle: 'Review Order',
        stepTitle: 'Review Order',
        pageTitle: 'Checkout',
        next: {
          get step () {
            return null
          },
          actionText: 'Place Order'
        },
        previous: {
          get step () {
            const stepId = rootState.cart.cartData?.paymentDetails ? 'shipping' : 'billing'
            return _steps.find(el => el.value === stepId)
          },
          actionText: 'Back'
        },
        hasBreadcrumb: true,
        get crumbData () {
          return null
        },
        get isAccessible () {
          return !!rootState.cart.cartData?.customerUid &&
            !!rootState.cart.cartData?.shippingAddress &&
            !!rootState.cart.cartData?.deliveryMode &&
            !!rootState.cart.cartData?.paymentDetails
        }
      }
    ]
    return _steps
  },
  currentStepIndex: (state, getters, rootState) => {
    const stepValue = rootState.route.params.step
    return getters.steps.map(el => el.value).indexOf(stepValue)
  },
  currentStep: (state, getters, rootState) => {
    const stepValue = rootState.route.params.step
    return getters.steps.find(el => el.value === stepValue)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
