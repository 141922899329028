import ApiBase from '@/services/z-api/v1/api-base'

class User extends ApiBase {
  /**
   * Get User account info.
   * @param email
   * @return {Promise<any>}
   */
  accountDetails (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/users/${this._getUserId(userId)}`)
    })
  }

  /** Unused */
  updateUserInfo (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/v1/users/${this._getUserId(userId)}`,
        data
      )
    })
  }

  updateSomeUserInfoFields (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/v1/users/${this._getUserId(userId)}`,
        data
      )
    })
  }

  applyInvitationsCode (code, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/v1/users/${this._getUserId(
          userId
        )}/validateCode?individualCode=${code}`
      )
    })
  }
}

export default User
