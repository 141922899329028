import router from '@/router'
import { transformGenericContentPageEntry, transformEntryByType } from '@/transformers'
import ContentfulService from '@/services/contentful'
const GenericContent = () => import('@/views/GenericContent.vue')

const state = {
  genericContentData: null,
  genericContentPageData: null
}

const mutations = {
  SET_GENERIC_CONTENT_DATA (state, data) {
    state.genericContentData = data
  },
  SET_GENERIC_CONTENT_PAGE_DATA (state, data) {
    state.genericContentPageData = data
  },
  UNSET_GENERIC_CONTENT_PAGE_DATA (state) {
    state.genericContentPageData = null
  }
}

const actions = {
  async setGenericContentData ({ commit }) {
    try {
      const genericContent = await ContentfulService.getEntriesByQuery({
        skip: 0,
        content_type: 'pageContent',
        include: 5,
        limit: 1000
      })

      // preview content can be undefined so must be removed
      const genericContentCleaned = genericContent.filter(element => {
        return typeof (element.fields.key) !== 'undefined'
      })

      const transformed = genericContentCleaned.map(transformGenericContentPageEntry) || []
      const genericRoutes = transformed.map(page => {
        return {
          path: page.pageUrl,
          meta: { name: 'GenericContent' },
          component: GenericContent
        }
      })
      router.addRoutes(genericRoutes)
      commit('SET_GENERIC_CONTENT_DATA', transformed)
    } catch {
      commit('SET_GENERIC_CONTENT_DATA', [])
    }
  },
  async setGenericContentPageData ({ commit, state, dispatch }, id) {
    // Preventing state.genericContentData mutation
    const pageData = JSON.parse(JSON.stringify(state.genericContentData.find(page => id === page.pageUrl)))
    const heroBannerContentType = ContentfulService.extractContentType(pageData.heroBanner)
    pageData.heroBanner = heroBannerContentType === 'blockCarousel'
      ? await dispatch('content/decorateBannerCarousel', pageData.heroBanner, { root: true })
      : transformEntryByType(pageData.heroBanner)

    const isPageContainsCarouselSlot = pageData?.slots?.some(el => el?.slot?.type === 'blockCarousel')
    if (isPageContainsCarouselSlot) {
      pageData.slots = await Promise.all(pageData.slots.map(async el => {
        if (el.slot?.type === 'blockCarousel') {
          const decoratedCarouselEntry = await dispatch('content/decorateCarouselEntryWithItems', el.slot, { root: true })
          el.slot = decoratedCarouselEntry
        }
        return el
      }))
      pageData.slots = pageData.slots.filter(el => el.slot)
    }
    commit('SET_GENERIC_CONTENT_PAGE_DATA', pageData)
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
