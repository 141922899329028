<template>
  <div
    @click="handleFavoriteClick"
    class="favorites-icon"
    :class="{
      'favorites-icon--active': isProductInFavorites,
      'favorites-icon--disabled': isGuest
    }"
  >
    <i
      v-if="isFavoritesActionInProgress"
      key="spinner"
      class="icon-spinner favorites-icon__spiner"
    ></i>
    <i
      v-else
      v-tooltip="!disabled ? {
        content:  tooltipText,
        trigger: 'hover',
        autoHide: false
      } : {}"
      key="heart"
      class="favorites-icon__heart"
      :class="[isProductInFavorites ? 'icon-heart-filled' : 'icon-heart']"
    ></i>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'FavoritesIcon',
  props: {
    productCode: {
      type: String,
      requires: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('user', ['isGuest']),
    ...mapState('user', ['favoriteProductsCodes']),
    isProductInFavorites () {
      return this.favoriteProductsCodes
        ? this.favoriteProductsCodes.includes(this.productCode)
        : false
    },
    favoritesAction () {
      return this.isProductInFavorites
        ? this.deleteProductFromFavorites
        : this.addProductToFavorites
    },
    tooltipText () {
      return this.isGuest
        ? 'Create account or login to save as favorite'
        : this.isProductInFavorites
          ? 'Remove From Favorites'
          : 'Add To Favorites'
    }
  },
  methods: {
    ...mapActions('user', ['addProductToFavorites', 'deleteProductFromFavorites']),
    handleFavoriteClick () {
      const isClickable = !this.disabled || (this.disabled && this.isProductInFavorites)
      if (isClickable && !this.isGuest && !this.isFavoritesActionInProgress) {
        this.isFavoritesActionInProgress = true
        this.favoritesAction({ productCode: this.productCode })
          .finally(() => {
            this.isFavoritesActionInProgress = false
          })
      }
    }
  },
  data () {
    return {
      isFavoritesActionInProgress: false
    }
  }
}
</script>
