const state = {
  catalogData: null
}

const mutations = {
  SET_CATALOG_DATA (state, data) {
    state.catalogData = data
  },
  UNSET_CATALOG_DATA (state) {
    state.catalogData = null
  }
}

const actions = {
  async setCatalogData ({ commit, rootState }, id) {
    const catalog = rootState.catalogs.catalogsData.allCatalogs.find(catalog => id === catalog.key)

    commit('SET_CATALOG_DATA', catalog)
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
