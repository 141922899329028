<template>
  <div
    v-if="closable"
    class="hint-bar hint-bar--closable"
    :class="{ 'hint-bar--text-color-force': isDecorated }"
    :style="isDecorated ? {background: backgroundColor, color: textColor} : null"
  >
    <div class="hint-bar__inner-container container">
      <i
        v-if="showIcon"
        class="hint-bar__icon"
        :class="[
          hintData && hintData.icon ? hintData.icon : iconVariant,
          hintData && hintData.iconColor ? `hint-bar__icon--${hintData.iconColor}` : ''
        ]"
      ></i>
      <div
        v-if="hintData && hintData.message"
        v-html="hintData.message"
        class="hint-bar__message"
      ></div>
      <div
        v-else
        class="hint-bar__message"
      >
        <slot></slot>
      </div>
      <i
        @click="$emit('close')"
        class="hint-bar__close-icon icon-times"
      ></i>
    </div>
  </div>
  <div
    v-else
    class="hint-bar"
    :class="{ 'hint-bar--text-color-force': isDecorated }"
    :style="isDecorated ? {background: backgroundColor, color: textColor} : null"
  >
    <i
      v-if="showIcon"
      class="hint-bar__icon"
      :class="[
        hintData && hintData.icon ? hintData.icon : iconVariant,
        hintData && hintData.iconColor ? `hint-bar__icon--${hintData.iconColor}` : ''
      ]"
    ></i>
    <div
      v-if="hintData && hintData.message"
      v-html="hintData.message"
      class="hint-bar__message"
    ></div>
    <div
      v-else
      class="hint-bar__message"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HintBar',
  props: {
    hintData: {
      type: Object,
      required: false
    },
    closable: {
      type: Boolean,
      required: false,
      default: false
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    isDecorated () {
      return !!this.hintData.type
    },
    iconVariant () {
      switch (this.hintData?.variant) {
        case 'success':
          return 'icon-check-circle'
        case 'warning':
          return 'icon-exclamation-triangle-light'
        default:
          return 'icon-exclamation-circle'
      }
    },
    backgroundColor () {
      const colors = {
        Yellow: '#FDDB32',
        Black: '#000000',
        Blue: '#2D8C9E',
        Green: '#09B26D',
        Orange: '#F1C400',
        Red: '#E21F2D'
      }
      return colors[this.hintData.backgroundColor]
    },
    textColor () {
      const colors = {
        Black: '#000000',
        White: '#FFFFFF'
      }
      return colors[this.hintData.textColor]
    }
  }
}
</script>
