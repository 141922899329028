<template>
  <Modal
    :showModal="isModalVisible"
    @close="toggleNewsletterModal(false)"
    class="modal-newsletter"
    animation="slide-up"
  >
    <template v-slot:header v-if="isModalVisible">
      <div
        class="modal-newsletter__header"
        :class="{ 'modal-newsletter__header--image': !!newsletterModalData.image }"
        :style="`background-image: url('${newsletterModalData.image}')`"
      >
        <h2
          class="text-h2 modal-newsletter__title"
          v-html="newsletterModalData.title"
          :style="{ color: colorsMap[newsletterModalData.titleColor] || colorsMap.Yellow }"
        ></h2>
        <p
          class="modal-newsletter__description"
          v-if="newsletterModalData.description"
          v-html="newsletterModalData.description"
          :style="{ color: colorsMap[newsletterModalData.descriptionColor] || colorsMap.While }"
        ></p>
      </div>
    </template>
    <template v-slot:footer v-if="isModalVisible">
      <div class="modal-newsletter__content">
        <div v-if="!isFormSubmited">
          <p
            v-if="newsletterModalData.additionalParagraph"
            v-html="newsletterModalData.additionalParagraph"
            class="modal-newsletter__paragraph"
          ></p>
          <form class="modal-newsletter__form" @submit.prevent="submitForm">
            <div class="modal-newsletter__form-fields">
              <FormGroup
                v-model="$v.form.email.$model.value"
                :field="$v.form.email"
                :isValidationRun="isValidationRun"
              />
              <FormCheckbox
                v-model="$v.form.termsAndConditions.$model.value"
                :field="$v.form.termsAndConditions"
                :isValidationRun="isValidationRun"
              />
            </div>
            <button
              type="submit"
              class="button button--primary"
              :disabled="!form.email.value || !form.termsAndConditions.value"
            >
              Sign me up
            </button>
          </form>
        </div>
        <div class="modal-newsletter__success-block" v-else>
          <h3 class="text-h4" v-html="newsletterModalData.successMessage"></h3>
          <a
            @click.prevent="continueShopping"
            href="/"
            class="button button--primary"
          >
            Continue shopping
          </a>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { required, email, sameAs } from 'vuelidate/lib/validators'

import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import Modal from '@/components/modal/Modal'
import FormCheckbox from '@/components/FormCheckbox'
import FormGroup from '@/components/FormGroup'

export default {
  name: 'ModalNewsletter',
  components: {
    Modal,
    FormCheckbox,
    FormGroup
  },
  props: {
    showModal: Boolean
  },
  mixins: [FormValidationMixin, LoaderMixin],
  watch: {
    async showModal (val) {
      if (!this.isInitialized) {
        if (!this.newsletterModalData) await this.setNewsletterModalData()
        this.isInitialized = true
      }
      if (!val) this.resetModal()
    }
  },
  computed: {
    ...mapState('content', ['newsletterModalData']),
    isModalVisible () {
      return !!(this.isInitialized && this.showModal && this.newsletterModalData)
    }
  },
  methods: {
    ...mapActions('content', ['setNewsletterModalData']),
    ...mapMutations('content', { toggleNewsletterModal: 'SET_SHOW_NEWSLETTER_MODAL' }),
    ...mapActions('user', ['updateEmailSubscriptionsData']),
    submitForm () {
      if (this.form.email.value && this.form.termsAndConditions.value) {
        this.withLoader(() => {
          return this.sendForm(this.updateEmailSubscriptionsData, {
            email: this.form.email.value,
            pageName: window.document.title,
            pageUri: window.location.href,
            salesAndDiscounts: true,
            newProductAnnouncements: true,
            unsubscribe: false
          }).then(() => {
            this.isFormSubmited = true
          }).catch(() => {
            this.isValidationRun = true
          })
        })
      }
    },
    continueShopping () {
      this.$router.push('/')
      this.toggleNewsletterModal(false)
    },
    resetModal () {
      this.isValidationRun = false
      this.isFormSubmited = false
      this.form.email.value = ''
      this.form.termsAndConditions.value = false
    }
  },
  validations () {
    return {
      form: {
        email: {
          value: {
            required,
            email,
            server: this.server
          }
        },
        termsAndConditions: {
          value: {
            sameAs: sameAs(() => true)
          }
        }
      }
    }
  },
  data () {
    return {
      isInitialized: false,
      isValidationRun: false,
      isFormSubmited: false,
      form: {
        email: {
          placeholder: 'Email',
          value: '',
          defaultValue: '',
          title: 'email',
          type: 'email',
          disabled: false,
          variant: 'shadow',
          maxLength: '255',
          errors: {
            required: 'Please enter a Email Address',
            email: 'Please enter a valid Email Address'
          }
        },
        termsAndConditions: {
          title: 'termsAndConditions',
          type: 'checkbox',
          label: 'I agree to the terms of Hubbell\'s <a class="link" href="https://hubbell.com/hubbell/en/privacy-policy" target="_blank">privacy policy</a> and for my personal data to be processed to be contacted by Hubbell.',
          variant: 'shadow',
          value: false,
          errors: {
            sameAs: 'This field is required'
          }
        }
      },
      colorsMap: {
        Yellow: '#FDDB32',
        Black: '#000000',
        White: '#FFFFFF',
        Blue: '#2D8C9E',
        Green: '#09B26D',
        Orange: '#F1C400',
        Red: '#E21F2D'
      }
    }
  }
}
</script>
