import Transport from '@/services/z-api/transport'
import Auth from '@/services/z-api/v1/auth'
import Address from '@/services/z-api/v1/address'
import Checkout from '@/services/z-api/v1/checkout'
import Favorites from '@/services/z-api/v1/favorites'
import Analytics from '@/services/z-api/v1/analytics'
import Products from '@/services/z-api/v1/products'
import Sites from '@/services/z-api/v1/sites'
import Inventory from '@/services/z-api/v1/inventory'
import Orders from '@/services/z-api/v1/orders'
import User from '@/services/z-api/v1/user'

class Api {
  /**
   * Initialize a new instance of the API class.
   * @param settings
   * @return {*}
   */
  constructor (settings = null) {
    if (Api.instance) {
      return Api.instance
    }
    Api.instance = this
    this.initApiModules(settings)
  }

  /**
   * Initialize API modules.
   * @param settings
   */
  initApiModules (settings) {
    this.auth = new Auth(new Transport(`${settings.services.api.baseUrl}/security-api-proxy`), settings.services.api.apiKey)
    this.address = new Address(new Transport(`${settings.services.api.baseUrl}/addressbook-api-proxy`))
    this.checkout = new Checkout(new Transport(`${settings.services.api.baseUrl}/checkout-api-proxy`))
    this.favorites = new Favorites(new Transport(`${settings.services.api.baseUrl}/favorites-api-proxy`))
    this.analytics = new Analytics(new Transport(`${settings.services.api.baseUrl}/hubspot-integration-api-proxy`))
    this.products = new Products(new Transport(`${settings.services.api.baseUrl}/products-api-proxy`))
    this.sites = new Sites(new Transport(`${settings.services.api.baseUrl}/sites-api-proxy`))
    this.inventory = new Inventory(new Transport(`${settings.services.api.baseUrl}/inventory-api-proxy`))
    this.orders = new Orders(new Transport(`${settings.services.api.baseUrl}/orders-api-proxy`))
    this.user = new User(new Transport(`${settings.services.api.baseUrl}/users-api-proxy`))
  }
}

export default Api
