<template>
  <header
    id="header"
    class="header"
    :class="{
      'header--sticky': isHeaderSticky,
      'header--sticky-extended': isHeaderSticky && (isEmployee || isAffiliate)
    }"
  >
    <div class="container">
      <ul
        class="header-top"
        :class="{ 'header-top--hidden': isHeaderSticky }"
      >
        <li
          class="header-top__item"
          v-if="userData && profileCompletionPercentage !== 100"
        >
          <router-link to="/account/profile" class="header-top__link">{{
            `Complete your profile (${profileCompletionPercentage}% complete)`
          }}</router-link>
        </li>
        <li v-if="!isMobile" class="header-top__item">
          <button
            @click="$router.push(trackOrderLink)"
            class="header-top__link"
            >Track Order
          </button>
      </li>
        <li v-if="!isMobile" class="header-top__item">
          <router-link
            to="/customer-support"
            title="Help"
            class="header-top__link"
            >Help</router-link
          >
        </li>
        <li v-if="!isMobile" class="header-top__item">
          <CountrySwitcher />
        </li>
      </ul>
      <div class="header-container">
        <Logo
          :class="{ hidden: isMobile && isHeaderSticky }"
          :link="headerData && headerData.logo ? headerData.logo.link : null"
          :src="headerData && headerData.logo ?  headerData.logo.imageUrl : null"
          :title="headerData && headerData.logo ? headerData.logo.imageDescription || headerData.logo.title : null"
          :alt="headerData && headerData.logo ?  headerData.logo.imageAlt || headerData.logo.title : null"
        />
        <div class="header__menu" v-click-outside="closeMobileMenuItem">
          <div
            @v-click-outside="activeMegaMenuId = null"
            class="header__nav header__shortcut-item"
            :class="{
              'is-active': activeMobItem == 'navigation'
            }"
          >
            <button
              v-if="isMobile"
              @click="toggleHandler('activeMobItem', 'navigation')"
              type="button"
              class="header__shortcut-link header__shortcut--mob-menu icon-bars"
            ></button>
            <ul
              v-if="headerData && headerData.headerLinks"
              @mouseenter="closeDropdown"
              @mouseleave="hideMegamenu"
              class="header__nav-list"
            >
              <li
                v-for="(headerItem, idx) in headerData.headerLinks"
                :key="headerItem.title"
                @[navEvent].stop="
                  toggleHandler('activeMegaMenuId', headerItem.title + idx)
                "
                class="header__nav-item"
                :class="{
                  'is-active': activeMegaMenuId == headerItem.title + idx
                }"
              >
                <div class="header__nav-item-inner">
                  <a
                    v-if="headerItem.link"
                    @click.stop.prevent="
                      onMenuItemClick(headerItem.link, headerItem)
                    "
                    v-html="headerItem.title"
                    :href="headerItem.link"
                    :title="headerItem.title"
                    class="header__nav-link header__nav-link--clickable"
                  ></a>
                  <span
                    v-else
                    v-html="headerItem.title"
                    :title="headerItem.title"
                    class="header__nav-link"
                  ></span>
                  <i
                    v-if="isMobile && headerItem.megaMenu"
                    class="header__nav-icon icon-down-arrow"
                  ></i>
                </div>
                <div
                  v-if="headerItem.megaMenu"
                  class="header__nav-megamenu header__megamenu"
                  :class="{
                    'is-open': activeMegaMenuId == headerItem.title + idx
                  }"
                >
                  <ul
                    class="header__megamenu-list"
                    :class="{container: !headerItem.containsCarousel}"
                  >
                    <li
                      v-for="menuItem in headerItem.megaMenu"
                      :key="menuItem.indexTitle"
                      class="header__megamenu-item"
                    >
                      <a
                        @click.stop.prevent="
                          onMenuItemClick(menuItem.url, headerItem)
                        "
                        v-html="menuItem.title"
                        :title="menuItem.title"
                        :href="menuItem.url"
                        class="header__megamenu-title"
                      ></a>
                      <ul
                        v-if="menuItem.subValues"
                        class="header__megamenu-links"
                      >
                        <li
                          v-for="(item, idx) in menuItem.subValues"
                          :key="item.indexTitle"
                        >
                          <template v-if="item.subValues">
                            <div
                              class="header__megamenu-nested-title"
                              :class="{
                                'is-active':
                                  activeNestedMenuId ==
                                  menuItem.title + item.title + idx
                              }"
                            >
                              <a
                                @click.stop.prevent="
                                  onMenuItemClick(item.url, headerItem)
                                "
                                v-html="item.title"
                                :href="item.url"
                                :title="item.title"
                                class="header__megamenu-nested-link"
                              ></a>
                              <button
                                @click.stop="
                                  toggleHandler(
                                    'activeNestedMenuId',
                                    menuItem.title + item.title + idx
                                  )
                                "
                                type="button"
                                class="header__megamenu-nested-button button"
                              >
                                <i
                                  :class="
                                    !isMobile
                                      ? 'icon-caret-down'
                                      : 'icon-down-arrow'
                                  "
                                ></i>
                              </button>
                            </div>
                            <ul
                              class="header__megamenu-nested-links"
                              :class="{
                                'is-active':
                                  activeNestedMenuId ==
                                  menuItem.title + item.title + idx
                              }"
                            >
                              <li
                                v-for="nestedItem in item.subValues"
                                :key="nestedItem.title"
                              >
                                <a
                                  @click.stop.prevent="
                                    onMenuItemClick(nestedItem.url, headerItem)
                                  "
                                  v-html="nestedItem.title"
                                  :href="nestedItem.url"
                                  :title="nestedItem.title"
                                  class="header__megamenu-link"
                                ></a>
                              </li>
                            </ul>
                          </template>
                          <a
                            v-else
                            @click.stop.prevent="
                              onMenuItemClick(item.url, headerItem)
                            "
                            v-html="item.title"
                            :href="item.url"
                            :title="item.title"
                            class="header__megamenu-link"
                          ></a>
                        </li>
                      </ul>
                      <div
                        v-else-if="showProductCarousel
                          && !isTablet
                          && !menuItem.subValues
                          && menuItem.showMegamenuCarousel
                          && menuItem.productsCarouselList
                        "
                        class="header__megamenu-carousel"
                      >
                        <ProductCarousel
                          @productClicked="onMenuItemClick(null, headerItem)"
                          :productsList="menuItem.productsCarouselList"
                          :itemsPerPage="[[0, 1]]"
                          :isPriceVisible="true"
                          :loop="true"
                          :autoplay="true"
                          :autoplayTimeout="3000"
                          :analyticsData="{referencePage: 'Header Megamenu Carousel'}"
                          carouselClass="carousel-arrow-navigation carousel-arrow-navigation--small carousel-arrow-navigation--no-right-padding"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li v-if="isMobile" class="header__nav-item" @click="closeMobileMenuItem">
                <button
                  @click="$router.push(trackOrderLink)"
                  class="header__nav-link header__nav-link--clickable"
                  >Track Order
                </button>
              </li>
              <li v-if="isMobile" class="header__nav-item">
                <a
                  @click.stop.prevent="onMenuItemClick('/customer-support')"
                  class="header__nav-link header__nav-link--clickable"
                  >Help
                </a>
              </li>
              <li v-if="isMobile" class="header__nav-item">
                <CountrySwitcher class="header__nav-link" />
              </li>
            </ul>
          </div>
          <div
            v-if="isSearchInputVisible"
            class="header__search header__shortcut-item"
            :class="{
              'is-active': activeMobItem == 'search'
            }"
          >
            <button
              v-if="isMobile"
              @click="toggleHandler('activeMobItem', 'search')"
              type="button"
              class="header__shortcut-link header__shortcut--search icon-search"
            ></button>
            <div class="header__search-inner" v-click-outside="onQueryInputBlur">
              <form
                v-on:submit.prevent="onSearchSubmit"
                class="header__search-form"
              >
                <input
                  :value="searchQuery"
                  @input="handleQueryInput"
                  @focus="onQueryInputFocus"
                  type="search"
                  placeholder="What are you looking for?"
                  class="input"
                />
                <button type="submit" class="header__search-btn icon-search"></button>
              </form>
              <div class="header__suggestions-wrap" v-if="showSuggestions">
                <SearchSuggestions
                  @suggestionSelected="onSuggestionSelected"
                  class="search__suggestions"
                />
              </div>
            </div>
          </div>
          <div class="header__cart header__shortcut-item" @click="closeMobileMenuItem">
            <router-link
              to="/cart/review-cart"
              class="header__shortcut-link header__shortcut-link--icon header__shortcut-link--cart icon-shopping-cart badge-wrapper"
              v-tooltip="'View Cart'"
            >
              <span v-show="miniCartQty" class="badge badge--primary">{{
                miniCartQty
              }}</span>
            </router-link>
          </div>
          <div
            v-click-outside="closeDropdown"
            v-tooltip="{
              content:  isGuest ? 'Registered User and Employee Login' : null,
              trigger: 'hover',
              autoHide: false
            }"
            class="header__dropdown header__shortcut-item"
            :class="{
              'is-active': activeMobItem == 'account'
            }"
          >
            <div class="header__dropdown-btn">
              <a
                v-if="!userData"
                @click.prevent="onLoginClick()"
                href="/login"
                class="header__shortcut-link header__shortcut-link--icon icon-user"
              ></a>
              <div
                v-else
                @click="toggleDropDown('account')"
                class="header__shortcut-link"
              >
                <i class="icon-user-solid header__shortcut-link header__shortcut-link--yellow-border"></i>
                <span v-if="!isMobile" class="header__shortcut--user"
                  >{{ userData.firstName }} {{ userData.lastName }}</span
                >
                <i v-if="!isMobile" class="icon-down-arrow"></i>
              </div>
            </div>
            <span v-if="isEmployee || isAffiliate" class="header__dropdown-label">
              {{ isAffiliate ? 'Affiliate' : 'Employee' }}
            </span>
            <ul v-if="isDropDownOpen" class="header__dropdown-list">
              <template v-for="(item, idx) in dropDownMenu">
                <li
                  v-if="isMobile && item.isVisibleMobile"
                  :key="item.title + idx"
                  class="header__dropdown-item"
                  :class="{
                    'is-active': activeAccountDropdownItem == item.title + idx
                  }"
                >
                  <a
                    v-if="item.link"
                    @click.stop.prevent="onMenuItemClick(item.link)"
                    :href="item.link"
                    class="header__dropdown-link"
                    >
                      <span>
                        <i v-if="item.icon" class="header__dropdown-icon" :class="item.icon"></i>
                        {{ item.title }}
                      </span>
                  </a>
                  <div v-if="item.nestedLinks">
                    <span
                      @click.stop="
                        toggleHandler(
                          'activeAccountDropdownItem',
                          item.title + idx
                        )
                      "
                      class="header__dropdown-link"
                      >{{ item.title }} <i class="header__dropdown-icon fal icon-down-arrow"></i
                    ></span>
                    <ul class="header__dropdown-nested">
                      <!-- eslint-disable -->
                      <li
                        v-for="(nestedItem, idx) in item.nestedLinks"
                        :key="nestedItem.title + idx"
                        v-if="!(nestedItem.link === '/account/preferences' && !isUSSite)"
                      >
                      <!-- eslint-enable -->
                        <a
                          @click.stop.prevent="onMenuItemClick(nestedItem.link)"
                          :href="nestedItem.link"
                          class="header__dropdown-nested-link"
                          v-html="nestedItem.title"
                          ></a
                        >
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  v-else-if="!isMobile && item.isVisibleDesktop"
                  :key="item.title + idx"
                  class="header__dropdown-item"
                >
                  <a
                    v-if="item.link"
                    @click.stop.prevent="onMenuItemClick(item.link)"
                    :href="item.link"
                    class="header__dropdown-link"
                  >
                    <i v-if="item.icon" class="header__dropdown-icon" :class="item.icon"></i>
                    {{ item.title }}
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ModalTrackOrder
      v-if="showTrackOrder"
      @loginClicked="onLoginClick('/account/order-history')"
      @close="showTrackOrder = false"
      :showModal="showTrackOrder"
    />
    <ModalNewsletter :showModal="showNewsletterModal" />
  </header>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'

import settings from '@/settings'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import Logo from '@/components/Logo'
import ModalTrackOrder from '@/components/modal/ModalTrackOrder'
import ModalNewsletter from '@/components/modal/ModalNewsletter'
import CountrySwitcher from '@/components/CountrySwitcher'
import ProductCarousel from '@/components/ProductCarousel'
import SearchSuggestions from '@/components/search/SearchSuggestions'

export default {
  name: 'Header',
  mixins: [NavigationalMixin, ScreenWidthMixin],
  components: {
    Logo,
    ModalTrackOrder,
    ModalNewsletter,
    CountrySwitcher,
    ProductCarousel,
    SearchSuggestions
  },
  watch: {
    $route: {
      handler (newRoute) {
        if (newRoute.query[settings.app.queryTokens.openTrackOrderOnInitToken]) {
          this.showTrackOrder = true
        }
      },
      immediate: true
    },
    activeMobItem () {
      if (this.isMobile) {
        this.activeMobItem ? document.body.classList.add('dimmed') : document.body.classList.remove('dimmed')
      }
    },
    activeMegaMenuId (val) {
      if (val && !this.isCarouselsInitialized && this.headerData?.headerLinks?.some(el => el.containsCarousel)) {
        this.decorateMegamenuWithCarousels()
      }
      if (!this.isCarouselsInitialized) this.isCarouselsInitialized = true
      // @TODO Megamenu show/hide animation with overflow result in issue when hideMegamenu fn not closing megamenu after carousel item clicked
      // so here we're waiting till animation end
      setTimeout(() => {
        this.showProductCarousel = !!val
      }, 150)
    },
    searchQuery () {
      this.sendQuerySuggestions()
    }
  },
  computed: {
    ...mapState('content', ['headerData']),
    ...mapState('user', ['userData']),
    ...mapGetters('cart', ['cartSize']),
    ...mapGetters('user', ['profileCompletionPercentage', 'isGuest', 'isEmployee', 'isAffiliate']),
    ...mapGetters('app', ['isUSSite']),
    ...mapState('content', ['showNewsletterModal']),
    miniCartQty () {
      return this.cartSize < 999 ? this.cartSize : '999+'
    },
    isSearchInputVisible () {
      return this.$route.name !== 'Search'
    },
    trackOrderLink () {
      return this.isGuest
        ? {
          path: this.$route.name === 'TrackOrder' ? '/' : this.$route.path,
          query: {
            ...this.$route.query,
            [settings.app.queryTokens.openTrackOrderOnInitToken]: true
          }
        }
        : { path: '/account/order-history' }
    },
    navEvent () {
      return this.isTablet ? 'click' : 'mouseenter'
    }
  },
  methods: {
    ...mapActions('content', ['setHeaderData', 'decorateMegamenuWithCarousels']),
    ...mapActions('app', ['doLogin', 'doLogout']),
    ...mapActions('search', ['setQuerySuggestionsData']),
    onLoginClick (url) {
      this.doLogin({ customPath: url })
    },
    onMenuItemClick (url, item) {
      if (url) this.navigateTo(url)
      if (item && item.megaMenu) {
        this.activeMegaMenuId = null
        this.activeNestedMenuId = null
      }
      if (this.isTablet) this.closeMobileMenuItem()
      this.closeDropdown()
    },
    onSearchSubmit () {
      this.$router.push('/search?query=' + this.searchQuery)
      this.searchQuery = ''
      if (this.isMobile) this.closeMobileMenuItem()
    },
    onSuggestionSelected ({ query }) {
      this.$router.push('/search?query=' + query)
      this.searchQuery = ''
      this.setQuerySuggestionsData({ query: '' })
      if (this.isMobile) this.closeMobileMenuItem()
    },
    handleQueryInput (e) {
      this.searchQuery = e.target.value
    },
    onQueryInputBlur () {
      this.showSuggestions = false
    },
    onQueryInputFocus () {
      this.showSuggestions = true
    },
    sendQuerySuggestions: _.debounce(function () {
      this.setQuerySuggestionsData({ query: this.searchQuery })
    }, 300),
    toggleHandler (item, id) {
      this[item] !== id ? (this[item] = id) : (this[item] = null)
      setTimeout(() => {
        const activeItem = document.querySelector('.header__nav-megamenu.header__megamenu.is-open')
        if (activeItem) activeItem.classList.add('overflow')
      }, 300)
    },
    toggleDropDown (id) {
      this.isDropDownOpen = !this.isDropDownOpen
      if (this.isMobile) this.toggleHandler('activeMobItem', id)
    },
    closeDropdown () {
      this.isDropDownOpen = false
      this.activeAccountDropdownItem = null
    },
    closeMobileMenuItem () {
      this.activeMobItem = null
    },
    hideMegamenu () {
      this.activeMegaMenuId = null
      const activeItem = document.querySelector('.header__nav-megamenu.header__megamenu.is-open')
      if (activeItem) activeItem.classList.remove('overflow')
    },
    scrollPageListener: _.throttle(function () {
      this.$nextTick(() => {
        this.isHeaderSticky = window.pageYOffset > 50
      })
    }, 20)
  },
  created () {
    this.setHeaderData()
    window.addEventListener('scroll', this.scrollPageListener)
    this.dropDownMenu = [
      {
        title: 'My Account',
        link: '/account/profile',
        isVisibleDesktop: true,
        isVisibleMobile: false,
        icon: 'icon-cog'
      },
      {
        title: 'My Account',
        isVisibleDesktop: false,
        isVisibleMobile: true,
        nestedLinks: [
          {
            title: 'Account Overview',
            link: '/account/profile'
          },
          {
            title: 'Address Book',
            link: '/account/address-book'
          },
          {
            title: 'Order History',
            link: '/account/order-history'
          },
          {
            title: 'Favorites &amp; Lists',
            link: '/account/favorites'
          },
          {
            title: 'Preference Center',
            link: '/account/preferences'
          },
          {
            title: 'Invite Friends & Family',
            link: '/account/invite-friends-and-family'
          }
        ].filter(el => {
          return (!this.isEmployee && this.isUSSite) || !this.isUSSite ? el.link !== '/account/invite-friends-and-family' : true
        })
      },
      {
        title: 'Favorites & Lists',
        link: '/account/favorites',
        isVisibleDesktop: true,
        isVisibleMobile: false,
        icon: 'icon-heart'
      },
      {
        title: 'Logout',
        link: '/logout',
        isVisibleDesktop: true,
        isVisibleMobile: true,
        icon: 'icon-sign-out-alt'
      }
    ]
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollPageListener)
  },
  data () {
    return {
      activeNestedMenuId: null,
      activeMegaMenuId: null,
      activeMobItem: null,
      activeAccountDropdownItem: null,
      isDropDownOpen: false,
      searchQuery: '',
      showTrackOrder: false,
      isHeaderSticky: false,
      showProductCarousel: false,
      dropDownMenu: [],
      isCarouselsInitialized: false,
      showSuggestions: false
    }
  }
}
</script>
