<template>
  <div :id="compId"></div>
</template>
<script>
import postscribe from 'postscribe'
var ready = require('document-ready')

export default {
  name: 'VueScriptComponent',
  props: ['script'],
  mounted () {
    var vm = this
    ready(function () {
      const addEl = new Promise((resolve) => {
        postscribe(
          `#${vm.compId}`,
          `${vm.script}`,
          {
            done: function (x) {}
          }
        )
        resolve()
      })
      addEl.then()
    })
  },
  data () {
    return {
      compId: Date.now()
    }
  }
}
</script>
