<template>
  <div class="product-quantity-input">
    <button
      v-if="isButtonsVisible"
      @click="$emit('input', value - minOrderQty)"
      :disabled="value === minOrderQty"
      type="button"
      class="product-quantity-input__button button button--square"
    >
      <i class="icon-minus"></i>
    </button>
    <the-mask
      ref="input"
      @change.native="onInputChange"
      :value="value"
      :mask="'####'"
      class="form-input"
      :type="inputType"
      :class="inputClassList"
    />
    <button
      v-if="isButtonsVisible"
      @click="$emit('input', value + minOrderQty)"
      type="button"
      class="product-quantity-input__button button button--square"
    >
      <i class="icon-plus"></i>
    </button>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'ProductQuantityInput',
  components: { TheMask },
  props: {
    value: {
      type: Number,
      required: true
    },
    minOrderQty: {
      type: Number,
      required: true
    },
    isButtonsVisible: {
      type: Boolean,
      default: true
    },
    inputClassList: Array
  },
  mixins: [ScreenWidthMixin],
  computed: {
    inputType () {
      return this.isMobile ? 'number' : 'text'
    }
  },
  methods: {
    onInputChange (event) {
      if (+event.target.value === 0) {
        this.$emit('quantityChangedToZero')
        event.target.value = this.value
      } else {
        const newValue = this.getProductQuantity(+event.target.value)
        this.$emit('input', newValue)
        event.target.value = newValue
      }
    },
    getProductQuantity (inputValue) {
      return inputValue <= this.minOrderQty
        ? this.minOrderQty
        : inputValue - (inputValue % this.minOrderQty)
    }
  },
  mounted () {
    if (this.$refs.input && this.isMobile) {
      this.$refs.input.$el.pattern = '[0-9]*'
      this.$refs.input.$el.inputMode = 'numeric'
    }
  }
}
</script>
