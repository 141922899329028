const state = {
  productsToCompare: [],
  maxProductsToCompare: 10,
  comparisonData: null
}

const mutations = {
  TOGGLE_PRODUCT_TO_COMPARE (state, product) {
    state.productsToCompare = state.productsToCompare.some(el => el.productData.sku === product.productData.sku)
      ? state.productsToCompare.filter(el => el.productData.sku !== product.productData.sku)
      : [...state.productsToCompare, product]
  },
  TOOGLE_PREVIEWED_PRODUCT_TO_COMPARE_WITH_ANOTHER_PRODUCT (state, { mainProduct, secondaryProduct }) {
    const isListContainsMainProduct = state.productsToCompare.some(el => el.productData.sku === mainProduct.productData.sku)
    const isListContainsSecondaryProduct = state.productsToCompare.some(el => el.productData.sku === secondaryProduct.productData.sku)
    const isListCanHoldTwoProductsMore = state.maxProductsToCompare - state.productsToCompare.length >= 2

    const finalList = isListContainsSecondaryProduct
      ? state.productsToCompare.filter(el => el.productData.sku !== secondaryProduct.productData.sku)
      : [...state.productsToCompare, secondaryProduct]
    if (!isListContainsMainProduct && isListCanHoldTwoProductsMore) finalList.unshift(mainProduct)

    state.productsToCompare = finalList
  },
  SET_PRODUCTS_TO_COMPARE (state, data) {
    state.productsToCompare = data
  },
  SET_COMPARISON_DATA (state, data) {
    state.comparisonData = data
  }
}

const actions = {
  async setComparisonData ({ commit, dispatch }, skuList) {
    let products = await dispatch('product/getProductsList', skuList, { root: true })
    products = products.map(el => {
      el.addToCartQty = el.minOrderQty > 1 ? el.minOrderQty : 1
      return el
    })

    const productsData = products.map(product => ({
      code: product.sku,
      data: product
    }))

    const specifications = await dispatch('constructSpecifications', products)

    commit('SET_COMPARISON_DATA', {
      products: productsData,
      specifications
    })
  },
  constructSpecifications (context, products) {
    const specifications = {}
    products.forEach(product => {
      if (!product.classifications) return

      product.classifications.forEach(classification => {
        if (!specifications[classification.title]) {
          specifications[classification.title] = { name: classification.title, list: {}, hasDifferences: false }
        }
        classification.list.forEach(classificationValue => {
          if (!specifications[classification.title].list[classificationValue.name]) {
            specifications[classification.title].list[classificationValue.name] = {
              name: classificationValue.name,
              list: products.reduce((acc, curr) => {
                acc[curr.sku] = null
                return acc
              }, {})
            }
          }
          specifications[classification.title].list[classificationValue.name].list[product.sku] = classificationValue.value
        })
      })
    })

    for (const specification in specifications) {
      Object.values(specifications[specification].list).forEach(el => {
        const list = Object.values(el.list)
        el.hasDifferences = Object.values(el.list).some(val => val !== list[0])
        if (el.hasDifferences) specifications[specification].hasDifferences = true

        el.list = products.map(product => el.list[product.sku])
      })
    }

    return specifications
  },
  clearData ({ commit }) {
    commit('SET_COMPARISON_DATA', null)
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
