import {
  transformProductSearchEntry,
  extractProductCategory,
  extractCategoriesData,
  extractDiscountAmountFromProduct,
  extractDiscountAmountFromCartEntry,
  extractDiscountedProductPrice
} from '@/transformers'
import settings from '@/settings'
import AlgoliaService from '@/services/algolia'
import AnalyticsService from '@/services/analytics'

const actions = {
  async getProductDataFromAlgoliaBySku ({ rootState }, productSku) {
    const { hits } = await AlgoliaService.getProductIndex().search('', {
      filters: `(objectID:${productSku})`,
      hitsPerPage: 150
    })
    const searchProduct = hits?.length
      ? hits.map(el => transformProductSearchEntry({ entry: el, categories: rootState.catalogs.catalogsData.allCatalogs }))
        .find(el => el.sku === productSku)
      : null
    return {
      ...searchProduct,
      category: extractProductCategory(searchProduct.groupedCategories)
    }
  },
  populateCheckoutStepsData ({ rootState }, stepData) {
    if (AnalyticsService.googleEnabled) {
      const analyticsData = {
        orderData: { step: stepData.step, option: stepData.option },
        productsData: rootState.cart.cartData.entries.map(productFromCart => ({
          name: productFromCart.product.title,
          id: productFromCart.product.catalogId,
          price: `${productFromCart.adjustedPrice}`,
          brand: productFromCart.extraProperties?.brand,
          category: productFromCart.extraProperties?.category,
          variant: productFromCart.product.sku,
          quantity: productFromCart.quantity
        }))
      }
      AnalyticsService.sendCheckoutStepsData(analyticsData)
    }
  },
  populateOrderAnalyticsData ({ rootState, rootGetters }) {
    if (AnalyticsService.googleEnabled || settings.services.algolia.analyticsEnabled) {
      const analyticsProductsData = []
      const searchConversionData = []
      rootState.cart.cartData.entries.forEach(productFromCart => {
        const productFromPlacedOrderData = rootState.cart.placedOrderData.entries.find(el => el.product.catalogId === productFromCart.product.catalogId)
        analyticsProductsData.push({
          name: productFromCart.product.title,
          id: productFromCart.product.catalogId,
          position: +productFromCart.extraProperties?.position,
          list: productFromCart.extraProperties?.list,
          variant: productFromCart.product.sku,
          price: `${productFromPlacedOrderData.adjustedPrice}`,
          quantity: productFromCart.quantity,
          coupon: productFromCart.discounts?.length ? productFromCart.discounts[0].code : '',
          brand: productFromCart.extraProperties?.brand,
          category: productFromCart.extraProperties?.category,
          metric1: (productFromPlacedOrderData.totalPriceWithoutDiscount - productFromPlacedOrderData.totalPrice).toFixed(2),
          dimension1: productFromCart.extraProperties?.catalogOriginalTitle && productFromCart.extraProperties?.catalogOriginalTitle !== 'undefined'
            ? productFromCart.extraProperties.catalogOriginalTitle.split(' > ').join(' / ')
            : ''
        })

        searchConversionData.push({
          indexName: productFromCart.extraProperties?.indexName,
          queryID: productFromCart.extraProperties?.queryID,
          objectIDs: [productFromCart.sku]
        })
      })

      const analyticsData = {
        generalData: {
          currencyCode: settings.app.currency
        },
        orderData: {
          id: rootState.cart.placedOrderData.id,
          affiliation: rootGetters['user/isEmployee']
            ? 'Employee'
            : rootGetters['user/isAffiliate']
              ? 'Affiliate'
              : 'Public',
          revenue: `${rootState.cart.placedOrderData.totalPriceWithTax.toFixed(2)}`,
          tax: `${rootState.cart.placedOrderData.totalTax}`,
          shipping: `${rootState.cart.placedOrderData.deliveryCost}`,
          coupon: rootState.cart.cartData.appliedVouchers?.length
            ? rootState.cart.cartData.appliedVouchers[0].code
            : '',
          order_ff_code: rootGetters['app/isUSSite'] && rootState.user.userData?.friendsAndFamilyCode
            ? rootState.user.userData?.friendsAndFamilyCode
            : ''
        },
        productsData: analyticsProductsData
      }
      if (AnalyticsService.googleEnabled) AnalyticsService.sendPlaceOrderData(analyticsData)
      if (settings.services.algolia.analyticsEnabled) AnalyticsService.sendSearchOrderConversion(searchConversionData)
    }
    return Promise.resolve()
  },

  async populateImpressionsData ({ rootState, dispatch }, searchProductsData) {
    if (AnalyticsService.googleEnabled) {
      if (searchProductsData.some(el => typeof el.product?.groupedCategories === 'undefined')) {
        const entriesId = searchProductsData.map(entry => entry.product.catalogId)
        const requests = searchProductsData.map(entry => {
          return {
            indexName: AlgoliaService.getProductIndexName(),
            params: {
              filters: `(objectID:${entry.product.sku})`,
              hitsPerPage: 150
            }
          }
        })
        const { results } = await AlgoliaService.getClient().multipleQueries(requests)
        const transformedResultsWithDuplicates = results
          .map(el => el.hits)
          .flat()
          .map(el => transformProductSearchEntry({ entry: el, categories: rootState.catalogs.catalogsData.allCatalogs, hasFinalizedPrices: false }))
          .filter(el => entriesId.includes(el.catalogId))
        const searchProducts = []
        transformedResultsWithDuplicates.forEach(result => {
          if (!searchProducts.some(el => el.catalogId === result.catalogId)) searchProducts.push(result)
        })
        searchProductsData = searchProductsData.map(el => {
          const searchProduct = searchProducts.find(searchProduct => searchProduct.sku === el.product.sku)
          if (searchProduct) el.product = searchProduct
          return el
        })
      }

      const analyticsData = {
        generalData: { currencyCode: settings.app.currency },
        productsData: searchProductsData.map(({ product, position, list }) => ({
          position,
          list,
          name: product.title,
          id: product.catalogId,
          price: product.minPrice,
          brand: product.brand,
          category: extractProductCategory(product.groupedCategories),
          variant: product.sku
        }))
      }
      AnalyticsService.sendImpressionsData(analyticsData)

      dispatch('sendImpressionsViewItemsData', { productsData: searchProductsData })
    }
  },

  populateProductViewAnalytics ({ rootState, dispatch }) {
    if (AnalyticsService.googleEnabled) {
      const zProduct = rootState.product.productData

      const analyticsData = {
        productsData: [{
          name: zProduct.title,
          id: zProduct.catalogId,
          variant: zProduct.sku,
          price: zProduct.minPrice,
          brand: zProduct.brand,
          category: extractProductCategory(zProduct.groupedCategories)
        }]
      }

      if (rootState.product.productAnalyticsData?.referencePage) {
        analyticsData.productsData[0].position = typeof rootState.product.productAnalyticsData?.position === 'number'
          ? rootState.product.productAnalyticsData?.position : 0
        dispatch('populateProductClickAnalytics', analyticsData)

        dispatch('sendSelectProductFromListData', {
          productData: zProduct,
          position: +rootState.product.productAnalyticsData?.position || 1,
          pageName: rootState.product.productAnalyticsData?.referencePage || 'Direct PDP',
          quantity: 1
        })
      }
      AnalyticsService.sendProductDetailsData(analyticsData)

      dispatch('sendImpressionsViewItemData', {
        productData: zProduct,
        position: +rootState.product.productAnalyticsData?.position || 1,
        pageName: rootState.product.productAnalyticsData?.referencePage || 'Direct PDP',
        quantity: 1
      })
    }
  },

  populateProductClickAnalytics (context, analyticsData) {
    if (AnalyticsService.googleEnabled) AnalyticsService.sendProductDetailsClickData(analyticsData)
  },

  populateAddProductsToCartAnalytics ({ rootState, rootGetters }, products) {
    if (AnalyticsService.googleEnabled) {
      const productsData = products.map(({ sku, catalogId, title, quantity, brand, category, position, pageName }) => {
        const cartEntry = rootState.cart.cartData?.entries?.find(entry => entry.product.sku === sku)
        const categoriesFields = extractCategoriesData(category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: cartEntry.product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0

        return {
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2)
        }
      })
      const totatValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)

      const analyticsData = {
        generalData: {
          currencyCode: settings.app.currency,
          value: +totatValue.toFixed(2)
        },
        productsData
      }
      AnalyticsService.sendAddProductToCartData(analyticsData)
    }
  },

  populateRemoveProductsFromCartAnalytics ({ rootGetters }, removeProductsData) {
    if (AnalyticsService.googleEnabled) {
      const productsData = removeProductsData.map(({ cartEntry, product, quantity }) => {
        const categoriesFields = extractCategoriesData(cartEntry.extraProperties?.category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0
        return {
          ...categoriesFields,
          item_id: product.catalogId,
          item_variant: product.sku,
          item_name: product.title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          discount: +discount.toFixed(2),
          quantity,
          item_brand: cartEntry.extraProperties?.brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup']
        }
      })
      const totalValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)
      const analyticsData = {
        generalData: {
          currencyCode: settings.app.currency,
          value: +totalValue.toFixed(2)
        },
        productsData
      }
      AnalyticsService.sendRemoveProductsFromCartData(analyticsData)
    }
  },

  sendSelectProductFromListData ({ rootGetters }, { productData, quantity, position, pageName }) {
    if (AnalyticsService.googleEnabled) {
      const categoriesFields = extractCategoriesData(extractProductCategory(productData.groupedCategories))
      const coupon = productData.promoBanner?.code || ''
      const price = extractDiscountedProductPrice({ productData, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
      const discount = coupon ? extractDiscountAmountFromProduct({ discountPrice: productData.discountPrice, regularPrice: price }) : 0

      const analyticsData = {
        productsData: [{
          ...categoriesFields,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          item_id: productData.catalogId,
          item_variant: productData.sku,
          item_name: productData.title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          item_brand: productData.brand,
          coupon,
          discount: +discount.toFixed(2),
          quantity,
          index: position,
          item_list_name: pageName
        }]
      }
      AnalyticsService.sendSelectProductFromListData(analyticsData)
    }
  },

  sendImpressionsViewItemData ({ rootGetters }, { productData, quantity, position, pageName }) {
    if (AnalyticsService.googleEnabled) {
      const categoriesFields = extractCategoriesData(extractProductCategory(productData.groupedCategories))
      const coupon = productData.promoBanner?.code || ''
      const price = extractDiscountedProductPrice({ productData, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
      const discount = coupon ? extractDiscountAmountFromProduct({ discountPrice: productData.discountPrice, regularPrice: price }) : 0

      const analyticsData = {
        productsData: [{
          ...categoriesFields,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          item_id: productData.catalogId,
          item_variant: productData.sku,
          item_name: productData.title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          item_brand: productData.brand,
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2),
          quantity
        }]
      }
      AnalyticsService.sendImpressionsViewItemData(analyticsData)
    }
  },

  sendImpressionsViewItemsData ({ rootGetters }, { productsData }) {
    if (AnalyticsService.googleEnabled) {
      const analyticsData = {
        productsData: productsData.map(({ list: pageName, position, product: productData }) => {
          const categoriesFields = extractCategoriesData(extractProductCategory(productData.groupedCategories))
          const coupon = productData.promoBanner?.code || ''
          const price = extractDiscountedProductPrice({ productData, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
          const discount = coupon ? extractDiscountAmountFromProduct({ discountPrice: productData.discountPrice, regularPrice: price }) : 0
          return {
            ...categoriesFields,
            affiliation: rootGetters['user/analyticsAffiliationGroup'],
            item_id: productData.catalogId,
            item_variant: productData.sku,
            item_name: productData.title,
            currency: settings.app.currency,
            price: +price.toFixed(2),
            item_brand: productData.brand,
            index: position,
            item_list_name: pageName,
            coupon,
            discount: +discount.toFixed(2),
            quantity: 1
          }
        })
      }

      AnalyticsService.sendImpressionsViewItemsData(analyticsData)
    }
  },

  populateAddProductToListAnalytics ({ rootState, rootGetters }, { cartEntry, sku, catalogId, title, quantity, brand, category, position, listName }) {
    if (AnalyticsService.googleEnabled) {
      if (!cartEntry) cartEntry = rootState.cart.cartData?.entries?.find(entry => entry.product.sku === sku)
      const categoriesFields = extractCategoriesData(category)
      const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
      const price = extractDiscountedProductPrice({ productData: cartEntry.product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
      const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0
      const analyticsData = {
        generalData: {
          currencyCode: settings.app.currency,
          value: +((price - discount) * quantity).toFixed(2)
        },
        productsData: [{
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          discount: +discount.toFixed(2),
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: listName,
          coupon
        }]
      }
      AnalyticsService.sendAddProductToListData(analyticsData)
    }
  },

  populatePromotionClickAnalytics (context, promotionData) {
    if (AnalyticsService.googleEnabled) {
      AnalyticsService.sendPromotionClickData({ promotionData })
      AnalyticsService.sendSelectPromotionData({
        promotionData: {
          promotion_name: promotionData.name,
          location_id: promotionData.position,
          creative_name: promotionData.creative
        }
      })
    }
  },

  populatePromotionImpressionAnalytics (context, promotionData) {
    if (AnalyticsService.googleEnabled) {
      AnalyticsService.sendPromotionImpressionData({ promotionData })
      AnalyticsService.sendViewPromotionImpressionData({
        promotionData: {
          promotion_name: promotionData.name,
          location_id: promotionData.position,
          creative_name: promotionData.creative
        }
      })
    }
  },

  populateViewReviewCartAnalytics ({ rootState, rootGetters }) {
    if (AnalyticsService.googleEnabled) {
      const productsData = rootState.cart.cartData?.entries?.map(cartEntry => {
        const product = cartEntry.product
        const sku = product.sku
        const catalogId = product.catalogId
        const title = product.title
        const brand = product.brand
        const quantity = cartEntry.quantity
        const position = +cartEntry.extraProperties?.position
        const category = cartEntry.extraProperties?.category
        const pageName = cartEntry.extraProperties?.list

        const categoriesFields = extractCategoriesData(category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0

        return {
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2)
        }
      })
      const totatValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)

      const analyticsData = {
        generalData: {
          currencyCode: settings.app.currency,
          value: +totatValue.toFixed(2)
        },
        productsData
      }
      AnalyticsService.sendViewReviewCartData(analyticsData)
    }
  },

  populateBeginCheckoutAnalytics ({ rootState, rootGetters }) {
    if (AnalyticsService.googleEnabled) {
      const productsData = rootState.cart.cartData?.entries?.map(cartEntry => {
        const product = cartEntry.product
        const sku = product.sku
        const catalogId = product.catalogId
        const title = product.title
        const brand = product.brand
        const quantity = cartEntry.quantity
        const position = +cartEntry.extraProperties?.position
        const category = cartEntry.extraProperties?.category
        const pageName = cartEntry.extraProperties?.list

        const categoriesFields = extractCategoriesData(category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0

        return {
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2)
        }
      })
      const totatValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)

      const analyticsData = {
        generalData: {
          currencyCode: settings.app.currency,
          value: +totatValue.toFixed(2)
        },
        productsData
      }
      AnalyticsService.sendBeginCheckoutData(analyticsData)
    }
  },

  populateShippingCheckoutAnalytics ({ rootState, rootGetters }) {
    if (AnalyticsService.googleEnabled) {
      const productsData = rootState.cart.cartData?.entries?.map(cartEntry => {
        const product = cartEntry.product
        const sku = product.sku
        const catalogId = product.catalogId
        const title = product.title
        const brand = product.brand
        const quantity = cartEntry.quantity
        const position = +cartEntry.extraProperties?.position
        const category = cartEntry.extraProperties?.category
        const pageName = cartEntry.extraProperties?.list

        const categoriesFields = extractCategoriesData(category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0

        return {
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2)
        }
      })
      const totatValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)

      const analyticsData = {
        generalData: {
          coupon: rootState.cart.cartData?.appliedVouchers?.[0]?.code || '',
          shipping_tier: rootState.cart.cartData?.deliveryMode?.title,
          currency: settings.app.currency,
          value: +totatValue.toFixed(2)
        },
        productsData
      }
      AnalyticsService.sendShippingCheckoutData(analyticsData)
    }
  },

  populatePaymentInformationCheckoutAnalytics ({ rootState, rootGetters }) {
    if (AnalyticsService.googleEnabled) {
      const productsData = rootState.cart.cartData?.entries?.map(cartEntry => {
        const product = cartEntry.product
        const sku = product.sku
        const catalogId = product.catalogId
        const title = product.title
        const brand = product.brand
        const quantity = cartEntry.quantity
        const position = +cartEntry.extraProperties?.position
        const category = cartEntry.extraProperties?.category
        const pageName = cartEntry.extraProperties?.list

        const categoriesFields = extractCategoriesData(category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0

        return {
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2)
        }
      })
      const totatValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)

      const analyticsData = {
        generalData: {
          coupon: rootState.cart.cartData?.appliedVouchers?.[0]?.code || '',
          currency: settings.app.currency,
          value: +totatValue.toFixed(2),
          payment_type: 'Credit Card'
        },
        productsData
      }
      AnalyticsService.sendPaymentInformationCheckoutData(analyticsData)
    }
  },

  populatePurchaseCheckoutAnalytics ({ rootState, rootGetters }) {
    if (AnalyticsService.googleEnabled) {
      const productsData = rootState.cart.cartData?.entries?.map(cartEntry => {
        const product = cartEntry.product
        const sku = product.sku
        const catalogId = product.catalogId
        const title = product.title
        const brand = product.brand
        const quantity = cartEntry.quantity
        const position = +cartEntry.extraProperties?.position
        const category = cartEntry.extraProperties?.category
        const pageName = cartEntry.extraProperties?.list

        const categoriesFields = extractCategoriesData(category)
        const coupon = cartEntry?.allDiscounts?.map(discount => discount.code)?.join(';') || ''
        const price = extractDiscountedProductPrice({ productData: product, isAffiliate: rootGetters['user/isAffiliate'] }) || 0
        const discount = coupon ? extractDiscountAmountFromCartEntry(cartEntry) : 0

        return {
          ...categoriesFields,
          item_id: catalogId,
          item_variant: sku,
          item_name: title,
          currency: settings.app.currency,
          price: +price.toFixed(2),
          quantity,
          item_brand: brand,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          index: position,
          item_list_name: pageName,
          coupon,
          discount: +discount.toFixed(2)
        }
      })
      const totatValue = productsData.reduce((acc, curr) => acc + ((curr.price - curr.discount) * curr.quantity), 0)

      const analyticsData = {
        generalData: {
          transaction_id: rootState.cart.placedOrderData?.id,
          tax: rootState.cart.placedOrderData?.totalTax,
          shipping: rootState.cart.placedOrderData?.deliveryCost,
          affiliation: rootGetters['user/analyticsAffiliationGroup'],
          coupon: rootState.cart.cartData?.appliedVouchers?.[0]?.code || '',
          currency: settings.app.currency,
          value: +totatValue.toFixed(2)
        },
        productsData
      }
      AnalyticsService.sendPurchaseCheckoutData(analyticsData)
    }
  },

  sendSearchTracking (context, { objectID, position, indexName, queryID }) {
    AnalyticsService.sendSearchClick({
      indexName,
      queryID,
      positions: [position + 1],
      objectIDs: [objectID]
    })
  }
}

export default {
  actions,
  namespaced: true
}
