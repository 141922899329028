import algoliaInsights from 'search-insights'
import settings from '@/settings'

export default {
  googleEnabled: !!(settings.services.analytics.google.enabled && settings.services.analytics.google.accessToken),
  oneTrustEnabled: settings.services.oneTrust.enabled,
  init () {
    if (this.oneTrustEnabled) {
      const environmentPostfix = settings.app.env === 'prod' ? '' : '-test'
      const cookieConsentScript = document.createElement('script')
      cookieConsentScript.src = `https://cdn.cookielaw.org/consent/142a2985-02d0-4231-9138-a4f20b82857b${environmentPostfix}/OtAutoBlock.js`
      cookieConsentScript.type = 'text/javascript'
      document.head.appendChild(cookieConsentScript)

      const cookieScript = document.createElement('script')
      cookieScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      cookieScript.type = 'text/javascript'
      cookieScript.setAttribute('data-domain-script', `142a2985-02d0-4231-9138-a4f20b82857b${environmentPostfix}`)
      document.head.appendChild(cookieScript)

      window.OptanonWrapper = function OptanonWrapper () { }
    }
    if (this.googleEnabled) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(
        {
          brandCode: `STORE${settings.app.country === 'US' ? '' : `_${settings.app.country}`}`,
          Site: 'Hubbell Store'
        },
        {
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        }
      )

      const gtmScript = document.createElement('script')
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${settings.services.analytics.google.accessToken}`
      gtmScript.async = true
      gtmScript.defer = true
      gtmScript.type = 'text/javascript'
      document.head.appendChild(gtmScript)

      const noScriptWrapper = document.createElement('noscript')
      const gtmIframe = document.createElement('iframe')
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${settings.services.analytics.google.accessToken}`
      gtmIframe.height = 0
      gtmIframe.width = 0
      gtmIframe.style.display = 'none'
      gtmIframe.style.visibility = 'hidden'

      noScriptWrapper.appendChild(gtmIframe)
      document.body.appendChild(noScriptWrapper)

      if (settings.services.analytics.google.accessTokenCA !== '') {
        const gtmScript = document.createElement('script')
        gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${settings.services.analytics.google.accessTokenCA}`
        gtmScript.async = true
        gtmScript.defer = true
        gtmScript.type = 'text/javascript'
        document.head.appendChild(gtmScript)

        const noScriptWrapper = document.createElement('noscript')
        const gtmIframe = document.createElement('iframe')
        gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${settings.services.analytics.google.accessTokenCA}`
        gtmIframe.height = 0
        gtmIframe.width = 0
        gtmIframe.style.display = 'none'
        gtmIframe.style.visibility = 'hidden'

        noScriptWrapper.appendChild(gtmIframe)
        document.body.appendChild(noScriptWrapper)
      }
    }

    if (settings.services.analytics.hubspotScript.enabled) {
      window._hsq = window._hsq || []
      window._hsq.push(['setPath', window.location.pathname])

      const hubspotScript = document.createElement('script')
      hubspotScript.src = `//js.hs-scripts.com/${settings.services.analytics.hubspotScript.id}.js`
      hubspotScript.id = 'hs-script-loader'
      hubspotScript.async = true
      hubspotScript.defer = true
      hubspotScript.type = 'text/javascript'
      document.head.appendChild(hubspotScript)
    }

    if (settings.services.algolia.analyticsEnabled) {
      algoliaInsights('init', {
        appId: settings.services.algolia.appId,
        apiKey: settings.services.algolia.accessToken,
        useCookie: true
      })
    }
  },
  sendPlaceOrderData ({ orderData, productsData, generalData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'transaction',
        ecommerce: {
          ...generalData,
          purchase: {
            actionField: orderData,
            products: productsData
          }
        }
      })
    }
  },
  sendImpressionsData ({ productsData, generalData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        ecommerce: {
          ...generalData,
          impressions: productsData
        }
      })
    }
  },
  sendImpressionsViewItemData ({ productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          items: productsData
        }
      })
    }
  },
  sendImpressionsViewItemsData ({ productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          items: productsData
        }
      })
    }
  },
  sendProductDetailsData ({ orderData, productsData, generalData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productDetailView',
        ecommerce: {
          ...generalData,
          detail: {
            actionField: orderData,
            products: productsData
          }
        }
      })
    }
  },
  sendProductDetailsClickData ({ orderData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: orderData,
            products: productsData
          }
        }
      })
    }
  },
  sendAddProductToCartData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },
  sendRemoveProductsFromCartData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },
  sendSelectProductFromListData ({ productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
          items: productsData
        }
      })
    }
  },
  sendCheckoutStepsData ({ orderData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: orderData,
            products: productsData
          }
        }
      })
    }
  },
  sendPromotionClickData ({ promotionData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [promotionData]
          }
        }
      })
    }
  },
  sendSelectPromotionData ({ promotionData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'select_promotion',
        ecommerce: {
          items: [promotionData]
        }
      })
    }
  },
  sendPromotionImpressionData ({ promotionData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'promotionView',
        ecommerce: {
          promoView: {
            promotions: [promotionData]
          }
        }
      })
    }
  },
  sendViewPromotionImpressionData ({ promotionData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'view_promotion',
        ecommerce: {
          items: promotionData
        }
      })
    }
  },
  sendAddProductToListData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },

  sendViewReviewCartData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },

  sendBeginCheckoutData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },

  sendShippingCheckoutData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },

  sendPaymentInformationCheckoutData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },

  sendPurchaseCheckoutData ({ generalData, productsData }) {
    if (this.googleEnabled) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          ...generalData,
          items: productsData
        }
      })
    }
  },

  sendSearchClick ({ indexName, queryID, objectIDs, positions }) {
    if (settings.services.algolia.analyticsEnabled) {
      algoliaInsights('clickedObjectIDsAfterSearch', {
        eventName: 'item_click_on_search_results',
        index: indexName,
        queryID,
        objectIDs,
        positions
      })
    }
  },
  sendSearchOrderConversion (conversionObjects) {
    if (settings.services.algolia.analyticsEnabled) {
      conversionObjects.forEach(({ indexName, queryID, objectIDs }) => {
        if (objectIDs?.length && (queryID && queryID !== 'undefined') && (indexName && indexName !== 'undefined')) {
          algoliaInsights('convertedObjectIDsAfterSearch', {
            eventName: 'cart_place_with_search_results',
            index: indexName,
            queryID,
            objectIDs
          })
        }
      })
    }
  },
  trackHubspotPageView (pageUrl) {
    if (settings.services.analytics.hubspotScript.enabled) {
      window._hsq.push(['setPath', pageUrl])
      window._hsq.push(['trackPageView'])
    }
  }
}
