import settings from '@/settings'

export const authenticateUser = (idToken) => {
  return fetch(
    `${settings.services.api.baseUrl}/security-api-proxy/rest/v1/authenticate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`
      },
      body: JSON.stringify({ id_token: idToken })
    }
  ).then((res) => res.json())
}

export const getAndSaveGuestAccessToken = async () => {
  const res = await fetch(
    `${settings.services.api.baseUrl}/security-api-proxy/rest/v1/token`
  ).then((res) => res.json())

  saveAccessToken(res.token)
}

export const saveAccessToken = (jwt) => {
  localStorage.setItem('hubble.client_id', jwt)
}

export const getAccessToken = () => {
  return localStorage.getItem('hubble.client_id')
}

export const removeAccessToken = () => {
  return localStorage.removeItem('hubble.client_id')
}

export const decodeJTW = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// Check if JWT is expired with a 15 minute buffer
export const isJWTExpired = (expirationTimestamp) => {
  return expirationTimestamp < Date.now() / 1000 + 900
}

// Validate the access token and get a new guest one if it's expired
export const validateOrGetAccessToken = async () => {
  const accessToken = getAccessToken()

  if (!accessToken) {
    await getAndSaveGuestAccessToken()
    return
  }

  try {
    const decodedToken = decodeJTW(accessToken)

    if (isJWTExpired(decodedToken.exp)) {
      await getAndSaveGuestAccessToken()
    }
  } catch (e) {
    await getAndSaveGuestAccessToken()
  }
}

export const saveCurrentURLState = (path, queryParams = {}) => {
  const existingParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  )

  localStorage.setItem(
    'hubble.current_url',
    JSON.stringify({ path, queryParams: { ...queryParams, ...existingParams } })
  )
}

export const getCurrentURLState = () => {
  try {
    const state = JSON.parse(localStorage.getItem('hubble.current_url'))
    localStorage.removeItem('hubble.current_url')
    return state || { path: '/' }
  } catch (e) {
    return { path: '/' }
  }
}
