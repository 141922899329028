export default {
  methods: {
    navigateTo (link) {
      if (!link) return
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    },
    anchorTo (ref) {
      const refPosition = ref?.getBoundingClientRect().top + window.pageYOffset
      this.scrollToPosition(refPosition)
    },
    scrollPageToTop () {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    scrollToPosition (position) {
      const isBrowserSupportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style
      if (isBrowserSupportsNativeSmoothScroll) {
        window.scrollTo({ top: position, behavior: 'smooth' })
      } else {
        window.scrollTo(0, position)
      }
    }
  }
}
