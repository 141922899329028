import { transformProductEntry, transformEntryByType, transformProductSearchEntry } from '@/transformers'
import ContentfulService from '@/services/contentful'
import zApiService from '@/services/z-api'
import AlgoliaService from '@/services/algolia'
import settings from '@/settings'
import recommend from '@algolia/recommend'

const state = {
  productData: null,
  productAnalyticsData: null,
  structureDataMarkupScriptId: 'structureDataMarkupScript'
}

const mutations = {
  SET_PRODUCT_DATA (state, data) {
    state.productData = data
  },
  UNSET_PRODUCT_DATA (state) {
    state.productData = null
  },
  SET_PRODUCT_ANALYTICS_DATA (state, data) {
    state.productAnalyticsData = data
  }
}

const actions = {
  async getProduct ({ rootState }, id) {
    const [
      { fields: contentfulEntry } = {},
      zProduct,
      { hits: searchHits } = {}
    ] = await Promise.all([
      ContentfulService.getEntryByTypeAndKey('pageProduct', id),
      zApiService.products.getProduct(id),
      AlgoliaService.getProductIndex().search('', {
        filters: `(objectID:${id})`,
        hitsPerPage: 150
      })
    ])

    const product = zProduct ? transformProductEntry(zProduct) : null
    const searchProduct = searchHits?.length
      ? searchHits.map(el => transformProductSearchEntry({ entry: el, categories: rootState.catalogs.catalogsData.allCatalogs }))
        .find(el => el.sku === zProduct.sku)
      : null

    if (searchProduct?.groupedCategories?.length) product.groupedCategories = searchProduct.groupedCategories

    if (product && contentfulEntry) {
      product.content = {
        bannerA: transformEntryByType(contentfulEntry.bannerA)
      }
    }

    return product
  },
  async getProductsList ({ rootState, dispatch }, skuList) {
    const [
      zProducts,
      { hits: searchHits } = {}
    ] = await Promise.all([
      zApiService.products.getProductsList(skuList),
      dispatch('search/getSearchDataBySkuList', skuList, { root: true })
    ])

    const products = zProducts.map(transformProductEntry)
    const searchProducts = searchHits
      .map(el => transformProductSearchEntry({ entry: el, categories: rootState.catalogs.catalogsData.allCatalogs }))

    products.forEach(zProduct => {
      const searchProduct = searchProducts.find(product => product.sku === zProduct.sku)
      if (searchProduct) zProduct.groupedCategories = searchProduct.groupedCategories
    })

    return products
  },
  async setProductData ({ commit, dispatch, rootGetters }, id) {
    const product = await dispatch('getProduct', id)
    await commit('SET_PRODUCT_DATA', product)
    if (rootGetters['user/isGuest']) dispatch('embedStructureDataMarkup')
    dispatch('analytics/populateProductViewAnalytics', null, { root: true })
    return Promise.resolve()
  },
  async getDownloadAllResourcesByTypeLink (context, data) {
    const link = await zApiService.products.getDownloadAllResourcesByTypeLink(data)
    return Promise.resolve(`https://hubbellcdn.com/zipdownloads/${link}`)
  },
  async getRelatedProducts ({ dispatch }, data) {
    const { references } = await zApiService.products.getRelatedProducts(data)
    let relatedProducts = references?.length
      ? references
        .filter(el => el.target && el.target.id)
        .map(el => transformProductEntry(el.target))
        .filter(el => el.sku !== data.productCode)
      : null

    if (relatedProducts) {
      const transformedProductsWithPrices = await dispatch('search/getDecoratedProducts',
        {
          requestData: { products: relatedProducts.map(el => ({ code: el?.sku })) },
          products: relatedProducts
        },
        { root: true }
      )
      relatedProducts = transformedProductsWithPrices
    }

    return Promise.resolve(relatedProducts)
  },
  async getRelatedProductsFromAlgolia ({ dispatch }, data) {
    const recommendClient = recommend(settings.services.algolia.appId, settings.services.algolia.accessToken)
    const indexName = settings.services.algolia.searchApp.productIndexRelevance
    const { results } = await recommendClient.getRelatedProducts([
      {
        indexName: indexName,
        objectID: data.productCode,
        maxRecommendations: 10
      }
    ])
    const { hits } = results[0]
    let relatedProducts = hits?.length
      ? hits
        .filter(el => el.objectID)
        .map(el => transformProductEntry(el))
        .filter(el => el.objectID !== data.productCode)
      : null
    if (relatedProducts) {
      const transformedProductsWithPrices = await dispatch('search/getDecoratedProducts',
        {
          requestData: { products: relatedProducts.map(el => ({ code: el?.sku })) },
          products: relatedProducts
        },
        { root: true }
      )
      relatedProducts = transformedProductsWithPrices
    }
    return Promise.resolve(relatedProducts)
  },
  async getAllRelatedProducts ({ dispatch }, data) {
    const { references } = await zApiService.products.getAllRelatedProducts(data)
    let relatedProducts = references?.length
      ? references
        .filter(el => el.target && el.target.id && el.referenceType === 'SIMILAR')
        .map(el => transformProductEntry(el.target))
        .filter(el => el.sku !== data.productCode)
      : null
    if (relatedProducts) {
      const transformedProductsWithPrices = await dispatch('search/getDecoratedProducts',
        {
          requestData: { products: relatedProducts.map(el => ({ code: el?.sku })) },
          products: relatedProducts
        },
        { root: true }
      )
      relatedProducts = transformedProductsWithPrices
    }
    const finishColorEntries = references?.length
      ? references
        .filter(el => el.target && el.target.id && el.referenceType === 'FINISHORCOLOR')
        .map(el => transformProductEntry(el.target))
        .filter(el => el.sku !== data.productCode)
      : null
    return {
      finishColorEntries: finishColorEntries,
      relatedProducts: relatedProducts
    }
  },
  embedStructureDataMarkup ({ state }) {
    const data = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: state.productData.title,
      image: state.productData.images.filter(el => el.type === 'image').map(el => el.src),
      description: state.productData.descriptionLong,
      sku: state.productData.catalogId,
      mpn: state.productData.catalogId,
      gtin: state.productData.upcCode,
      brand: {
        '@type': 'Brand',
        name: state.productData.brand
      },
      offers: {
        '@type': 'Offer',
        url: settings.environmentsBaseUrl[settings.app.env][settings.app.country] + state.productData.link,
        priceCurrency: settings.app.currency,
        price: `${state.productData.minPrice}`,
        itemCondition: 'https://schema.org/NewCondition',
        availability: state.productData.isPublicStore && state.productData.hasPrice && state.productData.isPurchasable
          ? 'https://schema.org/InStock'
          : 'https://schema.org/OutOfStock'
      }
    }
    const structureDataMarkupScript = document.createElement('script')
    structureDataMarkupScript.id = state.structureDataMarkupScriptId
    structureDataMarkupScript.type = 'application/ld+json'
    structureDataMarkupScript.innerHTML = JSON.stringify(data)
    document.head.appendChild(structureDataMarkupScript)
  },
  unsetStructureDataMarkupScript ({ state, rootGetters }) {
    if (rootGetters['user/isGuest']) {
      const structureDataMarkupScript = document.getElementById(state.structureDataMarkupScriptId)
      if (structureDataMarkupScript) structureDataMarkupScript.remove()
    }
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
