<template>
  <ul class="social__list" v-if="links && links.length">
    <li v-for="item in links" :key="item.id">
      <a :href="item.url" class="social__link" :title="item.title" target="_blank" rel="noopener">
        <i :class="item.iconClass"></i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SocialLinks',
  props: ['links']
}
</script>
