import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/store/modules/index'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer: (state) => {
        const filteredUser = { ...state.user }
        const filteredCart = { ...state.cart }
        const filteredContent = { ...state.content }
        return {
          user: {
            userData: filteredUser.userData
          },
          cart: {
            cartData: filteredCart.cartData
          },
          content: {
            isAlertHintBarViewed: filteredContent.isAlertHintBarViewed,
            alertHintBar: filteredContent.alertHintBar
          }
        }
      }
    })
  ]
})
