import ContentfulService from '@/services/contentful'
import { transformEntryByType } from '@/transformers'
import { getSortingKeys } from '@/helpers'

const state = {
  bottomContentData: null,
  topContentData: null
}

const mutations = {
  SET_BOTTOM_CONTENT_DATA (state, data) {
    state.bottomContentData = data
  },
  SET_TOP_CONTENT_DATA (state, data) {
    state.topContentData = data
  }
}

const actions = {
  async setTopContentData ({ commit, dispatch, rootState, rootGetters }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'pageHome',
      'default-page-home'
    )
    if (!entry) return

    const meta = entry?.fields?.meta ? transformEntryByType(entry?.fields?.meta) : null

    const weOfferSection = await dispatch('content/decorateBannerCarousel', entry?.fields?.weOffer, { root: true })

    let categoriesSection = null
    if (entry?.fields?.categories?.fields?.items) {
      categoriesSection = entry.fields.categories
        ? {
          title: entry.fields.categories.fields.title,
          link: ContentfulService.extractEntryFromIncludesByType(entry.fields.categories, 'particleLink'),
          categoriesList: entry.fields.categories.fields.items.filter(el => el.fields)
            .map(el => rootState.catalogs.catalogsData.allCatalogs?.find(catalog => el.fields.key === catalog.key)).flat().filter(el => el)
        }
        : null
    }

    const bannerASection = entry.fields.bannerA?.fields[rootGetters['user/userGroupCollectionName']]?.length
      ? {
        parentKey: entry.fields.bannerA?.fields?.key,
        banner: transformEntryByType(entry.fields.bannerA.fields[rootGetters['user/userGroupCollectionName']][0])
      }
      : null

    commit('SET_TOP_CONTENT_DATA', {
      meta,
      weOfferSection,
      categoriesSection,
      bannerASection
    })

    dispatch('setBottomContentData', entry)
  },
  async setBottomContentData ({ commit, rootState, rootGetters, dispatch }, entry) {
    let newItemsSection = null
    if (entry?.fields?.newItems) {
      const catalog = rootState.catalogs.catalogsData.allCatalogs?.find(catalog => entry.fields.newItems.fields.key === catalog.key)

      if (catalog) {
        const transformedProducts = await dispatch(
          'search/getTransformedSearchProductsByQueryParams',
          { filters: `(${catalog.indexTitle})`, hitsPerPage: 5 },
          { root: true }
        )
        newItemsSection = {
          sectionTitle: catalog.title,
          sectionLink: {
            title: 'View all',
            url: catalog.url
          },
          productsList: transformedProducts
        }
      }
    }

    const smartSection = entry?.fields?.bannerB?.fields[rootGetters['user/userGroupCollectionName']]?.length
      ? {
        parentKey: entry.fields.bannerB?.fields?.key,
        banner: transformEntryByType(entry.fields.bannerB.fields[rootGetters['user/userGroupCollectionName']][0])
      }
      : null

    // @TODO uniform logic to get products and categories for 'section' type entries
    let saleItemsSection = null
    if (entry?.fields?.saleItems) {
      const catalog = rootState.catalogs.catalogsData.allCatalogs.find(catalog => entry.fields.saleItems.fields.key === catalog.key)

      if (catalog) {
        const transformedProducts = await dispatch(
          'search/getTransformedSearchProductsByQueryParams',
          { filters: `(${catalog.indexTitle})`, hitsPerPage: 10, sortBy: 'popularity' },
          { root: true }
        )

        saleItemsSection = {
          sectionTitle: catalog.title,
          sectionLink: {
            title: 'View all',
            url: catalog.url
          },
          productsList: transformedProducts
        }
      }
    }

    const signUpSection = entry?.fields?.signUp
      ? {
        parentKey: entry?.fields?.key,
        banners: transformEntryByType(entry.fields.signUp)
      }
      : null

    const bannerDSection = entry.fields.bannerD?.fields[rootGetters['user/userGroupCollectionName']]?.length
      ? {
        parentKey: entry.fields.bannerD?.fields?.key,
        banner: transformEntryByType(entry.fields.bannerD.fields[rootGetters['user/userGroupCollectionName']][0])
      }
      : null

    let brandsSection = null
    if (entry?.fields?.brands?.fields?.items) {
      const sortingKeys = getSortingKeys(entry.fields.brands.fields.items)
      brandsSection = entry.fields.brands
        ? {
          sectionTitle: entry.fields.brands.fields.title,
          sectionLink: ContentfulService.extractEntryFromIncludesByType(entry.fields.brands, 'particleLink'),
          // @TODO move extraction logic to servive helper
          brandsList: rootState.catalogs.catalogsData.allCatalogs
            ?.filter(el => entry.fields.brands.fields.items.map(el => el.fields.key).includes(el.key))
            .sort((a, b) => sortingKeys[b.key] - sortingKeys[a.key]).reverse()
        }
        : null
    }

    const bottomContentData = {
      newItemsSection,
      smartSection,
      saleItemsSection,
      signUpSection,
      bannerDSection,
      brandsSection
    }

    commit('SET_BOTTOM_CONTENT_DATA', bottomContentData)
    return Promise.resolve()
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
