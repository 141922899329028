import { transformEntryByType } from '@/transformers'
import ContentfulService from '@/services/contentful'
import markdownToHTML from 'marked'

const state = {
  customerSupportData: null
}

const mutations = {
  SET_CUSTOMER_SUPPORT_DATA (state, data) {
    state.customerSupportData = data
  }
}

const actions = {
  async setCustomerSupportData ({ commit }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'pageCustomerSupport',
      'default-page-customer-support'
    )

    // @TODO move to transformers
    const customerSupportData = {
      key: entry?.fields?.key || '',
      meta: entry?.fields?.meta ? transformEntryByType(entry?.fields?.meta) : null,
      heroBanner: entry?.fields?.heroBanner ? transformEntryByType(entry.fields.heroBanner) : null,
      title: entry?.fields?.title ? markdownToHTML(entry.fields.title) : null,
      paragraphA: entry?.fields?.paragraphA ? markdownToHTML(entry.fields.paragraphA) : null,
      paragraphB: entry?.fields?.paragraphB ? markdownToHTML(entry.fields.paragraphB) : null,
      infoBannerLeft: entry?.fields?.infoBannerLeft ? transformEntryByType(entry.fields.infoBannerLeft) : null,
      infoBannerRight: entry?.fields?.infoBannerRight ? transformEntryByType(entry.fields.infoBannerRight) : null,
      contactUsText: entry?.fields?.contactUsText ? markdownToHTML(entry.fields.contactUsText) : null,
      contactUsButton: entry?.fields?.contactUsButton ? transformEntryByType(entry.fields.contactUsButton) : null,
      mostViewedQuestions: entry?.fields?.mostViewedQuestions
        ? {
          title: entry.fields.mostViewedQuestions.fields.title,
          questionsList: entry.fields.mostViewedQuestions.fields.items
            ? entry.fields.mostViewedQuestions.fields.items.map(transformEntryByType)
            : null
        }
        : null
    }

    commit('SET_CUSTOMER_SUPPORT_DATA', customerSupportData)
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
