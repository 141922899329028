<template>
  <Modal
    @close="$emit('close')"
    :showModal="showModal"
    :isLoading="isLoading"
    class="modal-track-order"
  >
    <template v-slot:header>
      <div>
        <h3 class="text-h3">Track Guest Order Status</h3>
      </div>
    </template>
    <template v-slot:body>
      <div>
        <p class="modal-track-order__text">
          To check the status of your order, please enter your order reference number and email address.
        </p>

        <HintBar
          class="modal-track-order__hintbar"
          v-if="showError"
          :hintData="hintFormError"
        />

        <form class="modal-track-order__form" @submit.prevent="submitForm">
          <FormGroup
            v-model.trim="$v.form.orderNumber.$model.value"
            @input="$emit('fieldChanged', form.orderNumber)"
            :field="$v.form.orderNumber"
            :isValidationRun="isValidationRun"
            :class="{'form-group--error': showError}"
          />

          <FormGroup
            v-model.trim="$v.form.email.$model.value"
            @input="$emit('fieldChanged', form.email)"
            :field="$v.form.email"
            :isValidationRun="isValidationRun"
            :class="{'form-group--error': showError}"
          />
          <button
            :disabled="isLoading"
            type="submit"
            class="modal-track-order__submit button button--primary"
          >Track Order</button>
        </form>
        <a
          @click.prevent="$emit('loginClicked')"
          href="/login"
          class="modal-track-order__login-link"
        >Sign in to see full order history</a>
      </div>
    </template>
    <template v-slot:footer>
      <p class="modal-track-order__help-text">
        Need Help? Visit our
        <a
          @click.prevent="handleHelpLinkClick"
          href="/customer-support"
          class="link"
        >Contact Us</a>
        page
      </p>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import settings from '@/settings'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import Modal from '@/components/modal/Modal'
import HintBar from '@/components/HintBar'
import FormGroup from '@/components/FormGroup'

export default {
  name: 'ModalTrackOrder',
  components: {
    Modal,
    HintBar,
    FormGroup
  },
  mixins: [FormValidationMixin, LoaderMixin],
  props: ['showModal'],
  computed: {
    ...mapState('orders', ['trackOrderData'])
  },
  methods: {
    ...mapActions('orders', ['trackGuestOrder']),
    submitForm () {
      this.withPresendRoutine(() => {
        this.showError = false
        const formData = {}
        this.pushFormToData(formData)

        this.sendForm(this.trackGuestOrder, formData)
          .then(() => {
            this.isRedirectOnSubmit = true
            this.$emit('close')
            this.$router.push({
              path: `/track-order/${this.trackOrderData?.id}`,
              query: this.getQueryOnDestroy()
            })
          }).catch(() => {
            this.showError = true
          })
      })
    },
    getQueryOnDestroy () {
      const query = { ...this.$route.query, [settings.app.queryTokens.orderEmail]: this.form.email.value }
      delete query[settings.app.queryTokens.openTrackOrderOnInitToken]
      return query
    },
    handleHelpLinkClick () {
      this.$emit('close')
      this.$router.push('/customer-support')
    }
  },
  beforeDestroy () {
    if (!this.isRedirectOnSubmit) this.$router.replace({ query: this.getQueryOnDestroy() }).catch(() => {})
  },
  validations () {
    return {
      isRedirectOnSubmit: false,
      form: {
        orderNumber: {
          value: {
            required,
            server: this.server
          }
        },
        email: {
          value: {
            required,
            email,
            server: this.server
          }
        }
      }
    }
  },
  data () {
    return {
      isValidationRun: false,
      showError: false,
      hintFormError: {
        message: 'Please enter a valid order reference number and email address.'
      },
      form: {
        orderNumber: {
          placeholder: 'Order Reference Number *',
          value: '',
          title: 'orderNumber',
          type: 'text',
          variant: 'shadow',
          maxLength: '255',
          errors: {
            required: 'Please provide a valid Order Reference Number'
          }
        },
        email: {
          placeholder: 'Email Address *',
          value: '',
          type: 'email',
          title: 'orderEmail',
          variant: 'shadow',
          errors: {
            required: 'Please provide a valid Email Address',
            email: 'Please provide a valid Email Address'
          }
        }
      }
    }
  }
}
</script>
