<template>
  <div class="form-checkbox">
    <div
      class="checkbox"
      :class="{
        'checkbox--disabled': disabled || field.disabled
      }"
    >
      <input
        @change="$emit('input', $event.target.checked)"
        :id="model.label"
        :checked="value"
        :disabled="disabled || field.disabled"
        class="checkbox__input"
        type="checkbox"
      />
      <label :for="model.label" class="checkbox__label">
        <span v-html="label || model.label"></span>
        <span class="checkbox__icon" v-if="!!$slots['icon']">
          <slot name="icon"></slot>
        </span>
      </label>
    </div>
    <template v-if="isValidationRun && errors">
      <span
        class="form-group__hint"
        v-for="(error, idx) in errors"
        :key="idx"
        v-html="error"
      >
      </span>
    </template>
  </div>
</template>

<script>
import FormControlMixin from '@/mixins/FormControlMixin'

export default {
  mixins: [FormControlMixin],
  name: 'FormCheckbox',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
