import { mapMutations, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('productsComparison', ['productsToCompare', 'maxProductsToCompare']),
    ...mapState('search', ['resultsMetaData']),
    isCompareSelected () {
      return this.productsToCompare.some(el => el.productData.sku === this.productData?.sku)
    }
  },
  methods: {
    ...mapMutations('productsComparison', {
      toggleProductToCompare: 'TOGGLE_PRODUCT_TO_COMPARE',
      togglePreviewedProductToCompareWithAnotherProduct: 'TOOGLE_PREVIEWED_PRODUCT_TO_COMPARE_WITH_ANOTHER_PRODUCT'
    }),
    handleCompareClick (productData) {
      this.toggleProductToCompare({
        productData,
        indexName: this.resultsMetaData?.indexName,
        queryID: this.resultsMetaData?.queryID
      })
    },
    toggleCompareMainProductWithSelected ({ mainProduct, secondaryProduct }) {
      if (mainProduct?.sku === secondaryProduct?.sku) {
        this.handleCompareClick(mainProduct)
      } else {
        this.togglePreviewedProductToCompareWithAnotherProduct({
          mainProduct: {
            productData: mainProduct,
            indexName: this.resultsMetaData?.indexName,
            queryID: this.resultsMetaData?.queryID
          },
          secondaryProduct: {
            productData: secondaryProduct,
            indexName: this.resultsMetaData?.indexName,
            queryID: this.resultsMetaData?.queryID
          }
        })
      }
    }
  }
}
