import * as dayjs from 'dayjs'
import StringMask from 'string-mask'

export default {
  methods: {
    formatPrice (value, currency = 'USD') {
      const formattedVal = new Intl.NumberFormat('en-EN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)

      switch (currency) {
        case 'EUR':
          return `${formattedVal} €`
        case 'GBP':
          return `£${formattedVal}`
        case 'USD':
        default:
          return `$${formattedVal}`
      }
    },
    formatDate (date, format = 'MMMM D, YYYY') {
      return date ? dayjs(date).format(format) : null
    },
    formatPhone (phone) {
      return StringMask.apply(phone, '000-000-0000')
    }
  }
}
