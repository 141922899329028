var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header",class:{
    'header--sticky': _vm.isHeaderSticky,
    'header--sticky-extended': _vm.isHeaderSticky && (_vm.isEmployee || _vm.isAffiliate)
  },attrs:{"id":"header"}},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"header-top",class:{ 'header-top--hidden': _vm.isHeaderSticky }},[(_vm.userData && _vm.profileCompletionPercentage !== 100)?_c('li',{staticClass:"header-top__item"},[_c('router-link',{staticClass:"header-top__link",attrs:{"to":"/account/profile"}},[_vm._v(_vm._s(("Complete your profile (" + _vm.profileCompletionPercentage + "% complete)")))])],1):_vm._e(),(!_vm.isMobile)?_c('li',{staticClass:"header-top__item"},[_c('button',{staticClass:"header-top__link",on:{"click":function($event){return _vm.$router.push(_vm.trackOrderLink)}}},[_vm._v("Track Order ")])]):_vm._e(),(!_vm.isMobile)?_c('li',{staticClass:"header-top__item"},[_c('router-link',{staticClass:"header-top__link",attrs:{"to":"/customer-support","title":"Help"}},[_vm._v("Help")])],1):_vm._e(),(!_vm.isMobile)?_c('li',{staticClass:"header-top__item"},[_c('CountrySwitcher')],1):_vm._e()]),_c('div',{staticClass:"header-container"},[_c('Logo',{class:{ hidden: _vm.isMobile && _vm.isHeaderSticky },attrs:{"link":_vm.headerData && _vm.headerData.logo ? _vm.headerData.logo.link : null,"src":_vm.headerData && _vm.headerData.logo ?  _vm.headerData.logo.imageUrl : null,"title":_vm.headerData && _vm.headerData.logo ? _vm.headerData.logo.imageDescription || _vm.headerData.logo.title : null,"alt":_vm.headerData && _vm.headerData.logo ?  _vm.headerData.logo.imageAlt || _vm.headerData.logo.title : null}}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMobileMenuItem),expression:"closeMobileMenuItem"}],staticClass:"header__menu"},[_c('div',{staticClass:"header__nav header__shortcut-item",class:{
            'is-active': _vm.activeMobItem == 'navigation'
          },on:{"v-click-outside":function($event){_vm.activeMegaMenuId = null}}},[(_vm.isMobile)?_c('button',{staticClass:"header__shortcut-link header__shortcut--mob-menu icon-bars",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleHandler('activeMobItem', 'navigation')}}}):_vm._e(),(_vm.headerData && _vm.headerData.headerLinks)?_c('ul',{staticClass:"header__nav-list",on:{"mouseenter":_vm.closeDropdown,"mouseleave":_vm.hideMegamenu}},[_vm._l((_vm.headerData.headerLinks),function(headerItem,idx){return _c('li',{key:headerItem.title,staticClass:"header__nav-item",class:{
                'is-active': _vm.activeMegaMenuId == headerItem.title + idx
              },on:_vm._d({},[_vm.navEvent,function($event){$event.stopPropagation();return _vm.toggleHandler('activeMegaMenuId', headerItem.title + idx)}])},[_c('div',{staticClass:"header__nav-item-inner"},[(headerItem.link)?_c('a',{staticClass:"header__nav-link header__nav-link--clickable",attrs:{"href":headerItem.link,"title":headerItem.title},domProps:{"innerHTML":_vm._s(headerItem.title)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(headerItem.link, headerItem)}}}):_c('span',{staticClass:"header__nav-link",attrs:{"title":headerItem.title},domProps:{"innerHTML":_vm._s(headerItem.title)}}),(_vm.isMobile && headerItem.megaMenu)?_c('i',{staticClass:"header__nav-icon icon-down-arrow"}):_vm._e()]),(headerItem.megaMenu)?_c('div',{staticClass:"header__nav-megamenu header__megamenu",class:{
                  'is-open': _vm.activeMegaMenuId == headerItem.title + idx
                }},[_c('ul',{staticClass:"header__megamenu-list",class:{container: !headerItem.containsCarousel}},_vm._l((headerItem.megaMenu),function(menuItem){return _c('li',{key:menuItem.indexTitle,staticClass:"header__megamenu-item"},[_c('a',{staticClass:"header__megamenu-title",attrs:{"title":menuItem.title,"href":menuItem.url},domProps:{"innerHTML":_vm._s(menuItem.title)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(menuItem.url, headerItem)}}}),(menuItem.subValues)?_c('ul',{staticClass:"header__megamenu-links"},_vm._l((menuItem.subValues),function(item,idx){return _c('li',{key:item.indexTitle},[(item.subValues)?[_c('div',{staticClass:"header__megamenu-nested-title",class:{
                              'is-active':
                                _vm.activeNestedMenuId ==
                                menuItem.title + item.title + idx
                            }},[_c('a',{staticClass:"header__megamenu-nested-link",attrs:{"href":item.url,"title":item.title},domProps:{"innerHTML":_vm._s(item.title)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(item.url, headerItem)}}}),_c('button',{staticClass:"header__megamenu-nested-button button",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleHandler(
                                  'activeNestedMenuId',
                                  menuItem.title + item.title + idx
                                )}}},[_c('i',{class:!_vm.isMobile
                                    ? 'icon-caret-down'
                                    : 'icon-down-arrow'})])]),_c('ul',{staticClass:"header__megamenu-nested-links",class:{
                              'is-active':
                                _vm.activeNestedMenuId ==
                                menuItem.title + item.title + idx
                            }},_vm._l((item.subValues),function(nestedItem){return _c('li',{key:nestedItem.title},[_c('a',{staticClass:"header__megamenu-link",attrs:{"href":nestedItem.url,"title":nestedItem.title},domProps:{"innerHTML":_vm._s(nestedItem.title)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(nestedItem.url, headerItem)}}})])}),0)]:_c('a',{staticClass:"header__megamenu-link",attrs:{"href":item.url,"title":item.title},domProps:{"innerHTML":_vm._s(item.title)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(item.url, headerItem)}}})],2)}),0):(_vm.showProductCarousel
                        && !_vm.isTablet
                        && !menuItem.subValues
                        && menuItem.showMegamenuCarousel
                        && menuItem.productsCarouselList
                      )?_c('div',{staticClass:"header__megamenu-carousel"},[_c('ProductCarousel',{attrs:{"productsList":menuItem.productsCarouselList,"itemsPerPage":[[0, 1]],"isPriceVisible":true,"loop":true,"autoplay":true,"autoplayTimeout":3000,"analyticsData":{referencePage: 'Header Megamenu Carousel'},"carouselClass":"carousel-arrow-navigation carousel-arrow-navigation--small carousel-arrow-navigation--no-right-padding"},on:{"productClicked":function($event){return _vm.onMenuItemClick(null, headerItem)}}})],1):_vm._e()])}),0)]):_vm._e()])}),(_vm.isMobile)?_c('li',{staticClass:"header__nav-item",on:{"click":_vm.closeMobileMenuItem}},[_c('button',{staticClass:"header__nav-link header__nav-link--clickable",on:{"click":function($event){return _vm.$router.push(_vm.trackOrderLink)}}},[_vm._v("Track Order ")])]):_vm._e(),(_vm.isMobile)?_c('li',{staticClass:"header__nav-item"},[_c('a',{staticClass:"header__nav-link header__nav-link--clickable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick('/customer-support')}}},[_vm._v("Help ")])]):_vm._e(),(_vm.isMobile)?_c('li',{staticClass:"header__nav-item"},[_c('CountrySwitcher',{staticClass:"header__nav-link"})],1):_vm._e()],2):_vm._e()]),(_vm.isSearchInputVisible)?_c('div',{staticClass:"header__search header__shortcut-item",class:{
            'is-active': _vm.activeMobItem == 'search'
          }},[(_vm.isMobile)?_c('button',{staticClass:"header__shortcut-link header__shortcut--search icon-search",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleHandler('activeMobItem', 'search')}}}):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onQueryInputBlur),expression:"onQueryInputBlur"}],staticClass:"header__search-inner"},[_c('form',{staticClass:"header__search-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSearchSubmit.apply(null, arguments)}}},[_c('input',{staticClass:"input",attrs:{"type":"search","placeholder":"What are you looking for?"},domProps:{"value":_vm.searchQuery},on:{"input":_vm.handleQueryInput,"focus":_vm.onQueryInputFocus}}),_c('button',{staticClass:"header__search-btn icon-search",attrs:{"type":"submit"}})]),(_vm.showSuggestions)?_c('div',{staticClass:"header__suggestions-wrap"},[_c('SearchSuggestions',{staticClass:"search__suggestions",on:{"suggestionSelected":_vm.onSuggestionSelected}})],1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"header__cart header__shortcut-item",on:{"click":_vm.closeMobileMenuItem}},[_c('router-link',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('View Cart'),expression:"'View Cart'"}],staticClass:"header__shortcut-link header__shortcut-link--icon header__shortcut-link--cart icon-shopping-cart badge-wrapper",attrs:{"to":"/cart/review-cart"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.miniCartQty),expression:"miniCartQty"}],staticClass:"badge badge--primary"},[_vm._v(_vm._s(_vm.miniCartQty))])])],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"},{name:"tooltip",rawName:"v-tooltip",value:({
            content:  _vm.isGuest ? 'Registered User and Employee Login' : null,
            trigger: 'hover',
            autoHide: false
          }),expression:"{\n            content:  isGuest ? 'Registered User and Employee Login' : null,\n            trigger: 'hover',\n            autoHide: false\n          }"}],staticClass:"header__dropdown header__shortcut-item",class:{
            'is-active': _vm.activeMobItem == 'account'
          }},[_c('div',{staticClass:"header__dropdown-btn"},[(!_vm.userData)?_c('a',{staticClass:"header__shortcut-link header__shortcut-link--icon icon-user",attrs:{"href":"/login"},on:{"click":function($event){$event.preventDefault();return _vm.onLoginClick()}}}):_c('div',{staticClass:"header__shortcut-link",on:{"click":function($event){return _vm.toggleDropDown('account')}}},[_c('i',{staticClass:"icon-user-solid header__shortcut-link header__shortcut-link--yellow-border"}),(!_vm.isMobile)?_c('span',{staticClass:"header__shortcut--user"},[_vm._v(_vm._s(_vm.userData.firstName)+" "+_vm._s(_vm.userData.lastName))]):_vm._e(),(!_vm.isMobile)?_c('i',{staticClass:"icon-down-arrow"}):_vm._e()])]),(_vm.isEmployee || _vm.isAffiliate)?_c('span',{staticClass:"header__dropdown-label"},[_vm._v(" "+_vm._s(_vm.isAffiliate ? 'Affiliate' : 'Employee')+" ")]):_vm._e(),(_vm.isDropDownOpen)?_c('ul',{staticClass:"header__dropdown-list"},[_vm._l((_vm.dropDownMenu),function(item,idx){return [(_vm.isMobile && item.isVisibleMobile)?_c('li',{key:item.title + idx,staticClass:"header__dropdown-item",class:{
                  'is-active': _vm.activeAccountDropdownItem == item.title + idx
                }},[(item.link)?_c('a',{staticClass:"header__dropdown-link",attrs:{"href":item.link},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(item.link)}}},[_c('span',[(item.icon)?_c('i',{staticClass:"header__dropdown-icon",class:item.icon}):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")])]):_vm._e(),(item.nestedLinks)?_c('div',[_c('span',{staticClass:"header__dropdown-link",on:{"click":function($event){$event.stopPropagation();return _vm.toggleHandler(
                        'activeAccountDropdownItem',
                        item.title + idx
                      )}}},[_vm._v(_vm._s(item.title)+" "),_c('i',{staticClass:"header__dropdown-icon fal icon-down-arrow"})]),_c('ul',{staticClass:"header__dropdown-nested"},_vm._l((item.nestedLinks),function(nestedItem,idx){return (!(nestedItem.link === '/account/preferences' && !_vm.isUSSite))?_c('li',{key:nestedItem.title + idx},[_c('a',{staticClass:"header__dropdown-nested-link",attrs:{"href":nestedItem.link},domProps:{"innerHTML":_vm._s(nestedItem.title)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(nestedItem.link)}}})]):_vm._e()}),0)]):_vm._e()]):(!_vm.isMobile && item.isVisibleDesktop)?_c('li',{key:item.title + idx,staticClass:"header__dropdown-item"},[(item.link)?_c('a',{staticClass:"header__dropdown-link",attrs:{"href":item.link},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onMenuItemClick(item.link)}}},[(item.icon)?_c('i',{staticClass:"header__dropdown-icon",class:item.icon}):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]):_vm._e()]})],2):_vm._e()])])],1)]),(_vm.showTrackOrder)?_c('ModalTrackOrder',{attrs:{"showModal":_vm.showTrackOrder},on:{"loginClicked":function($event){return _vm.onLoginClick('/account/order-history')},"close":function($event){_vm.showTrackOrder = false}}}):_vm._e(),_c('ModalNewsletter',{attrs:{"showModal":_vm.showNewsletterModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }