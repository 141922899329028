<template>
  <li
    class="crumbs__dropdown-item"
    :class="{ 'crumbs__dropdown-item--active': dropdownItem.isDropDownOpen }"
  >
    <div class="crumbs__dropdown-item-inner">
      <router-link
        :to="dropdownItem.url"
        class="crumbs__dropdown-link"
      >
        {{ dropdownItem.title }}
      </router-link>
      <i
        v-if="dropdownItem.dropdownData && dropdownItem.dropdownData.length"
        @click.prevent="toggleDropDown(dropdownItem, !dropdownItem.isDropDownOpen)"
        class="icon-angle-right crumbs__dropdown-icon crumbs__dropdown-icon--subitems"
        :class="{ 'crumbs__dropdown-icon--active': dropdownItem.isDropDownOpen }"
      ></i>
    </div>

    <ul
      v-if="dropdownItem.dropdownData && dropdownItem.dropdownData.length && dropdownItem.isDropDownOpen"
      class="crumbs__dropdown crumbs__dropdown--subitems"
      :class="{ 'crumbs__dropdown--active': dropdownItem.dropdownData.some(el => el.isDropDownOpen) }"
    >
      <CrumbsDropdownItem
        v-for="dropdownSubItem in dropdownItem.dropdownData"
        :key="dropdownSubItem.key"
        :dropdownItem="dropdownSubItem"
        @toggleDropDown="handleCrumbsDropdownItemToggle($event, dropdownItem.dropdownData)"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'CrumbsDropdownItem',
  props: ['dropdownItem', 'handleCrumbsDropdownItemToggle'],
  methods: {
    toggleDropDown (dropdownItem, val) {
      this.$emit('toggleDropDown', dropdownItem)
      dropdownItem.isDropDownOpen = typeof val === 'boolean' ? val : !dropdownItem.isDropDownOpen
    }
  }
}
</script>
