import eventBus from '@/services/event-bus'
import store from '@/store/index'

const _is401 = error => error && error.response && error.response.status === 401
const _is403 = error => error && error.response && error.response.status === 403
const _isAbove500 = error => error && error.response && error.response.status >= 500

class ApiBase {
  constructor (transport) {
    if (!transport) {
      throw new Error('Api transport cannot be undefined')
    }
    this.transport = transport
  }

  _getUserId (userId) {
    // @TODO remomve dependency on store
    return userId || store.getters['user/userId'] || 'anonymous'
  }

  // @TODO check the implementation
  // do proper 401 handling
  _withDecoratedPromise (cb, options) {
    return new Promise((resolve, reject) => {
      cb().then(response => {
        resolve(response.data)
      }).catch(reason => {
        if (_is401(reason) || _is403(reason)) {
          eventBus.$emit('auth.notAuthorized')
        } else if (_isAbove500(reason) && !reason?.response?.config?.url?.includes('/inventory/')) {
          eventBus.$emit('api.serverError')
        }
        reject(reason)
      })
    })
  }
}

export default ApiBase
