var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"favorites-icon",class:{
    'favorites-icon--active': _vm.isProductInFavorites,
    'favorites-icon--disabled': _vm.isGuest
  },on:{"click":_vm.handleFavoriteClick}},[(_vm.isFavoritesActionInProgress)?_c('i',{key:"spinner",staticClass:"icon-spinner favorites-icon__spiner"}):_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(!_vm.disabled ? {
      content:  _vm.tooltipText,
      trigger: 'hover',
      autoHide: false
    } : {}),expression:"!disabled ? {\n      content:  tooltipText,\n      trigger: 'hover',\n      autoHide: false\n    } : {}"}],key:"heart",staticClass:"favorites-icon__heart",class:[_vm.isProductInFavorites ? 'icon-heart-filled' : 'icon-heart']})])}
var staticRenderFns = []

export { render, staticRenderFns }