import zApiService from '@/services/z-api'
import { transformCartEntry, extractProductCategory } from '@/transformers'
import AnalyticsService from '@/services/analytics'

const state = {
  saveForLaterData: null,
  isProcessing: false,
  isProductsPriceRecalculationRequired: false
}

const mutations = {
  SET_SAVE_FOR_LATER_DATA (state, data) {
    state.saveForLaterData = data
  },
  SET_IS_PROCESSING (state, data) {
    state.isProcessing = data
  },
  SET_IS_PRODUCTS_RECALCULATION_REQUIRED (state, data) {
    state.isProductsPriceRecalculationRequired = data
  }
}

const actions = {
  async setSaveForLaterData ({ commit, dispatch }) {
    const saveForLaterCarts = await zApiService.checkout.getSaveForLater()
    const cartData = saveForLaterCarts && saveForLaterCarts[0]
      ? transformCartEntry(saveForLaterCarts[0], { entriesDeep: false }) : null
    commit('SET_IS_PRODUCTS_RECALCULATION_REQUIRED', cartData?.isSomeProductPriceChanged)
    if (cartData?.isSomeProductPriceChanged) dispatch('recalculatePoductsPrice')
    commit('SET_SAVE_FOR_LATER_DATA', cartData)
  },
  async recalculatePoductsPrice ({ state, commit }) {
    const cartId = state.saveForLaterData.id
    await zApiService.checkout.recalculatePoductsPrice(cartId)
    commit('SET_IS_PRODUCTS_RECALCULATION_REQUIRED', false)
  },
  async moveProductFromCartToSaveForLater ({ dispatch, state, commit }, cartProduct) {
    try {
      commit('SET_IS_PROCESSING', true)
      await zApiService.checkout.addProductToSaveForLater({
        cartId: state.saveForLaterData?.id,
        productCodes: [cartProduct.product.sku]
      })
      await dispatch('cart/deleteCartEntry', { entryNumber: cartProduct.number }, { root: true })
      await dispatch('setSaveForLaterData')
    } catch {}
    commit('SET_IS_PROCESSING', false)
  },

  async moveProductsFromSaveForLaterToCart ({ dispatch, rootState, commit }, saveForLaterEntries) {
    try {
      commit('SET_IS_PROCESSING', true)
      await Promise.all(saveForLaterEntries.map(cartEntry => {
        return zApiService.checkout.addProduct({
          quantity: cartEntry.quantity,
          code: cartEntry.product.sku
        })
      }))
      await dispatch('cart/setCartData', { showProductsPriceChange: true }, { root: true })
      await dispatch('deleteProductsFromSaveForLater', saveForLaterEntries.map(cartEntry => cartEntry.product.sku))
    } catch {}
    commit('SET_IS_PROCESSING', false)
    if (AnalyticsService.googleEnabled) {
      const filters = saveForLaterEntries.map(cartEntry => `objectID:${cartEntry.product.sku}`).join(' OR ')
      const transformedProducts = await dispatch(
        'search/getTransformedSearchProductsByQueryParams',
        { filters: filters, hitsPerPage: 100, sortBy: 'popularity' },
        { root: true }
      )
      const productsAdditionalData = transformedProducts.map(product => {
        return {
          entryNumber: rootState.cart.cartData.entries.find(productFromCart => productFromCart.product.sku === product.sku)?.number,
          list: 'Save for later',
          position: 0,
          brand: product.brand,
          category: product.groupedCategories?.length ? extractProductCategory(product.groupedCategories) : ''
        }
      })

      await Promise.all(productsAdditionalData.map(payload => dispatch('cart/saveCartItemEntryAdditionalData', payload, { root: true })))

      dispatch('analytics/populateAddProductsToCartAnalytics', saveForLaterEntries.map(entry => {
        const algoliaProduct = transformedProducts.find(transformedProduct => transformedProduct.sku === entry.product.sku)
        return {
          sku: entry.product.sku,
          catalogId: entry.product.catalogId,
          brand: entry.product.brand,
          title: entry.product.title,
          quantity: entry.quantity,
          category: algoliaProduct.groupedCategories?.length ? extractProductCategory(algoliaProduct.groupedCategories) : '',
          position: 1,
          pageName: 'Saved for Later'
        }
      }), { root: true })
    }
  },

  async updateProductInSaveForLater ({ dispatch }, cartEntry) {
    await zApiService.checkout.updateProductInSaveForLater({
      cartId: state.saveForLaterData?.id,
      productCodes: [cartEntry.productData.sku],
      quantity: cartEntry.qty
    })
    await dispatch('setSaveForLaterData')
  },

  async deleteProductsFromSaveForLater ({ dispatch }, productCodes) {
    await zApiService.checkout.deleteProductFromSaveForLater({
      cartId: state.saveForLaterData?.id,
      productCodes
    })
    await dispatch('setSaveForLaterData')
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
