import store from '@/store/index'
import Vue from 'vue'
import VueRouter from 'vue-router'

import AnalyticsService from '@/services/analytics'

const Home = () => import('@/views/Home.vue')
const Catalog = () => import('@/views/Catalog.vue')
const ProductDetails = () => import('@/views/ProductDetails.vue')
const Brands = () => import('@/views/Brands.vue')
const CustomerSupport = () => import('@/views/CustomerSupport.vue')
const Search = () => import('@/views/Search.vue')
const CatalogProducts = () => import('@/views/CatalogProducts.vue')
const Cart = () => import('@/views/Cart.vue')
const Account = () => import('@/views/Account.vue')
const AccountOrderDetails = () => import('@/views/AccountOrderDetails.vue')
const Login = () => import('@/views/Login.vue')
const LoginOkta = () => import('@/views/LoginOkta.vue')
const Logout = () => import('@/views/Logout.vue')
const NotFound = () => import('@/views/NotFound.vue')
const TrackOrder = () => import('@/views/TrackOrder.vue')
const ProductsComparison = () => import('@/views/ProductsComparison.vue')
const AuthCallback = () => import('@/views/AuthCallback.vue')

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Homepage'
    },
    component: Home
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    meta: {
      title: 'Product Details Page'
    },
    component: ProductDetails
  },
  // order of catalog routes with a wildcard matters
  {
    path: '/category/*/products',
    alias: '/brand/*/products',
    name: 'CatalogProducts',
    component: CatalogProducts
  },
  {
    path: '/category/*',
    alias: '/brand/*',
    name: 'Catalog',
    component: Catalog
  },
  {
    path: '/brands',
    name: 'Brands',
    meta: {
      title: 'Brands'
    },
    component: Brands
  },
  {
    path: '/customer-support',
    name: 'CustomerSupport',
    meta: {
      title: 'Help'
    },
    component: CustomerSupport
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title: 'Search'
    },
    component: Search
  },
  {
    path: '/cart/:step?',
    name: 'Cart',
    meta: {
      title: 'Cart',
      className: 'cart-page'
    },
    component: Cart
  },
  {
    path: '/account/:section',
    name: 'Account',
    meta: {
      title: 'Account'
    },
    component: Account
  },
  {
    path: '/account/order-history/:id',
    name: 'AccountOrderDetails',
    meta: {
      title: 'Order Details'
    },
    component: AccountOrderDetails
  },
  {
    path: '/track-order/:id',
    name: 'TrackOrder',
    meta: {
      title: 'Track Order'
    },
    component: TrackOrder
  },
  {
    path: '/products-comparison',
    name: 'ProductsComparison',
    meta: {
      title: 'Products Comparison'
    },
    component: ProductsComparison
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login-okta',
    name: 'LoginOkta',
    component: LoginOkta
  },
  {
    path: '/employee',
    name: 'Employee login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/oauth/callback',
    name: 'AuthCallback',
    component: AuthCallback
  },
  {
    path: '/newsletter-modal',
    name: 'NewsletterModal',
    beforeEnter: (to, from, next) => {
      const isAppLoadedOnThatRoute = !from.name
      if (isAppLoadedOnThatRoute) next('/')
      store.commit('content/SET_SHOW_NEWSLETTER_MODAL', true)
    }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
    beforeEnter: (to, from, next) => {
      const isGuest = store.getters['user/isGuest']
      const isFromDeclaredRoute = !!from.name
      const isRedirectedFromUndeclaredRoute = !!to.redirectedFrom
      if (isGuest && isFromDeclaredRoute && !isRedirectedFromUndeclaredRoute) store.dispatch('app/doLogin', {}, { root: true })
      else next()
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from) {
    return to.path === from.path ? null : { x: 1, y: 0, behavior: 'smooth' }
  }
})

router.beforeEach((to, from, next) => {
  const toPath = to?.redirectedFrom || to?.path
  const redirectUrl = store?.state?.app?.redirectUrlsData?.[toPath]
  if (redirectUrl) {
    next(redirectUrl)
  } else {
    if (!store.state?.genericContent?.genericContentData) {
      const interval = setInterval(() => {
        if (store.state?.genericContent?.genericContentData) {
          const redirectedFromPath = to?.redirectedFrom?.split('?')[0]
          if (store.state.genericContent.genericContentData.some(el => el.pageUrl === redirectedFromPath)) {
            next(to.redirectedFrom)
          } else {
            next()
          }
          clearInterval(interval)
        }
      }, 300)
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  const body = document.querySelector('body')
  if (body && body.classList) {
    body.className = ''
    if (to.meta.className) body.classList.add(to.meta.className)
  }

  const isInitialAppLoad = !from.name && from.meta?.name !== 'GenericContent'
  const isPDPPage = to.name === 'ProductDetails'
  if (isInitialAppLoad || isPDPPage) return
  if (to.path !== from.path) AnalyticsService.trackHubspotPageView(to.path)
})

export default router
