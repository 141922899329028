import ApiBase from '@/services/z-api/v1/api-base'

// @TODO clean up once auth is ready
class Auth extends ApiBase {
  /** Unused */
  initSession () {
    return this._withDecoratedPromise(() => {
      return this.validateApiKey()
    })
  }

  /** Unused */
  validateApiKey () {
    const data = `username=${this.apiKey}`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return this.transport.post('/j_spring_security_check', data, headers, {}, { checkSession: false })
  }

  /** Unused */
  login (username, password) {
    return this._withDecoratedPromise(() => {
      const data = {
        username,
        password
      }
      return this.transport.post('/rest/v1/users/authenticate', data)
    }, { showNotification: false })
  }

  logout () {
    return this.transport.post('/rest/v1/users/logout', {}, {}, {}, { checkSession: false, trackRequest: false })
  }
}

export default Auth
