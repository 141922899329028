import ApiBase from '@/services/z-api/v1/api-base'

class Inventory extends ApiBase {
  checkInventory ({ code, baseStoreId }) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/v1/inventory/${baseStoreId}/${code}`)
    })
  }
}

export default Inventory
