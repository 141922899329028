const state = {
  showAppError: false,
  appErrorMessage: null
}

const mutations = {
  SHOW_APP_ERROR (state, data) {
    state.appErrorMessage = data
    state.showAppError = true
  },
  HIDE_APP_ERROR (state) {
    state.appErrorMessage = null
    state.showAppError = false
  }
}

export default {
  state,
  mutations,
  namespaced: true
}
