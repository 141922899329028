<template>
  <div class="search-suggestions" v-if="isVisible">
    <div class="search-suggestions__block" v-if="querySuggestionsData.querySuggestions">
      <span class="search-suggestions__title">Suggested Searches</span>
      <ul class="search-suggestions__list">
        <li
          v-for="suggestionItem in querySuggestionsData.querySuggestions"
          :key="suggestionItem.query"
          @click="onResultClick(suggestionItem)"
          class="search-suggestions__item"
        >
          <span class="search-suggestions__query-text">{{ suggestionItem.query }}</span>
        </li>
      </ul>
    </div>
    <div class="search-suggestions__block" v-if="false">
      <span class="search-suggestions__title">Suggested Categories</span>
      <ul class="search-suggestions__list">
        <li
          v-for="suggestionItem in querySuggestionsData.facetSuggestions"
          :key="suggestionItem.query + suggestionItem.facet"
          @click="onResultClick(suggestionItem)"
          class="search-suggestions__item"
        >
          <span class="search-suggestions__query-text">{{ suggestionItem.query }}</span>
          <span class="search-suggestions__category-text">in {{ suggestionItem.facet }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SearchSuggestions',
  computed: {
    ...mapState('search', ['querySuggestionsData']),
    isVisible () {
      return this.querySuggestionsData?.querySuggestions?.length
    }
  },
  methods: {
    onResultClick (suggestionItem) {
      this.$emit('suggestionSelected', suggestionItem)
    }
  }
}
</script>
