<template>
  <i
    @click="scrollPageToTop()"
    class="icon-arrow-alt-circle-up scroll-up"
    :class="{
      show: visible,
      'scroll-up--sticky': emulateStickyBehavior,
      'scroll-up--sticky-disabled': !emulateStickyBehavior
    }"
  />
</template>

<script>
import _ from 'lodash'

import NavigationalMixin from '@/mixins/NavigationalMixin'

export default {
  name: 'ScrollTop',
  mixins: [NavigationalMixin],
  methods: {
    scrollListener: _.throttle(function () {
      this.visible = window.pageYOffset > 1300
      this.emulateStickyBehavior =
        window.pageYOffset < document.querySelector('#content').getBoundingClientRect().height - 600 &&
        window.pageYOffset > 1300
    }, 100)
  },
  mounted () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  data () {
    return {
      visible: false,
      emulateStickyBehavior: false
    }
  }
}
</script>
