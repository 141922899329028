var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
    'form-group--inline': _vm.model.inline,
    'form-group--disabled': _vm.disabled || _vm.model.disabled,
    'form-group--shadow': _vm.model.variant === 'shadow',
    'form-group--error': _vm.isValidationRun && _vm.field.value && _vm.field.value.$invalid
  },attrs:{"disabled":_vm.disabled || _vm.model.disabled}},[(_vm.model.placeholder)?_c('span',{staticClass:"form-group__label",domProps:{"innerHTML":_vm._s(_vm.model.placeholder)}}):_vm._e(),(_vm.model.inputType === 'the-mask')?_c('the-mask',{staticClass:"form-group__input form-input",class:{
      'form-input--shadow': _vm.model.variant === 'shadow',
      'form-input--disabled': _vm.model.disabled,
      'form-input--error': _vm.isValidationRun && _vm.field.value && _vm.field.value.$invalid
    },attrs:{"mask":_vm.model.mask,"tokens":_vm.model.tokens,"masked":false,"type":_vm.model.type,"maxlength":_vm.model.maxLength,"readonly":_vm.model.readonly,"disabled":_vm.disabled || _vm.model.disabled},on:{"input":function($event){return _vm.$emit('input', _vm.model.value)}},model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}}):(_vm.model.type !== 'textarea')?_c('input',{staticClass:"form-group__input form-input",class:{
      'form-input--shadow': _vm.model.variant === 'shadow',
      'form-input--disabled': _vm.model.disabled,
      'form-input--error': _vm.isValidationRun && _vm.field.value && _vm.field.value.$invalid
    },attrs:{"type":_vm.model.type,"disabled":_vm.disabled || _vm.model.disabled,"maxlength":_vm.model.maxLength,"readonly":_vm.model.readonly,"placeholder":_vm.model.placeholderText},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}):_c('textarea',{staticClass:"form-group__input form-textarea",class:{
      'form-textarea--shadow': _vm.model.variant === 'shadow',
      'form-textarea--disabled': _vm.model.disabled,
      'form-textarea--error': _vm.isValidationRun && _vm.field.value && _vm.field.value.$invalid
    },attrs:{"maxlength":_vm.model.maxLength,"readonly":_vm.model.readonly,"disabled":_vm.disabled || _vm.model.disabled},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.value)}}}),(_vm.isValidationRun && _vm.errors)?_vm._l((_vm.errors),function(error,idx){return _c('span',{key:idx,staticClass:"form-group__hint",domProps:{"innerHTML":_vm._s(error)}})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }