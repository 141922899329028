export default {
  props: {
    field: {
      required: true,
      type: Object
    },
    value: {
      required: true
    },
    isValidationRun: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model () {
      return this.field.$model || this.field
    },
    errors () {
      if (this.field.$model) {
        return Object.keys(this.field.$model.errors).map(el => {
          switch (el) {
            case 'required':
              return (!this.field.value.required && typeof this.field.value.required === 'boolean') ? this.field.$model.errors.required : null
            case 'maxLength':
              return (!this.field.value.maxLength && typeof this.field.value.maxLength === 'boolean') ? this.field.$model.errors.maxLength : null
            case 'server':
              return (!this.field.value.server && typeof this.field.value.server === 'boolean') ? this.field.$model.errors.server : null
            case 'numeric':
              return (!this.field.value.numeric && typeof this.field.value.numeric === 'boolean') ? this.field.$model.errors.numeric : null
            case 'email':
              return (!this.field.value.email && typeof this.field.value.email === 'boolean') ? this.field.$model.errors.email : null
            case 'minLength':
              return (!this.field.value.minLength && typeof this.field.value.minLength === 'boolean') ? this.field.$model.errors.minLength : null
            case 'sameAs':
              return (!this.field.value.sameAs && typeof this.field.value.sameAs === 'boolean') ? this.field.$model.errors.sameAs : null
            default:
              return null
          }
        }).filter(error => error)
      } else {
        return null
      }
    }
  }
}
