import ContentfulService from '@/services/contentful'
import markdownToHTML from 'marked'
import { getSortingKeys } from '@/helpers'
import { transformEntryByType } from '@/transformers'

const state = {
  brandsPageData: null
}

const mutations = {
  SET_BRANDS_PAGE_DATA (state, data) {
    state.brandsPageData = data
  }
}

const actions = {

  async setBrandsPageData ({ commit, rootState }) {
    const entry = await ContentfulService.getEntryByTypeAndKey(
      'pageBrands',
      'default-page-brands'
    )

    const sortingKeys = entry ? getSortingKeys(entry.fields.brandsList) : null

    const brandsPageData = {
      title: entry?.fields?.title ? markdownToHTML(entry.fields.title) : null,
      meta: entry?.fields?.meta ? transformEntryByType(entry?.fields?.meta) : null,
      paragraph: entry?.fields?.paragraph ? markdownToHTML(entry.fields.paragraph) : null,
      image: entry?.fields?.image ? ContentfulService.extractImageUrl(entry.fields.image) : null,
      imageMobile: entry?.fields?.imageMobile ? ContentfulService.extractImageUrl(entry.fields.imageMobile) : null,
      imageAlt: entry?.fields?.image?.fields?.title || null,
      imageDescription: entry?.fields?.image?.fields?.description || null,
      // @TODO move extraction logic to servive helper
      brandsList: rootState.catalogs.catalogsData.allCatalogs
        .filter(el => entry?.fields?.brandsList?.filter(el => el?.fields).map(el => el?.fields?.key).includes(el.key))
        .sort((a, b) => sortingKeys[b.key] - sortingKeys[a.key]).reverse()
    }

    commit('SET_BRANDS_PAGE_DATA', brandsPageData)
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true
}
